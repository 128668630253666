import {
  Backdrop,
  DialogActions,
  DialogContent,
  Fade,
  Modal,
  makeStyles,
} from "@material-ui/core";
import React, { useState } from "react";
import {
  ConfigDocText,
  EntityName,
} from "./styles/ModalPublicationChannelFrame";

import { ModalPublicationChannel } from "./ModalPublicationChannelFrame/ModalPublicationChannel";
import { ModalMultipleEmails } from "./ModalPublicationChannelFrame/ModalMultipleEmails";
import {
  ButtonCancel,
  ButtonSave,
  CloseIconStyle,
  DialogHeader,
  DialogStyle,
} from "./Modal";
import SubdirectoryArrowLeftIcon from "@material-ui/icons/SubdirectoryArrowLeft";
import { notify } from "../../utils/notify";
import { postDocuments, sendVerifyEmail } from "../../repositories/Document";
import { ModalProcessingEmails } from "./ModalPublicationChannelFrame/ModalProcessingEmails";

export const ModalPublicationChannelFrame = ({
  open,
  handleClose,
  entityIdParam,
  nameIdentifier,
  startOption,
}) => {
  const [tab, setTab] = useState(0);
  const [multipleEmailsList, setMultipleEmailsList] = useState([]);
  const [multipleEmailsListSuccess, setmultipleEmailsListSuccess] =
    React.useState([]);
  const [multipleEmailsListDup, setmultipleEmailsListDup] = React.useState([]);
  const [multipleEmailsListError, setmultipleEmailsListError] = React.useState(
    []
  );
  const [modalProcessingOpen, setModalProcessingOpen] = useState(false);

  const useStyles = makeStyles((theme) => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "24px",
      flexDirection: "column",
      gap: "24px",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(2, 4, 3),
      width: "800px",
      borderRadius: "16px",
      overflow: "scroll",
    },
    MuiAccordionroot: {
      "&.MuiAccordion-root:before": {},
    },
  }));

  const entityIds =
    entityIdParam === undefined && localStorage.getItem("isAccountantPanel")
      ? localStorage.getItem("companyIdPanel")
      : entityIdParam;

  const backModal = (tabNumber) => {
    setTab(tabNumber);
  };

  const addEmailList = async (list) => {
    if (list.length > 0) {
      const result = await postDocuments(list);
      const { final_list, formated_dup_emails, error_list } = result;

      setmultipleEmailsListSuccess(final_list);
      setmultipleEmailsListDup(formated_dup_emails);
      setmultipleEmailsListError(error_list);
      if (error_list.length !== 0 || formated_dup_emails.length !== 0) {
        setModalProcessingOpen(true);
      } else {
        notify("Documentos Adicionados com Sucesso!", true, "success");
      }

      setMultipleEmailsList([]);
      final_list.map((email) =>
        sendVerifyEmail(email, { entity_id: entityIds })
      );
    } else {
      notify("Lista Vazia");
    }
  };

  const classes = useStyles();
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <DialogStyle style={{ width: 800 }}>
          <DialogHeader>
            <div className="row">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <ConfigDocText>
                  {tab === 0
                    ? "Canais de Publicação"
                    : tab === 1
                    ? "Inserir múltiplos e-mails"
                    : tab === 2
                    ? "Status do Processamento de E-mails"
                    : ""}
                </ConfigDocText>
                {tab !== 2 ? (
                  <EntityName>
                    {nameIdentifier === undefined ? "Todos" : nameIdentifier}
                  </EntityName>
                ) : (
                  <div style={{ marginBottom: "32px" }}></div>
                )}
              </div>
              <CloseIconStyle
                style={{ marginRight: "6px" }}
                onClick={() => {
                  handleClose();
                }}
              />
            </div>
          </DialogHeader>

          <DialogContent style={{ maxHeight: "65vh" }}>
            {tab === 0 ? (
              <ModalPublicationChannel
                startOption={startOption}
                entityIdParam={entityIdParam}
                open={open}
                setTab={setTab}
                handleClose={handleClose}
              />
            ) : tab === 1 ? (
              <>
                <ModalMultipleEmails
                  setTab={setTab}
                  startOption={startOption}
                  entityIds={entityIds}
                  emailList={multipleEmailsList}
                  setEmailList={setMultipleEmailsList}
                />
                <ModalProcessingEmails
                  open={modalProcessingOpen}
                  handleClose={() => {
                    setModalProcessingOpen(false);
                  }}
                  successEmails={multipleEmailsListSuccess}
                  duplicateEmails={multipleEmailsListDup}
                  errorEmails={multipleEmailsListError}
                />
              </>
            ) : (
              <></>
            )}
          </DialogContent>

          <DialogActions style={{ paddingRight: 24 }}>
            {tab === 0 ? (
              <ButtonCancel onClick={handleClose}>{"Fechar"}</ButtonCancel>
            ) : tab === 1 ? (
              <>
                <ButtonCancel
                  style={{
                    marginRight: 24,
                    float: "right",
                  }}
                  onClick={() => {
                    backModal(0);
                    setMultipleEmailsList([]);
                  }}
                  startIcon={<SubdirectoryArrowLeftIcon />}
                >
                  {"Voltar"}
                </ButtonCancel>
                <ButtonSave
                  onClick={async () => {
                    await addEmailList(multipleEmailsList);
                  }}
                >
                  Salvar
                </ButtonSave>
              </>
            ) : (
              <></>
            )}
          </DialogActions>
        </DialogStyle>
      </Fade>
    </Modal>
  );
};
