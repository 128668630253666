import { Switch } from "react-router-dom";
import { ModalCurrentUserContextProvider } from "../../pages/currentUser/contexts/ModalCurrentUserContext";
import { SettingContextProvider } from "../../pages/settings/contexts/SettingContext";
import Settings from "../../pages/settings/Setting";
import MiddlewareRoute from "../Middleware";
import { CustumerManagementContextProvider } from "../../pages/customerManagements/contexts/CustomerManagementContext";
import { ManagementContextProvider } from "../../pages/accessProfile/contexts/ManagementContext";

export default function Setting() {
  return (
    <>
      <SettingContextProvider>
        <ModalCurrentUserContextProvider>
          <CustumerManagementContextProvider>
            <ManagementContextProvider>
              <Switch>
                <MiddlewareRoute
                  path="/setting"
                  component={Settings}
                  sidebar
                  noPaddingSidebar
                />
              </Switch>
            </ManagementContextProvider>
          </CustumerManagementContextProvider>
        </ModalCurrentUserContextProvider>
      </SettingContextProvider>
    </>
  );
}
