import axios from "axios";
import { notify } from "../utils/notify";
import { search } from "./BaseRepositoryComponents";

const newPath = `${process.env.REACT_APP_NEW_PAINEL_API_URL}`;

export async function getTemplateFiles(document, typeNotification) {
  return axios
    .get(
      `${newPath}/v1/email-template/get-template-files/${document}?type_notification=${typeNotification}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("tokenPanel"),
          "Content-Type": "application/json",
          "Content-Language": "pt-BR",
        },
      }
    )
    .then((response) => {
      return response.data.data;
    })
    .catch((err) => {
      notify("Ocorreu um erro ao buscar os templates!");
      return null;
    });
}

export async function sendTemplateFiles(body) {
  return await axios
    .post(`${newPath}/v1/email-template/send-template-files`, body, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("tokenPanel"),
        "Content-Type": "application/json",
        "Content-Language": "pt-BR",
      },
    })
    .then((response) => {
      if (body["editorType"] === "Edit") {
        notify("Template Editado com Sucesso!", true, "success");
        return response.data.data;
      } else {
        notify("Template Criado com Sucesso!", true, "success");
        return response.data.data;
      }
    })
    .catch((err) => {
      if (body["editorType"] === "Edit") {
        notify("Ocorreu um Erro ao Editar o Template!");
      } else {
        console.log(err);
        notify("Ocorreu um Erro ao Salvar o Template!");
      }

      return err;
    });
}

export async function sendPreviewEmail(email, document, type, template_id) {
  return await axios
    .get(
      `${newPath}/v1/email-template/send-preview-email/${email}/${document}/${type}/${template_id}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("tokenPanel"),
          "Content-Type": "application/json",
          "Content-Language": "pt-BR",
        },
      }
    )
    .then((response) => {
      notify("Prévia Enviada com Sucesso!", true, "success");
      return response;
    })
    .catch((err) => {
      return null;
    });
}

// /v1/documents/change-additional-message
export async function changeAdditionalMessage(body) {
  return await axios
    .post(`${newPath}/v1/email-template/change-additional-message`, body, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("tokenPanel"),
        "Content-Type": "application/json",
        "Content-Language": "pt-BR",
      },
    })
    .then((response) => {
      notify("Mensagem Adicional Alterada com sucesso!", true, "success");
      return response;
    })
    .catch((err) => {
      notify("Ocorreu um Erro ao Alterar a Mensagem Adicional!");
      return err;
    });
}

export async function deleteTemplate(template_id, document, type) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("tokenPanel"),
  };
  return await axios
    .delete(
      `${newPath}/v1/email-template/delete-template/${template_id}/${document}/${type}`,
      {
        headers: headers,
      }
    )
    .then((response) => {
      if (response.data.data === "Template is being used") {
        notify(
          "O template está sendo utilizado em outra configuração!",
          true,
          "warning"
        );
        return response;
      } else {
        notify("Template deletado com sucesso!", true, "success");
        return response;
      }
    })
    .catch((err) => {
      notify("Ocorreu um erro ao deletar o template!");
      return err;
    });
}

export async function getTemplateImage(document, type, template_id) {
  return axios
    .get(
      `${newPath}/v1/email-template/get-template-image/${document}/${type}/${template_id}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("tokenPanel"),
          "Content-Type": "application/json",
          "Content-Language": "pt-BR",
        },
      }
    )
    .then((response) => {
      return response.data.data;
    })
    .catch((err) => {
      notify("Ocorreu um erro ao carregar as imagens dos templates!");
      return null;
    });
}

export async function getTemplateJson(document, type, template_id) {
  return axios
    .get(
      `${newPath}/v1/email-template/get-template-json/${document}/${type}/${template_id}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("tokenPanel"),
          "Content-Type": "application/json",
          "Content-Language": "pt-BR",
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      notify("Ocorreu um erro as carregar o template!");
      return null;
    });
}

export async function getEmailPlaceholdersByTypeAndSystem(
  systemPlaceholder,
  typeNotification
) {
  return search({
    path: `${newPath}/v1/email-template/placeholders?system_placeholder=${systemPlaceholder}&type_notification=${typeNotification}`,
    method: "GET",
  });
}
