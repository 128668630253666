import axios from "axios";
import { search } from "../../BaseRepositoryComponents";

const newPath = `${process.env.REACT_APP_NEW_PAINEL_API_URL}/v1`;

export async function getCertificatesFromAccountant() {
  return await axios
    .get(`${newPath}/certificate`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("tokenPanel"),
        "Entity-Id": localStorage.getItem("companyIdPanel"),
        "Content-Type": "application/json",
        "Content-Language": "pt-BR",
      },
    })
    .then((response) => {
      return response.data.data;
    })
    .catch((err) => {
      return null;
    });
}

export async function getCertificatesByDocument(document) {
  return await axios
    .get(`${newPath}/certificate/${document}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("tokenPanel"),
        "Entity-Id": localStorage.getItem("companyIdPanel"),
        "Content-Type": "application/json",
        "Content-Language": "pt-BR",
      },
    })
    .then((response) => {
      return response.data.data;
    })
    .catch((err) => {
      return null;
    });
}

export function a1UploadAndProcuration(body) {
  return search({
    path: `${newPath}/certificate/a1-upload-and-procuration`,
    method: "POST",
    myBody: body,
  });
}
