import { Grid, IconButton } from "@material-ui/core";
import React, { useState, useCallback, useEffect } from "react";
import Bot from "../sidebar/children/bot/Bot";
import { useRef } from "react";
import { useContext } from "react";
import { NewBotContext } from "./context/NewBotContext";
import BotActionIcons from "../sidebar/children/bot/components/BotActionIcons";
import {
  DivStyledActions,
  StyledBotFuncionalities,
  StyledButtonBotActivity,
  StyledGridBotActivity,
  StyledGridBotActivityQuantity,
  StyledGridBotFuncionality,
  StyledMessageField,
  StyledNewBotContainer,
  SubtitleBotActions,
} from "./styles/NewBot";
import { DialogUploadDocument } from "../sidebar/children/DialogUploadDocument";
import { getBillingData, handleFile } from "./services/NewBot";
import { MetricsModal } from "../sidebar/children/MetricsModal";
import { BotPanelModal } from "../sidebar/children/BotPanelVideo";
import { BotVideoListModal } from "../sidebar/children/BotPanelVideoList";
import BotChatModal from "../sidebar/children/bot/BotChatModal";
import { CertificateModal } from "../../pages/dashboard/components/CertificateModal";
import { DocumentModal } from "../documentModal/DocumentModal";
import { CompanyModal } from "../../pages/dashboard/components/CompanyModal";
import { MigrationModal } from "../sidebar/children/MigrationModal";
import { XmlModal } from "../xmlModal/XmlModal";
import { ZipModal } from "../../pages/dashboard/components/ZipModal";
import { ImageModal } from "../../pages/dashboard/components/ImageModal";
import { ClientBillModal } from "../../pages/dashboard/components/ClientBillModal";
import { FileNotRecognizedModal } from "./components/FileNotRecognizedModal";
import { GlobalContext } from "../../contexts/GlobalContext";
import { usePortalAsClient } from "../../utils/viewPortalAsClientHook";
import BotConfiguration from "../sidebar/children/BotConfiguration";

import "@brainhubeu/react-carousel/lib/style.css";
import { GlobalVariables } from "../../GlobalVariables";
import { usePermissions } from "../../utils/permissionsHook";
import { ActionCarousel } from "./components/ActionsCarousel";
import Loading from "../basics/Loading";
import { NotFoundCompanyModal } from "../../pages/dashboard/components/NotFoundCompanyModal";
import { CompanyHasAccountantModal } from "../../pages/dashboard/components/CompanyHasAccountantModal";
import { getEventsByTimestamp } from "../../repositories/portal_api/Event";

export const NewBot = ({ ...props }) => {
  const botRef = useRef(null);
  const dropRef = useRef(null);
  const [dragging, setDragging] = useState(false);

  const { companyUser, userLogged } = useContext(GlobalContext);
  const { token } = userLogged;
  const isInClientView = sessionStorage.getItem("isInClientView");
  const { clientViewed } = usePortalAsClient();
  const botContext = useContext(NewBotContext);
  const [activitiesList, setActivitiesList] = useState([]);
  const { checkIfUserHasPermission } = usePermissions();
  const [audioListening, setAudioListening] = useState(false);
  const [recognition, setRecognition] = useState(null);

  const [loading, setLoading] = useState(false);

  const closeBotChatModal = () => {
    botContext.setBotText("");
    botContext.setBotMode("");
    botContext.setOpenBotChatModal(false);
    botRef.current.end_bot();
  };

  const openBotChatModal = () => {
    botContext.setBotPresentationRunning(null);
    if (botContext.isBotPresentationRunning === true) {
      botRef.current.stop_bot_presentation_to_open_chat();
    }
    botContext.setBotMode("CHAT");
    botContext.setOpenBotChatModal(true);
  };

  const closeZipModal = () => {
    botContext.setZipList([]);
    botContext.setOpenZipModal(false);
    botContext.setKeyZip(Math.random());
    botContext.setLoadingZip(false);
  };

  const handleDrag = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDrop = useCallback(async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);

    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      setLoading(true);
      await handleFile(
        e.dataTransfer.files,
        botRef,
        companyUser,
        token,
        isInClientView,
        clientViewed,
        closeZipModal,
        closeBotChatModal,
        botContext
      );

      setLoading(false);

      e.dataTransfer.clearData();
    }
  });

  const setAnimationFinished = (animation) => {
    if (animation.animation === "bot_presentation") {
      botContext.setBotPresentationRunning(null);
    }
    botContext.setAnimation(animation);
  };

  useEffect(() => {
    if (checkIfUserHasPermission(GlobalVariables.PERMISSIONS.GESTAO_FACIL)) {
      getBillingData(companyUser, botContext);
    }

    if (dropRef.current) {
      dropRef.current.addEventListener("dragover", handleDrag);
      dropRef.current.addEventListener("drop", handleDrop);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getEventsByTimestamp((data) => {
      data.sort(function (a, b) {
        return parseInt(b.total) - parseInt(a.total);
      });
      setActivitiesList(data);
    });
  }, []);

  const doAudioRecording = () => {
    try {
      botRef.current.listen();
      window.SpeechRecognition =
        window.webkitSpeechRecognition || window.SpeechRecognition;
      let recognition = new window.SpeechRecognition();
      setRecognition(recognition);

      recognition.lang = "pt-BR";
      //recognition.continuous = true;
      recognition.onresult = async (event) => {
        const speechToText = event.results[event.resultIndex][0].transcript;

        botContext.setBotPresentationRunning(null);
        if (botContext.isBotPresentationRunning === true) {
          botRef.current.stop_bot_presentation_to_open_chat();
        }
        botContext.setBotText(speechToText);
        botContext.setBotMode("CHAT");
        botContext.setOpenBotChatModal(true);

        recognition.stop();
        setAudioListening(false);
      };

      recognition.start();
    } catch (err) {
      console.error(err);
    }
  };

  const doDisableAudioRecording = () => {
    try {
      recognition.stop();
    } catch (err) {}
  };

  return (
    <>
      <Loading open={loading} />
      <StyledNewBotContainer container direction="column" alignItems="center">
        <Grid item xs={12} ref={dropRef} dragging={dragging}>
          <Bot
            ref={botRef}
            batteryStatus={botContext.batteryStatus}
            setAnimationFinished={setAnimationFinished}
            notificationOpen={props.notificationOpen}
          />
        </Grid>
        <StyledBotFuncionalities
          hasBoletoAlert={props.notificationOpen}
          hasBatteryMessage={botContext.batteryStatus.status !== ""}
        >
          <StyledGridBotFuncionality item container>
            <StyledMessageField
              fullWidth
              variant="outlined"
              color="primary"
              size="small"
              placeholder="Como posso ajudar?"
              value={botContext.botText}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  openBotChatModal();
                }
              }}
              onChange={(e) => {
                botContext.setBotText(e.target.value);
              }}
              InputProps={{
                style: {
                  fontSize: 12,
                  textAlign: "center",
                },
                startAdornment: (
                  <IconButton
                    size="small"
                    onClick={() => {
                      if (audioListening === false) {
                        setAudioListening(true);
                        doAudioRecording();
                      } else {
                        setAudioListening(false);
                        doDisableAudioRecording();
                      }
                    }}
                  >
                    <BotActionIcons
                      icon="microphone"
                      color={audioListening ? "red" : "#0839a9"}
                    ></BotActionIcons>
                  </IconButton>
                ),
                endAdornment: (
                  <IconButton
                    size="small"
                    onClick={() => {
                      openBotChatModal();
                    }}
                  >
                    <BotActionIcons
                      icon="send"
                      color="#0839a9"
                    ></BotActionIcons>
                  </IconButton>
                ),
              }}
            />
          </StyledGridBotFuncionality>

          <ActionCarousel />

          <StyledGridBotFuncionality
            item
            container
            direction="row"
            alignItems="center"
          >
            <DivStyledActions>
              <BotActionIcons icon="time" color="#FFF" />
            </DivStyledActions>

            <SubtitleBotActions>Atividades do Bot</SubtitleBotActions>
          </StyledGridBotFuncionality>

          {activitiesList.slice(0, 5).map((item, index) => (
            <>
              <StyledGridBotActivity
                item
                container
                direction="row"
                alignItems="center"
              >
                <Grid item xs={10} style={{ padding: 5 }}>
                  {item.name}
                </Grid>
                <StyledGridBotActivityQuantity item xs={2}>
                  {item.total}
                </StyledGridBotActivityQuantity>
              </StyledGridBotActivity>
            </>
          ))}

          <StyledButtonBotActivity
            fullWidth
            endIcon={<BotActionIcons icon="add" color="#FFF" />}
            onClick={() => {
              botContext.setOpenMetrics(true);
            }}
          >
            Mostrar Todas Atividades
          </StyledButtonBotActivity>
        </StyledBotFuncionalities>
        {botContext.showDialogUploadDocument && (
          <DialogUploadDocument
            openModal={botContext.showDialogUploadDocument}
            closeModal={() => botContext.setShowDialogUploadDocument(false)}
            title="Upload de Documentos"
            handleFile={handleFile}
            BotContext={botContext}
            botRef={botRef}
            closeBotChatModal={closeBotChatModal}
            closeZipModal={closeZipModal}
          ></DialogUploadDocument>
        )}

        {botContext.openMetrics && (
          <MetricsModal
            openModal={botContext.openMetrics}
            closeMetricsModal={() => {
              botContext.setOpenMetrics(false);
            }}
          />
        )}

        {botContext.openPanelVideo && (
          <BotPanelModal
            openModal={botContext.openPanelVideo}
            closeBotPanelVideo={() => {
              botContext.setOpenPanelVideo(false);
            }}
          />
        )}

        {botContext.openShowVideoList && (
          <BotVideoListModal
            openModal={botContext.openShowVideoList}
            videos={botContext.videoList}
            closeVideoList={() => {
              botContext.setOpenShowVideoList(false);
            }}
          />
        )}
        {botContext.openBotChatModal && (
          <BotChatModal
            animationEnded={botContext.animation}
            openModal={botContext.openBotChatModal}
            closeBotChatModal={closeBotChatModal}
            setOpenPanelVideo={botContext.setOpenPanelVideo}
            setOpenShowVideoList={botContext.setOpenShowVideoList}
            setVideoList={botContext.setVideoList}
            botRef={botRef}
          />
        )}
        {botContext.openCertificateModal && (
          <CertificateModal
            openModal={botContext.openCertificateModal}
            certificateList={botContext.certificateList}
            closeCertificateModal={() => {
              botContext.setCertificateList([]);
              botContext.setOpenCertificateModal(false);
            }}
          />
        )}

        {botContext.openDocumentModal && (
          <DocumentModal
            key={botContext.keyDocument}
            documentModalLoading={botContext.documentModalLoading}
            openModal={botContext.openDocumentModal}
            documentList={botContext.documentList}
            closeDocumentModal={() => {
              botContext.setDocumentList([]);
              botContext.setOpenDocumentModal(false);
              botContext.setKeyDocument(Math.random());
            }}
          />
        )}

        {botContext.openCompanyModal && (
          <CompanyModal
            openModal={botContext.openCompanyModal}
            companyErrorList={botContext.companyErrorList}
            companyList={botContext.companyList}
            loading={botContext.setLoadingCompany}
            loadingCompany={botContext.loadingCompany}
            closeCompanyModal={() => {
              botContext.setCompanyList([]);
              botContext.setCompanyErrorList([]);
              botContext.setOpenCompanyModal(false);
              botContext.setKeyCompany(Math.random());
            }}
            key={botContext.keyCompany}
          />
        )}

        {botContext.openNotFoundCompanyModal && (
          <NotFoundCompanyModal
            openModal={botContext.openNotFoundCompanyModal}
            closeModal={() => {
              botContext.setOpenNotFoundCompanyModal(false);
            }}
          />
        )}

        {botContext.openCompanyHasAccountantModal && (
          <CompanyHasAccountantModal
            openModal={botContext.openCompanyHasAccountantModal}
            closeModal={() => {
              botContext.setOpenCompanyHasAccountantModal(false);
            }}
          />
        )}

        {botContext.openMigrationModal && (
          <MigrationModal
            openModal={botContext.openMigrationModal}
            companyList={botContext.companyList}
            loading={botContext.setLoadingCompany}
            loadingMigration={botContext.loadingMigration}
            closeMigrationModal={() => {
              botContext.setCompanyList([]);
              botContext.setOpenMigrationModal(false);
              botContext.setKeyMigration(Math.random());
              botContext.setLoadingMigration(false);
            }}
            key={botContext.keyMigration}
          />
        )}
        {botContext.openXmlModal && (
          <XmlModal
            openModal={botContext.openXmlModal}
            xmlList={botContext.xmlList}
            loading={botContext.loadingXml}
            clients={botContext.clients}
            loadingXmlModal={botContext.setLoadingXml}
            closeXmlModal={() => {
              botContext.setXmlList([]);
              botContext.setCompanyErrorList([]);
              botContext.setOpenXmlModal(false);
              botContext.setKeyXml(Math.random());
              botContext.setLoadingXml(false);
            }}
            key={botContext.keyXml}
          />
        )}
        {botContext.openZipModal && (
          <ZipModal
            openModal={botContext.openZipModal}
            zipList={botContext.zipList}
            loading={botContext.loadingZip}
            loadingZipModal={botContext.setLoadingZip}
            closeZipModal={() => {
              botContext.setZipList([]);
              botContext.setOpenZipModal(false);
              botContext.setKeyZip(Math.random());
              botContext.setLoadingZip(false);
            }}
            key={botContext.keyZip}
          />
        )}
        {botContext.openImageModal && (
          <ImageModal
            openModal={botContext.openImageModal}
            image={botContext.logoImage}
            closeImageModal={botContext.setOpenImageModal}
          />
        )}
        {botContext.openClientBillModal && (
          <ClientBillModal
            openModal={botContext.openClientBillModal}
            closeModal={() => {
              botContext.setOpenClientBillModal(false);

              botContext.setClientBillingData((prev) => ({
                ...prev,
                loading: true,
              }));
            }}
            data={botContext.clientBillingData}
          />
        )}

        {botContext.openFileNotRecognizedModal && (
          <FileNotRecognizedModal
            openFileNotRecognizedModal={botContext.openFileNotRecognizedModal}
            setOpenFileNotRecognizedModal={
              botContext.setOpenFileNotRecognizedModal
            }
          />
        )}

        {botContext.openBotTrainingConfiguration && (
          <BotConfiguration
            open={botContext.openBotTrainingConfiguration}
            setOpen={botContext.setOpenBotTrainingConfiguration}
            propsUser={userLogged}
            propsCompany={companyUser}
          />
        )}
      </StyledNewBotContainer>
    </>
  );
};
