import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Grid,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { CircularProgressStyled } from "../../components/xmlModal/styles/XmlModalCss";
import {
  IDSystem,
  ListSystemVersion,
} from "../../repositories/panel/v1/integrations/VersionPortal";
import { GridStyled, Title } from "../../styles/GlobalStyle";

export const Versions = () => {
  const [value, setValue] = useState(0);
  const [system, setSystem] = useState(0);
  const [dataVersion, setDataVersion] = useState([]);
  const [HTMLTemplate, setHTMLTemplate] = useState("");
  const [idProduct, setidProduct] = useState("");
  const [loading, setLoading] = useState(false);
  const ref = useRef(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function TabPanel(props) {
    const { children, value, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== 0}
        aria-labelledby={`vertical-tab-${0}`}
        {...other}
      >
        {value === 0 && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  async function getHTMLVersion(id) {
    let response = await IDSystem(id);
    if (response.status === 200) {
      setHTMLTemplate(
        Buffer.from(response.data.base64_html, "base64").toString()
      );
      setidProduct(response.data.id);
    }
  }

  useEffect(() => {
    (async () => {
      setLoading(true);
      let response = await ListSystemVersion();
      if (response === null) {
        setLoading(false);
        return;
      }
      if (response.status === 200) {
        setLoading(false);
        getHTMLVersion(response.data[0].id);
        setDataVersion(response.data);
        setSystem("Portal");
      } else {
        setLoading(true);
      }
    })();
  }, []);

  return (
    <Grid container justifyContent="space-between">
      <Grid item xs={12} container alignItems="center">
        <Grid item flex={1}>
          <Title color="blue">Notas de versão</Title>
        </Grid>
      </Grid>

      <Box sx={{ pb: 8 }} ref={ref} style={{ width: "100%" }}>
        <Paper>
          <BottomNavigation
            showLabels
            value={system}
            style={{ marginTop: "20px" }}
            onChange={(event, newValue) => {
              setSystem(newValue);
            }}
          >
            <BottomNavigationAction label="Qyon" value={"Portal"} />
            <BottomNavigationAction
              label="Gestão Fácil"
              value={"Gestão Fácil"}
            />
            <BottomNavigationAction label="Qyon Bank" value={"Qyon Bank"} />
            <BottomNavigationAction label="Contábil" value={"Contábil"} />
            <BottomNavigationAction label="Fiscal" value={"Fiscal"} />
            <BottomNavigationAction label="Folha" value={"Folha"} />
            <BottomNavigationAction label="CND" value={"CND"} />
            <BottomNavigationAction label="CRM" value={"CRM"} />
            <BottomNavigationAction label="XML" value={"XML"} />
          </BottomNavigation>
        </Paper>
        {loading === true && <CircularProgressStyled />}
        {dataVersion.map(
          (item) =>
            system === item.product && (
              <Box
                sx={{
                  flexGrow: 1,
                  bgcolor: "background.paper",
                  display: "flex",
                }}
              >
                <Tabs
                  orientation="vertical"
                  variant="scrollable"
                  value={value}
                  onChange={handleChange}
                >
                  <Tab
                    label={item.version}
                    onClick={() => getHTMLVersion(item.id)}
                  />
                </Tabs>

                <TabPanel value={value}>
                  <Title fontSize="16">{item.name}</Title>
                  {item.id === idProduct && (
                    <div
                      style={{
                        border: "1px dashed grey",
                        maxWidth: "800px",
                        marginTop: "10px",
                        marginRight: "30px",
                      }}
                      dangerouslySetInnerHTML={{ __html: HTMLTemplate }}
                    />
                  )}
                </TabPanel>
              </Box>
            )
        )}
        {dataVersion.filter((e) => e.product === system).length === 0 && (
          <Box
            sx={{
              flexGrow: 1,
              bgcolor: "background.paper",
              display: "flex",
            }}
          >
            <GridStyled
              margin="10px 0px 0px 0"
              item
              flex={1}
              container
              justifyContent="center"
            >
              <Title
                fontSize="16"
                style={{ marginTop: "55px", marginBottom: "55px" }}
              >
                Não há novas atualizações nesse sistema.
              </Title>
            </GridStyled>
          </Box>
        )}
      </Box>
    </Grid>
  );
};
