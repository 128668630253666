import { useContext, useEffect, useState } from "react";
import { CircularProgress, Grid, Tooltip } from "@material-ui/core";
import { getCertificatesByDocument } from "../../repositories/panel/v1/CertificateRepository";
import moment from "moment/moment";
import {
  CertificateColumn,
  CertificateDetailsDotSeparator,
  CertificateDetailsGrid,
  CertificateDetailsText,
  CertificateNormal,
  CertificateWillExpire,
  ExpiredCertificate,
} from "./styles/Certificate";
import { getExpirationInDays } from "./util/DateUtil";

import { TemplateTypeChips } from "../../components/modal/styles/ModalEmailTemplates";
import CertificateDetailView from "./CertificateDetail";
import CertificateECACView from "./CertificateECAC";
import { CustumerManagementContext } from "../customerManagements/contexts/CustomerManagementContext";

export default function CertificateListView() {
  const { documentCustomer, companyName } = useContext(
    CustumerManagementContext
  );
  const [loading, setLoading] = useState(true);
  const [currentCertificate, setCurrentCertificate] = useState();
  const [chipValue, setChipValue] = useState(1);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    setLoading(true);
    const responseCertificate = await getCertificatesByDocument(
      documentCustomer
    );
    setCurrentCertificate(responseCertificate);

    setLoading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formatDate = (date) => {
    if (!date) {
      return "";
    }
    return moment(date).format("DD/MM/YYYY");
  };

  let daysUntilExpire = getExpirationInDays(currentCertificate?.valid_to);
  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "30px",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          {!currentCertificate?.certificate_id ? (
            <Grid
              direction="row"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <CertificateDetailsText>
                Nenhum Certificado Encontrado
              </CertificateDetailsText>
            </Grid>
          ) : (
            <CertificateDetailsGrid container direction="row">
              <Grid item>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "8px" }}
                >
                  <CertificateDetailsText>Certificado</CertificateDetailsText>
                  <CertificateColumn>
                    {currentCertificate.technology}
                  </CertificateColumn>
                  {currentCertificate?.procuration && (
                    <CertificateColumn>Procuração</CertificateColumn>
                  )}
                  <CertificateDetailsDotSeparator>
                    •
                  </CertificateDetailsDotSeparator>
                </div>
              </Grid>

              <Grid item>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "8px" }}
                >
                  <CertificateDetailsText>Expiração</CertificateDetailsText>
                  {daysUntilExpire <= 0 ? (
                    <Tooltip
                      title={moment(currentCertificate.valid_to).format(
                        "DD/MM/YYYY"
                      )}
                    >
                      <ExpiredCertificate>Expirado</ExpiredCertificate>
                    </Tooltip>
                  ) : daysUntilExpire <= 7 ? (
                    <Tooltip
                      title={moment(currentCertificate.valid_to).format(
                        "DD/MM/YYYY"
                      )}
                    >
                      <CertificateWillExpire>
                        {daysUntilExpire} Dias
                      </CertificateWillExpire>
                    </Tooltip>
                  ) : (
                    <Tooltip
                      title={moment(currentCertificate.valid_to).format(
                        "DD/MM/YYYY"
                      )}
                    >
                      <CertificateNormal>
                        {getExpirationInDays(currentCertificate.valid_to)} Dias
                      </CertificateNormal>
                    </Tooltip>
                  )}
                  <CertificateDetailsDotSeparator>
                    •
                  </CertificateDetailsDotSeparator>
                </div>
              </Grid>
              <Grid item>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "8px" }}
                >
                  <CertificateDetailsText>Inicio</CertificateDetailsText>

                  <p>{formatDate(currentCertificate.valid_from)}</p>
                  {currentCertificate?.procuration && (
                    <CertificateDetailsDotSeparator>
                      •
                    </CertificateDetailsDotSeparator>
                  )}
                </div>
              </Grid>
              {currentCertificate?.procuration && (
                <>
                  <Grid item>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                      }}
                    >
                      <CertificateDetailsText>
                        Outorgante
                      </CertificateDetailsText>
                      <p>{companyName}</p>
                      <CertificateDetailsDotSeparator>
                        •
                      </CertificateDetailsDotSeparator>
                    </div>
                  </Grid>
                  <Grid item>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                      }}
                    >
                      <CertificateDetailsText>Outorgado</CertificateDetailsText>
                      <p> {currentCertificate?.company_name}</p>
                    </div>
                  </Grid>
                </>
              )}
            </CertificateDetailsGrid>
          )}

          <Grid
            container
            justifyContent="flex-start"
            xs={12}
            style={{
              gap: "30px",
              marginBottom: "16px",
              marginTop: "16px",
            }}
          >
            <TemplateTypeChips
              onClick={() => {
                setChipValue(1);
              }}
              active={chipValue === 1}
            >
              Visualizar Procurações
            </TemplateTypeChips>
            <TemplateTypeChips
              onClick={() => {
                setChipValue(2);
              }}
              active={chipValue === 2}
            >
              Checagens por Procuração
            </TemplateTypeChips>
          </Grid>

          {chipValue === 1 ? (
            <CertificateDetailView
              companyDocument={currentCertificate?.document}
            />
          ) : chipValue === 2 ? (
            <CertificateECACView
              companyDocument={currentCertificate?.document}
            />
          ) : null}
        </>
      )}
    </>
  );
}
