import { useEffect, useState } from "react";
import {
  Grid,
  InputAdornment,
  LinearProgress,
  makeStyles,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import moment from "moment/moment";
import {
  CertificateDataGrid,
  CertificateNormal,
  CertificateWillExpire,
  CompanyColumn,
  DateColumn,
  ExpiredCertificate,
  ProcurationActiveColumn,
  ProcurationCanceledColumn,
  ProcurationExpiredColumn,
  ProcurationRejectedColumn,
} from "./styles/Certificate";
import { getProcurations } from "../../repositories/panel/v1/ProcurationRepository";
import { GridOverlay, GridPagination } from "@material-ui/data-grid";
import ProcurationPermissionModal from "./ProcurationPermissionModal";
import { formatCNPJorCPF } from "./util/Formatter";
import { getExpirationInDays } from "./util/DateUtil";
import { VerificationInProgressIcon, VerifiedIcon } from "./CertificateIcon";
import { notify } from "../../utils/notify";

export default function CertificateDetailView({ companyDocument, ...props }) {
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [tmpRows, setTmpRows] = useState([]);
  const [textFilter, setTextFilter] = useState("");
  const [selectedProcuration, setSelectedProcuration] = useState(null);
  const [showProcurationModal, setShowProcurationModal] = useState(false);
  const [pageSize, setPageSize] = useState(10);

  let columns = [
    {
      field: "grantor_name",
      headerName: "Outorgante",
      flex: 2,
      sortable: false,
      renderCell: ({ row }) => {
        if (!row.grantor_name) {
          return <CompanyColumn>CADASTRADO MANUALMENTE</CompanyColumn>;
        }

        return row.grantor_name;
      },
    },
    {
      field: "grantor_document",
      headerName: "CPF/CNPJ",
      flex: 2,
      sortable: false,
      renderCell: ({ row }) => {
        return formatCNPJorCPF(row.grantor_document);
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      sortable: false,

      renderCell: ({ row }) => {
        if (!row.status) {
          return "";
        }

        if (row.status === "ACTIVE") {
          return <ProcurationActiveColumn>Ativa</ProcurationActiveColumn>;
        }

        if (row.status === "EXPIRED") {
          return <ProcurationExpiredColumn>Expirada</ProcurationExpiredColumn>;
        }

        if (row.status === "CANCELED") {
          return (
            <ProcurationCanceledColumn>Cancelada</ProcurationCanceledColumn>
          );
        }

        if (row.status === "REJECTED") {
          return (
            <ProcurationRejectedColumn>Rejeitada</ProcurationRejectedColumn>
          );
        }

        return <div>DESCONHECIDA</div>;
      },
    },
    {
      field: "start_in",
      headerName: "Início",
      width: 100,
      sortable: false,

      renderCell: ({ row }) => {
        if (!row.start_date) {
          return "";
        }

        return <DateColumn>{formatDate(row.start_date)}</DateColumn>;
      },
    },
    {
      field: "expire_in",
      headerName: "Expiração",
      width: 150,
      sortable: false,

      renderCell: ({ row }) => {
        if (!row.end_date) {
          return "";
        }

        let daysUntilExpire = getExpirationInDays(row.end_date);
        if (daysUntilExpire <= 0) {
          return (
            <Tooltip title={moment(row.end_date).format("DD/MM/YYYY")}>
              <ExpiredCertificate>Expirado</ExpiredCertificate>
            </Tooltip>
          );
        }

        if (daysUntilExpire <= 7) {
          return (
            <Tooltip title={moment(row.end_date).format("DD/MM/YYYY")}>
              <CertificateWillExpire>
                {daysUntilExpire} Dias
              </CertificateWillExpire>
            </Tooltip>
          );
        }

        return (
          <Tooltip title={moment(row.end_date).format("DD/MM/YYYY")}>
            <CertificateNormal>
              {getExpirationInDays(row.end_date)} Dias
            </CertificateNormal>
          </Tooltip>
        );
      },
    },
    {
      field: "verified",
      headerName: "Verificado",
      width: 80,
      sortable: false,
      renderCell: ({ row }) => {
        if (row.verified === false) {
          return (
            <Tooltip title={"Verificação em progresso"}>
              <div style={{ marginLeft: 20 }}>
                <VerificationInProgressIcon />
              </div>
            </Tooltip>
          );
        }
        return (
          <Tooltip title={"Verificação em progresso"}>
            <div style={{ marginLeft: 20 }}>
              <VerifiedIcon />
            </div>
          </Tooltip>
        );
      },
    },

    {
      field: "actions",
      headerName: "",
      sortable: false,
      align: "right",
      width: 50,
      renderHeader: () => <></>,
      renderCell: ({ row }) => {
        return (
          <Tooltip title={"Visualizar detalhes"}>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                setSelectedProcuration(row);
                setShowProcurationModal(true);
              }}
            >
              <VisibilityOutlinedIcon style={{ color: "#616161" }} />
            </div>
          </Tooltip>
        );
      },
    },
  ];

  const customPagination = ({ pagination }) => {
    return (
      <GridPagination
        nextIconButtonText="Próxima página"
        backIconButtonText="Página anterior"
        rowsPerPageOptions={[10, 15, 20]}
        labelRowsPerPage="Linhas por página"
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} de ${count} `
        }
      />
    );
  };

  const customLocaleText = {
    noRowsLabel: "Sem Registros",
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      "& .MuiDataGrid-columnsContainer": {
        // Cor de fundo do cabeçalho
        background: "#F5F5F5",
        alignItems: "center",
        paddingTop: "10px",
      },

      "& .MuiDataGrid-columnSeparator": {
        display: "none !important",
      },
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    console.log("companyDocument", companyDocument);
    if (companyDocument) {
      setLoading(true);
      getProcurations({
        grantedDocument: companyDocument,
      })
        .then((procurations) => {
          setRows(procurations);
          setTmpRows(procurations);
          setLoading(false);
        })
        .catch((e) => {
          console.error(e);
          notify("Não foi possível carregar as procurações");
          setRows([]);
          setTmpRows([]);
          setLoading(false);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyDocument]);

  useEffect(() => {
    if (loading === true) {
      return;
    }
    const delayDebounceFn = setTimeout(() => {
      if (textFilter === "") {
        setTmpRows(rows);
        return;
      }
      const rowsFiltered = rows.filter((e) => {
        const search = textFilter
          .toLowerCase()
          .replace("-", "")
          .replace("-", "")
          .replace("/", "")
          .replace(".", "");
        const name = e.grantor_name.toLowerCase();
        const document = e.grantor_document
          .replace("-", "")
          .replace("-", "")
          .replace("/", "")
          .replace(".", "");

        if (name.includes(search) || document.includes(search)) {
          return true;
        }

        return false;
      });

      setTmpRows(rowsFiltered);
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textFilter]);

  const formatDate = (date) => {
    if (!date) {
      return "";
    }
    return moment(date).format("DD/MM/YYYY");
  };

  return (
    <>
      <ProcurationPermissionModal
        openModal={showProcurationModal}
        selectedProcuration={selectedProcuration}
        closeModal={() => {
          setSelectedProcuration(null);
          setShowProcurationModal(false);
        }}
      />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            placeholder="Pesquisar por nome e CPF/CNPJ do outorgante"
            value={textFilter}
            onChange={(e) => setTextFilter(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>

      <CertificateDataGrid
        className={classes.root}
        disableColumnFilter
        disableColumnMenu
        style={{ marginTop: 20 }}
        autoHeight
        rows={loading ? [] : tmpRows}
        columns={columns}
        slots={{
          loadingOverlay: LinearProgress,
        }}
        loading={loading}
        disableSelectionOnClick
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        components={{
          Pagination: customPagination,

          NoRowsOverlay: () =>
            !loading && (
              <GridOverlay>
                <h4>
                  Nenhuma procuração encontrada no momento!<br></br>
                  Rastreamos constantemente procurações outorgadas para sua
                  empresa! Assim que uma procuração for detectada, ela aparecerá
                  aqui.
                </h4>
              </GridOverlay>
            ),
        }}
        localeText={customLocaleText}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
      />
    </>
  );
}
