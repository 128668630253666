import {
  Checkbox,
  Chip,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  ButtonCancel,
  ButtonSave,
  CircleIcon,
  CloseIconStyle,
  DialogHeader,
  DialogStyle,
} from "../../../components/modal/Modal";
import MaskService from "../../../utils/mask/maskService";
import SubdirectoryArrowLeftIcon from "@material-ui/icons/SubdirectoryArrowLeft";
import { CompanyCard } from "./styles/CompanyModal";
import LogoQyonInteligence from "../../../components/logos/LogoQyonInteligence";

export const ModalUpdateCertificate = (props) => {
  return (
    <DialogStyle>
      <DialogHeader>
        <div className="row">
          <LogoQyonInteligence></LogoQyonInteligence>

          <CloseIconStyle
            onClick={() => {
              props.closeCertificateModal();
            }}
          />
        </div>
      </DialogHeader>

      <DialogContent style={{ maxHeight: 450 }}>
        <div
          style={{
            fontSize: 21,
            fontWeight: 700,
            color: "#0036B3",
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 10,
            alignItems: "center",
          }}
        >
          {props.getSecondTitle()}
        </div>

        <h3
          style={{
            fontWeight: 700,
            marginBottom: 10,
            marginTop: 0,
          }}
        >
          Selecione as empresas que deseja cadastrar:
        </h3>

        {props.certificateListState.map((certificate, index) => {
          if (certificate.action !== "procuration") {
            certificate.procurationCompanyList = [];
          }
          return (
            <>
              <Grid key={"CERTIFICATE" + index}>
                {index >= 1 && <br />}
                <CompanyCard>
                  <Grid container>
                    <Grid
                      item
                      xs={1}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Checkbox
                        color="primary"
                        checked={certificate["inviteCreateCompany"]}
                        onChange={(ev) => {
                          certificate["inviteCreateCompany"] =
                            ev.target.checked;
                          props.setCertificateListState([
                            ...props.certificateListState,
                          ]);
                        }}
                        style={{
                          width: "20px",
                          height: "5px",
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CircleIcon>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="30"
                          fill="none"
                          viewBox="0 0 24 30"
                        >
                          <path
                            fill="#0036B3"
                            fillRule="evenodd"
                            d="M1 2.5a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v6.382l6.553-3.276A1 1 0 0 1 15 6.5v2.382l6.553-3.276A1 1 0 0 1 23 6.5v16a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-20Zm2 1v18h18V8.118l-6.553 3.276A1 1 0 0 1 13 10.5V8.118l-6.553 3.276A1 1 0 0 1 5 10.5v-7H3Zm2 11a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1v-4Zm2 1v2h2v-2H7Zm6-1a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1v-4Zm2 1v2h2v-2h-2Z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </CircleIcon>
                    </Grid>

                    <Grid item xs={9}>
                      <Typography
                        id="modal-modal-title"
                        style={{ fontWeight: 700, fontSize: 18 }}
                      >
                        {certificate.company.companyName}
                      </Typography>

                      <Typography id="modal-modal-title" variant="body2">
                        {certificate.action === "procuration" ? (
                          <b>
                            Certificado de Procuração
                            <br />
                          </b>
                        ) : (
                          ""
                        )}

                        {certificate.company.clientCnpj.length === 14 && (
                          <>
                            CNPJ:{" "}
                            {MaskService.toMask(
                              "cnpj",
                              certificate.company.clientCnpj
                            )}
                          </>
                        )}

                        {certificate.company.clientCnpj.length === 11 && (
                          <>
                            CPF:{" "}
                            {MaskService.toMask(
                              "cpf",
                              certificate.company.clientCnpj
                            )}
                          </>
                        )}
                      </Typography>

                      <Typography id="modal-modal-title" variant="body2">
                        {certificate.name}
                        <br></br>
                      </Typography>

                      {certificate?.company?.needsEmail && (
                        <TextField
                          label="Informe o e-mail de acesso para seu cliente"
                          variant="outlined"
                          key={index}
                          style={{
                            marginBottom: 20,
                            marginTop: 10,
                            width: "90%",
                          }}
                          defaultValue={""}
                          onChange={(ev) => {
                            props.certificateListState[index].company.email =
                              ev.target.value;
                          }}
                        />
                      )}
                    </Grid>
                  </Grid>
                </CompanyCard>

                {certificate.procurationCompanyList.map(
                  (procuration, index) => {
                    return (
                      <CompanyCard style={{ marginTop: 20 }}>
                        <Grid container>
                          <Grid
                            item
                            xs={1}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Checkbox
                              color="primary"
                              checked={procuration["inviteCreateCompany"]}
                              onChange={(ev) => {
                                procuration["inviteCreateCompany"] =
                                  ev.target.checked;
                                props.setCertificateListState([
                                  ...props.certificateListState,
                                ]);
                                if (ev.target.checked) {
                                  if (procuration?.existsInPortal) {
                                    let newList = [
                                      ...props.listCompaniesCreated,
                                    ];
                                    newList.push(procuration.company);
                                    props.setListCompaniesCreated(newList);
                                  } else {
                                    let newList = [
                                      ...props.listCompaniesNotCreated,
                                    ];
                                    newList.push(procuration.company);
                                    props.setListCompaniesNotCreated(newList);
                                  }
                                } else {
                                  if (procuration?.existsInPortal) {
                                    let newList = [
                                      ...props.listCompaniesCreated,
                                    ];
                                    newList = newList.filter(
                                      (item) =>
                                        item.clientCnpj !==
                                        procuration.company.clientCnpj
                                    );
                                    props.setListCompaniesCreated(newList);
                                  } else {
                                    let newList = [
                                      ...props.listCompaniesNotCreated,
                                    ];
                                    newList = newList.filter(
                                      (item) =>
                                        item.clientCnpj !==
                                        procuration.company.clientCnpj
                                    );
                                    props.setListCompaniesNotCreated(newList);
                                  }
                                }
                              }}
                              style={{
                                width: "20px",
                                height: "5px",
                              }}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={2}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <CircleIcon>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="30"
                                fill="none"
                                viewBox="0 0 24 30"
                              >
                                <path
                                  fill="#0036B3"
                                  fillRule="evenodd"
                                  d="M1 2.5a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v6.382l6.553-3.276A1 1 0 0 1 15 6.5v2.382l6.553-3.276A1 1 0 0 1 23 6.5v16a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-20Zm2 1v18h18V8.118l-6.553 3.276A1 1 0 0 1 13 10.5V8.118l-6.553 3.276A1 1 0 0 1 5 10.5v-7H3Zm2 11a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1v-4Zm2 1v2h2v-2H7Zm6-1a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1v-4Zm2 1v2h2v-2h-2Z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </CircleIcon>
                          </Grid>
                          <Grid item xs={9}>
                            <Typography
                              id="modal-modal-title"
                              style={{
                                fontWeight: 700,
                                fontSize: 18,
                                display: "flex",
                              }}
                            >
                              {procuration.company.companyName}
                              <div style={{ marginLeft: 10 }}>
                                <Chip
                                  size="small"
                                  label="Procuração"
                                  style={{ background: "orange" }}
                                ></Chip>
                              </div>
                            </Typography>

                            <Typography id="modal-modal-title" variant="body2">
                              {certificate.action === "procuration" ? (
                                <b>
                                  Certificado de Procuração
                                  <br />
                                </b>
                              ) : (
                                ""
                              )}

                              {procuration.company.clientCnpj.length === 14 && (
                                <>
                                  CNPJ:{" "}
                                  {MaskService.toMask(
                                    "cnpj",
                                    procuration.company.clientCnpj
                                  )}
                                </>
                              )}

                              {procuration.company.clientCnpj.length === 11 && (
                                <>
                                  CPF:{" "}
                                  {MaskService.toMask(
                                    "cpf",
                                    procuration.company.clientCnpj
                                  )}
                                </>
                              )}
                            </Typography>

                            <Typography id="modal-modal-title" variant="body2">
                              {procuration.name}
                              <br></br>
                            </Typography>

                            {procuration.registerEmail && (
                              <TextField
                                label="Informe o e-mail de acesso para seu cliente"
                                variant="outlined"
                                key={index}
                                style={{
                                  marginBottom: 20,
                                  marginTop: 10,
                                  width: "90%",
                                }}
                                defaultValue={""}
                                onChange={(ev) => {
                                  procuration.company.email = ev.target.value;
                                }}
                                InputLabelProps={{
                                  style: { fontSize: "15px" },
                                }}
                              />
                            )}
                          </Grid>
                        </Grid>
                      </CompanyCard>
                    );
                  }
                )}
              </Grid>
            </>
          );
        })}
      </DialogContent>

      <DialogActions style={{ paddingRight: 20, paddingBottom: 20 }}>
        {props.disabled === false && (
          <>
            <ButtonCancel
              style={{ marginRight: 16 }}
              onClick={() => {
                if (props.disabled === false) {
                  props.closeCertificateModal();
                }
              }}
              startIcon={<SubdirectoryArrowLeftIcon />}
              disabled={props.disabled}
            >
              Cancelar
            </ButtonCancel>
            &nbsp;
          </>
        )}

        <ButtonSave
          onClick={async () => {
            props.saveUpdateCertificate();
          }}
          disabled={props.disabled}
        >
          {props.disabled ? "Carregando..." : props.textButtonSave}
        </ButtonSave>
      </DialogActions>
    </DialogStyle>
  );
};
