import { useContext } from "react";
import {
  useEffect,
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";

import { GlobalContext } from "../../../../contexts/GlobalContext";
import {
  getBotImageGif,
  getBotInteration,
  markInterationAsVisualized,
} from "../../../../repositories/Bot";
import {
  BatteryDiv,
  BatteryMessageContainer,
  BatteryMessageItalic,
  BatteryText,
  BotActions,
  BotContainer,
} from "./styles/Bot";
import {
  CircularProgress,
  Grid,
  IconButton,
  Tooltip,
  withStyles,
} from "@material-ui/core";
import BatteryImages from "./components/BatteryImages";
import BotActionIcons from "./components/BotActionIcons";
import { NewBotContext } from "../../../newBot/context/NewBotContext";
import { getBoletoById } from "../../../../repositories/integrations/erp/ContractQyonRepository";

const Bot = forwardRef((props, ref) => {
  const times = useRef(0);
  // eslint-disable-next-line no-unused-vars
  const [animation, setAnimation] = useState(null);
  const [nextAnimation, setNextAnimation] = useState(null);
  const [image, setImage] = useState(null);
  const [imageMap, setImageMap] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const { paramsQyonBot, setParamsQyonBot } = useContext(GlobalContext);
  const [chatModeActive, setChatModeActive] = useState(false);

  const botContext = useContext(NewBotContext);

  useEffect(() => {
    reset();
    if (window.location.href.includes("dashboard/view")) {
      animateBot("materialize");
    } else {
      animateBot("waiting");
    }

    const currentDate = new Date();

    if (
      currentDate.getMonth() === 8 &&
      currentDate.getDate() >= 5 &&
      currentDate.getDate() <= 8
    ) {
      setNextAnimation("7_september");
    } else if (
      currentDate.getMonth() === 11 &&
      currentDate.getDate() >= 22 &&
      currentDate.getDate() <= 27
    ) {
      setNextAnimation("christmas");
    } else if (
      (currentDate.getMonth() === 11 || currentDate.getMonth() === 0) &&
      currentDate.getDate() >= 28 &&
      currentDate.getDate() <= 4
    ) {
      const probNewYear = Math.random();

      if (probNewYear <= 0.5) {
        setNextAnimation("new_year");
      } else {
        setNextAnimation("new_year_celebration");
      }
    } else {
      setNextAnimation("waiting");
    }

    const data = async () => {
      let imageGif = await getBotImageGif();
      imageGif += paramsQyonBot.haveBankAccount ? "1" : "0";
      setImage(imageGif);
      setTimeout(data, 300000);

      const botInteration = await getBotInteration();
      if (botInteration !== null) {
        const { id, text } = botInteration;

        if (text !== null && text !== "" && text !== undefined) {
          botContext.setBotMode("CHAT");

          botContext.setBotPresentationRunning(null);
          if (botContext.isBotPresentationRunning === true) {
            ref.current.stop_bot_presentation_to_open_chat();
          }
          botContext.setFirstBotMessageText(text);
          botContext.setBotMode("CHAT");
          botContext.setOpenBotChatModal(true);
          await markInterationAsVisualized(id);
        }
      }
    };

    data();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useImperativeHandle(ref, () => ({
    listen() {
      setNextAnimation("listening");
    },

    think() {
      setNextAnimation("thinking");
    },

    answer() {
      setNextAnimation("answer");
    },

    a1_upload() {
      setNextAnimation("a1_certificate");
    },

    seven_september() {
      setNextAnimation("7_september");
    },

    christmas() {
      setNextAnimation("christmas");
    },

    new_year_celebration() {
      setNextAnimation("new_year_celebration");
    },

    new_year() {
      setNextAnimation("new_year");
    },

    pdf_upload() {
      setNextAnimation("pdf_upload");
    },

    logo_upload() {
      setNextAnimation("logo_upload");
    },

    bot_presentation() {
      setNextAnimation("bot_presentation");
    },

    stop_bot_presentation() {
      document.getElementById("bot_presentation").pause();
      document.getElementById("bot_presentation").currentTime = 0;
      setAnimation("waiting");
      handleVideoEnded("waiting");
    },
    stop_bot_presentation_to_open_chat() {
      document.getElementById("bot_presentation").pause();
      document.getElementById("bot_presentation").currentTime = 0;
      setAnimation("start_bot_neon");
      handleVideoEnded("start_bot_neon");
    },

    start_bot() {
      setNextAnimation("start_bot");
      setChatModeActive(true);
    },

    end_bot() {
      setNextAnimation("end_bot");
      setChatModeActive(false);
    },
  }));

  const events = [
    "waiting_neon",
    "waiting",
    "thinking",
    "thinking_neon",
    "download_xml",
    "cnd",
    "government",
    "waiting_2",
    "waiting_3",
    "waiting_braces",
    "materialize",
    "esocial",
    "internet",
    "answer",
    "listening",
    "a1_certificate",
    "christmas",
    "new_year_celebration",
    "new_year",
    "7_september",
    "logo_upload",
    "pdf_upload",
    "start_bot_neon",
    "end_bot",
    "end_bot_neon",
    "answer_neon",
    "cnd_neon",
    "download_xml_neon",
    "esocial_neon",
    "government_neon",
    "internet_neon",
    "listening_neon",
    "logo_upload_neon",
    "pdf_upload_neon",
    "processing_neon",
    "bot_presentation",
    "aerosports",
    "bill_will_expire",
    "bill_expired",
    "metaverse",
    "ead",
  ];

  const reset = () => {
    for (let event of events) {
      if (document.getElementById(event) !== null) {
        document.getElementById(event).style.display = "none";
      }
    }
  };

  const animateBot = (ev) => {
    for (let event of events) {
      if (event !== ev) {
        if (document.getElementById(event) !== null) {
          document.getElementById(event).style.display = "none";
        }
      } else {
        setAnimation(event);
        if (document.getElementById(event) !== null) {
          document.getElementById(event).style.display = "block";
          document.getElementById(event).play();
        }
      }
    }
  };

  const handleVideoEnded = (animation) => {
    const neonStr = chatModeActive ? "_neon" : "";

    times.current++;
    props.setAnimationFinished({
      animation: animation + neonStr,
    });

    let nxtAnimaton = nextAnimation;

    if (nxtAnimaton === null) {
      if (times.current % 5 === 0 && chatModeActive === false) {
        let prob = Math.random();
        if (prob < 0.2) {
          setAnimation("download_xml" + neonStr);
          animateBot("download_xml" + neonStr);
        } else if (prob >= 0.2 && prob < 0.4) {
          setAnimation("government" + neonStr);
          animateBot("government" + neonStr);
        } else if (prob >= 0.4 && prob < 0.6) {
          setAnimation("internet" + neonStr);
          animateBot("internet" + neonStr);
        } else if (prob > 0.6 && prob < 0.8) {
          setAnimation("esocial" + neonStr);
          animateBot("esocial" + neonStr);
        } else if (prob > 0.8 && prob < 0.95) {
          const currentDate = new Date();
          const probEvent = Math.random();

          if (
            currentDate.getMonth() === 8 &&
            currentDate.getDate() >= 5 &&
            currentDate.getDate() <= 8 &&
            probEvent <= 0.7
          ) {
            setAnimation("7_september");
            animateBot("7_september");
          } else if (
            currentDate.getMonth() === 11 &&
            currentDate.getDate() >= 22 &&
            currentDate.getDate() <= 27 &&
            probEvent <= 0.7
          ) {
            setAnimation("christmas");
            animateBot("christmas");
          } else if (
            (currentDate.getMonth() === 11 || currentDate.getMonth() === 0) &&
            currentDate.getDate() >= 28 &&
            currentDate.getDate() <= 4 &&
            probEvent <= 0.7
          ) {
            const newProbChristmas = Math.random();

            if (newProbChristmas <= 0.5) {
              setAnimation("new_year");
              animateBot("new_year");
            } else {
              setAnimation("new_year_celebration");
              animateBot("new_year_celebration");
            }
          } else {
            const prob1 = Math.random();

            if (prob1 <= 0.2) {
              setAnimation("aerosports" + neonStr);
              animateBot("aerosports" + neonStr);
            } else if (prob1 > 0.2 && prob1 <= 0.4) {
              setAnimation("metaverse" + neonStr);
              animateBot("metaverse" + neonStr);
            } else if (prob1 > 0.4 && prob1 <= 0.6) {
              setAnimation("ead" + neonStr);
              animateBot("ead" + neonStr);
            } else if (prob1 > 0.6 && prob1 <= 0.8) {
              let energyStatus = botContext.batteryStatus.status;
              if (energyStatus === "DUE") {
                setAnimation("bill_expired" + neonStr);
                animateBot("bill_expired" + neonStr);
              } else if (energyStatus === "NORMAL") {
                if (botContext.batteryStatus.diff * -1 <= 7) {
                  setAnimation("bill_will_expire" + neonStr);
                  animateBot("bill_will_expire" + neonStr);
                } else {
                  setAnimation("cnd" + neonStr);
                  animateBot("cnd" + neonStr);
                }
              } else {
                setAnimation("cnd" + neonStr);
                animateBot("cnd" + neonStr);
              }
            } else {
              setAnimation("cnd" + neonStr);
              animateBot("cnd" + neonStr);
            }
          }
        } else {
          setAnimation("cnd" + neonStr);
          animateBot("cnd" + neonStr);
        }
      } else {
        const prob = Math.random();

        if (chatModeActive) {
          setAnimation("waiting" + neonStr);
          animateBot("waiting" + neonStr);
        } else {
          if (prob <= 0.8) {
            setAnimation("waiting" + neonStr);
            animateBot("waiting" + neonStr);
          } else if (prob > 0.8 && prob <= 0.9) {
            setAnimation("waiting_braces" + neonStr);
            animateBot("waiting_braces" + neonStr);
          } else {
            setAnimation("waiting_2" + neonStr);
            animateBot("waiting_2" + neonStr);
          }
        }
      }
    } else {
      setAnimation(nxtAnimaton + neonStr);
      animateBot(nxtAnimaton + neonStr);
      setNextAnimation(null);
    }
  };

  const mouseover = (type) => {
    setImageMap(type);
  };

  const mouseLeave = () => {
    setImageMap(null);
  };

  const imageMapDesc = () => {
    if (imageMap === null) {
      return null;
    }
    if (imageMap === "SEFAZ") {
      return "SEFAZ";
    }

    if (imageMap === "ESOCIAL") {
      return "ESOCIAL";
    }

    if (imageMap === "CND") {
      return "CND";
    }

    if (imageMap === "QYON_BANK") {
      return "QYON BANK";
    }

    return null;
  };

  const RenderImageMapDescription = () => {
    const desc = imageMapDesc();
    const stls = {
      position: "absolute",
      color: "white",
      fontWeight: "bold",
      textAlign: "center",
      left: 60,
      right: 0,
      marginLeft: "auto",
      marginRight: "auto",
      top: 100,
    };
    if (desc === null) {
      return <div style={stls}>&nbsp;</div>;
    }
    return <div style={stls}>{desc}</div>;
  };

  useEffect(() => {
    switch (botContext.batteryStatus.status) {
      case "DUE_TODAY":
        botContext.setMessageBattery("Sua fatura vence hoje");
        break;
      case "SUSPEND":
        botContext.setMessageBattery("Seu cadastro está suspenso");
        break;
      default:
        botContext.setMessageBattery("");
        break;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [botContext.batteryStatus]);

  const BatteryTooltip = withStyles(() => ({
    tooltip: {
      backgroundColor: "#F2F2F2",
      color: "#333",
      maxWidth: 220,
      fontSize: 14,
      marginTop: 0,
    },
  }))(Tooltip);

  return (
    <BotContainer>
      <BatteryDiv showMessage={botContext.messageBattery !== ""}>
        {botContext.batteryStatus.image !== null && (
          <Grid container direction="row" justifyContent="space-between">
            <BatteryText>{botContext.messageBattery}</BatteryText>

            <BatteryTooltip
              placement="bottom-end"
              title={
                botContext.batteryStatus.onError ? (
                  <BatteryMessageContainer>
                    <BatteryMessageItalic>
                      Ocorreu um erro ao obter o nível da bateria
                    </BatteryMessageItalic>
                  </BatteryMessageContainer>
                ) : (
                  <BatteryMessageContainer>
                    {botContext.batteryStatus.status === "DUE_TODAY" && (
                      <>
                        <strong>Seu pagamento vence hoje. </strong> <br />
                        Pague seu boleto e continue utilizando as melhores
                        soluções para sua empresa.
                      </>
                    )}

                    {botContext.batteryStatus.status === "DUE" && (
                      <>
                        Seu boleto{" "}
                        <strong>
                          venceu há {botContext.batteryStatus.diff} dia
                          {botContext.batteryStatus.diff === 1 ? "" : "s"}.
                        </strong>
                        <br />
                        Evite a suspensão dos seus serviços. Efetue o pagamento
                        e continue utilizando as melhores soluções para sua
                        empresa
                      </>
                    )}

                    {botContext.batteryStatus.status === "SUSPEND" && (
                      <>
                        Seu cadastro está suspenso porque{" "}
                        <strong>não detectamos o pagamento</strong> de sua
                        conta. Regularize para voltar a utilizar os seus
                        serviços.
                        <BatteryMessageItalic>
                          Entre em contato através do email cobrança@qyon.com ou
                          pelo telefone (19) 4040-4800
                        </BatteryMessageItalic>
                      </>
                    )}

                    {botContext.batteryStatus.status === "NORMAL" && (
                      <>
                        <strong>
                          Para facilitar o seu dia a dia lembramos que falta
                          {botContext.batteryStatus.diff === -1
                            ? " "
                            : "m "}{" "}
                          apenas {""}
                          {botContext.batteryStatus.diff * -1} dia
                          {botContext.batteryStatus.diff === -1 ? " " : "s "}
                        </strong>
                        para o vencimento do seu boleto.
                        <BatteryMessageItalic>
                          Efetue o pagamento e continue utilizando as melhores
                          soluções para sua empresa.
                        </BatteryMessageItalic>
                      </>
                    )}

                    {botContext.batteryStatus.status === "WITHOUT_CONTRACT" && (
                      <BatteryMessageItalic>
                        Você não possui contratos
                      </BatteryMessageItalic>
                    )}

                    {botContext.batteryStatus.status === "" ? (
                      <>Carregando status da bateria.</>
                    ) : botContext.batteryStatus.status !==
                      "WITHOUT_CONTRACT" ? (
                      <BatteryMessageItalic>
                        {botContext.clientBillingData.enableBoleto
                          ? "Clique na bateria para ver o boleto"
                          : "O boleto não foi registrado"}
                      </BatteryMessageItalic>
                    ) : (
                      <></>
                    )}
                  </BatteryMessageContainer>
                )
              }
            >
              <div
                style={{ display: "flex", direction: "row", cursor: "pointer" }}
                onClick={async () => {
                  if (
                    botContext.batteryStatus.status !== "" &&
                    botContext.batteryStatus.status !== "WITHOUT_CONTRACT" &&
                    !botContext.batteryStatus.onError
                  ) {
                    botContext.setOpenClientBillModal(true);

                    if (!botContext.clientBillingData.enableBoleto) {
                      return botContext.setClientBillingData((prev) => ({
                        ...prev,
                        loading: false,
                        base64: "",
                        name: "",
                      }));
                    }

                    const response = await getBoletoById(
                      botContext.clientBillingData.id
                    );

                    if (response?.success) {
                      const { archive_base64, archive_name } =
                        await response.data;

                      botContext.setClientBillingData((prev) => ({
                        ...prev,
                        loading: false,
                        base64: archive_base64,
                        name: archive_name,
                      }));
                    } else {
                      botContext.setClientBillingData((prev) => ({
                        ...prev,
                        loading: false,
                        base64: "",
                        name: "",
                      }));
                    }
                  }
                }}
              >
                {botContext.batteryStatus.image ? (
                  <BatteryImages icon={botContext.batteryStatus.image} />
                ) : (
                  <BatteryImages icon="interrupt" />
                )}
              </div>
            </BatteryTooltip>
          </Grid>
        )}
        {botContext.batteryStatus.image === null && (
          <Grid
            container
            direction="row"
            justifyContent="center"
            style={{ padding: 4 }}
          >
            <CircularProgress size={20} />
          </Grid>
        )}
      </BatteryDiv>

      {image !== null && (
        <div>
          <RenderImageMapDescription />
          <img
            alt="Conexões do QYON Bot"
            src={`https://s3.sa-east-1.amazonaws.com/qyon.panel/images/${image}.gif`}
            usemap="#image-map"
            width={200}
            style={{ position: "absolute", top: 40, left: 77, zIndex: 9999 }}
          />

          <map name="image-map">
            <area
              target=""
              alt="SEFAZ"
              title="SEFAZ"
              coords="53,51,5,4"
              shape="rect"
              onMouseOver={() => mouseover("SEFAZ")}
              onMouseLeave={() => mouseLeave()}
            />
            <area
              target=""
              alt="ESOCIAL"
              title="ESOCIAL"
              coords="101,51,55,5"
              shape="rect"
              onMouseOver={() => mouseover("ESOCIAL")}
              onMouseLeave={() => mouseLeave()}
            />
            <area
              target=""
              alt="CND"
              title="CND"
              coords="153,5,105,50"
              shape="rect"
              onMouseOver={() => mouseover("CND")}
              onMouseLeave={() => mouseLeave()}
            />
            <area
              target="blank"
              alt="QYON BANK"
              title="QYON BANK"
              href="https://bank.qyon.com"
              coords="156,6,206,49"
              shape="rect"
              onMouseOver={() => mouseover("QYON_BANK")}
              onMouseLeave={() => mouseLeave()}
            />
          </map>
        </div>
      )}
      <div
        style={{ cursor: "pointer" }}
        onClick={() => {
          botContext.setBotMode("TOPIC");

          botContext.setBotPresentationRunning(null);
          if (botContext.isBotPresentationRunning === true) {
            ref.current.stop_bot_presentation_to_open_chat();
          }
          botContext.setOpenBotChatModal(true);
        }}
      >
        <video
          className="botVideoAction"
          autoplay
          muted
          id="waiting"
          onEnded={() => {
            handleVideoEnded("waiting");
          }}
        >
          <source
            src={`https://s3.sa-east-1.amazonaws.com/qyon.panel/videos/waiting.mp4`}
            type="video/mp4"
          ></source>
          <BatteryDiv>Bateria</BatteryDiv>
        </video>
        <video
          className="botVideoAction"
          autoplay
          muted
          id="waiting_braces"
          onEnded={() => {
            handleVideoEnded("waiting_braces");
          }}
        >
          <source
            src={`https://s3.sa-east-1.amazonaws.com/qyon.panel/videos/waiting_braces.mp4`}
            type="video/mp4"
          ></source>
        </video>
        <video
          className="botVideoAction"
          autoplay
          muted
          id="thinking"
          onEnded={() => {
            handleVideoEnded("thinking");
          }}
        >
          <source
            src={`https://s3.sa-east-1.amazonaws.com/qyon.panel/videos/thinking.mp4`}
            type="video/mp4"
          ></source>
        </video>
        <video
          className="botVideoAction"
          autoplay
          muted
          id="thinking_neon"
          onEnded={() => {
            handleVideoEnded("thinking_neon");
          }}
        >
          <source
            src={`https://s3.sa-east-1.amazonaws.com/qyon.panel/videos/thinking_neon.mp4`}
            type="video/mp4"
          ></source>
        </video>
        <video
          className="botVideoAction"
          autoplay
          muted
          id="download_xml"
          onEnded={() => {
            handleVideoEnded("download_xml");
          }}
        >
          <source
            src={`https://s3.sa-east-1.amazonaws.com/qyon.panel/videos/download_xml.mp4`}
            type="video/mp4"
          ></source>
        </video>
        <video
          className="botVideoAction"
          autoplay
          muted
          id="materialize"
          onEnded={() => {
            handleVideoEnded("materialize");
          }}
        >
          <source
            src={`https://s3.sa-east-1.amazonaws.com/qyon.panel/videos/materialize.mp4`}
            type="video/mp4"
          ></source>
        </video>
        <video
          className="botVideoAction"
          autoplay
          muted
          id="cnd"
          onEnded={() => {
            handleVideoEnded("cnd");
          }}
        >
          <source
            src={`https://s3.sa-east-1.amazonaws.com/qyon.panel/videos/cnd.mp4`}
            type="video/mp4"
          ></source>
        </video>
        <video
          className="botVideoAction"
          autoplay
          muted
          id="esocial"
          onEnded={() => {
            handleVideoEnded("esocial");
          }}
        >
          <source
            src={`https://s3.sa-east-1.amazonaws.com/qyon.panel/videos/esocial.mp4`}
            type="video/mp4"
          ></source>
        </video>
        <video
          className="botVideoAction"
          autoplay
          muted
          id="waiting_2"
          onEnded={() => {
            handleVideoEnded("waiting_2");
          }}
        >
          <source
            src={`https://s3.sa-east-1.amazonaws.com/qyon.panel/videos/waiting_2.mp4`}
            type="video/mp4"
          ></source>
        </video>
        <video
          className="botVideoAction"
          autoplay
          muted
          id="waiting_3"
          onEnded={() => {
            handleVideoEnded("waiting_3");
          }}
        >
          <source
            src={`https://s3.sa-east-1.amazonaws.com/qyon.panel/videos/waiting_3.mp4`}
            type="video/mp4"
          ></source>
        </video>
        <video
          className="botVideoAction"
          autoplay
          muted
          id="government"
          onEnded={() => {
            handleVideoEnded("government");
          }}
        >
          <source
            src={`https://s3.sa-east-1.amazonaws.com/qyon.panel/videos/government.mp4`}
            type="video/mp4"
          ></source>
        </video>
        <video
          className="botVideoAction"
          autoplay
          muted
          id="internet"
          onEnded={() => {
            handleVideoEnded("internet");
          }}
        >
          <source
            src={`https://s3.sa-east-1.amazonaws.com/qyon.panel/videos/internet.mp4`}
            type="video/mp4"
          ></source>
        </video>
        <video
          className="botVideoAction"
          autoplay
          muted
          id="answer"
          onEnded={() => {
            handleVideoEnded("answer");
          }}
        >
          <source
            src={`https://s3.sa-east-1.amazonaws.com/qyon.panel/videos/answer.mp4`}
            type="video/mp4"
          ></source>
        </video>
        <video
          className="botVideoAction"
          autoplay
          muted
          id="listening"
          onEnded={() => {
            handleVideoEnded("listening");
          }}
        >
          <source
            src={`https://s3.sa-east-1.amazonaws.com/qyon.panel/videos/listening.mp4`}
            type="video/mp4"
          ></source>
        </video>
        <video
          className="botVideoAction"
          autoplay
          muted
          id="logo_upload"
          onEnded={() => {
            handleVideoEnded("logo_upload");
          }}
        >
          <source
            src={`https://s3.sa-east-1.amazonaws.com/qyon.panel/videos/logo_upload.mp4`}
            type="video/mp4"
          ></source>
        </video>

        <video
          className="botVideoAction"
          autoplay
          muted
          id="christmas"
          onEnded={() => {
            handleVideoEnded("christmas");
          }}
        >
          <source
            src={`https://s3.sa-east-1.amazonaws.com/qyon.panel/videos/christmas.mp4`}
            type="video/mp4"
          ></source>
        </video>

        <video
          className="botVideoAction"
          autoplay
          muted
          id="new_year_celebration"
          onEnded={() => {
            handleVideoEnded("new_year_celebration");
          }}
        >
          <source
            src={`https://s3.sa-east-1.amazonaws.com/qyon.panel/videos/new_year_celebration.mp4`}
            type="video/mp4"
          ></source>
        </video>

        <video
          className="botVideoAction"
          autoplay
          muted
          id="new_year"
          onEnded={() => {
            handleVideoEnded("new_year");
          }}
        >
          <source
            src={`https://s3.sa-east-1.amazonaws.com/qyon.panel/videos/new_year.mp4`}
            type="video/mp4"
          ></source>
        </video>

        <video
          className="botVideoAction"
          autoplay
          muted
          id="a1_certificate"
          onEnded={() => {
            handleVideoEnded("a1_certificate");
          }}
        >
          <source
            src={`https://s3.sa-east-1.amazonaws.com/qyon.panel/videos/a1_certificate.mp4`}
            type="video/mp4"
          ></source>
        </video>
        <video
          className="botVideoAction"
          autoplay
          muted
          id="7_september"
          onEnded={() => {
            handleVideoEnded("7_september");
          }}
        >
          <source
            src={`https://s3.sa-east-1.amazonaws.com/qyon.panel/videos/7_september.mp4`}
            type="video/mp4"
          ></source>
        </video>
        <video
          className="botVideoAction"
          autoplay
          muted
          id="pdf_upload"
          onEnded={() => {
            handleVideoEnded("pdf_upload");
          }}
        >
          <source
            src={`https://s3.sa-east-1.amazonaws.com/qyon.panel/videos/pdf_upload.mp4`}
            type="video/mp4"
          ></source>
        </video>
        <video
          className="botVideoAction"
          autoplay
          muted
          id="start_bot_neon"
          onEnded={() => {
            handleVideoEnded("start_bot_neon");
          }}
        >
          <source
            src={`https://s3.sa-east-1.amazonaws.com/qyon.panel/videos/start_bot_neon.mp4`}
            type="video/mp4"
          ></source>
        </video>
        <video
          className="botVideoAction"
          autoplay
          muted
          id="end_bot"
          onEnded={() => {
            handleVideoEnded("end_bot");
          }}
        >
          <source
            src={`https://s3.sa-east-1.amazonaws.com/qyon.panel/videos/end_bot.mp4`}
            type="video/mp4"
          ></source>
        </video>
        <video
          className="botVideoAction"
          autoplay
          muted
          id="end_bot_neon"
          onEnded={() => {
            handleVideoEnded("end_bot_neon");
          }}
        >
          <source
            src={`https://s3.sa-east-1.amazonaws.com/qyon.panel/videos/end_bot_neon.mp4`}
            type="video/mp4"
          ></source>
        </video>
        <video
          className="botVideoAction"
          autoplay
          muted
          id="waiting_neon"
          onEnded={() => {
            handleVideoEnded("waiting_neon");
          }}
        >
          <source
            src={`https://s3.sa-east-1.amazonaws.com/qyon.panel/videos/waiting_neon.mp4`}
            type="video/mp4"
          ></source>
        </video>
        <video
          className="botVideoAction"
          autoplay
          muted
          id="answer_neon"
          onEnded={() => {
            handleVideoEnded("answer_neon");
          }}
        >
          <source
            src={`https://s3.sa-east-1.amazonaws.com/qyon.panel/videos/answer_neon.mp4`}
            type="video/mp4"
          ></source>
        </video>
        <video
          className="botVideoAction"
          autoplay
          muted
          id="cnd_neon"
          onEnded={() => {
            handleVideoEnded("cnd_neon");
          }}
        >
          <source
            src={`https://s3.sa-east-1.amazonaws.com/qyon.panel/videos/cnd_neon.mp4`}
            type="video/mp4"
          ></source>
        </video>
        <video
          className="botVideoAction"
          autoplay
          muted
          id="download_xml_neon"
          onEnded={() => {
            handleVideoEnded("download_xml_neon");
          }}
        >
          <source
            src={`https://s3.sa-east-1.amazonaws.com/qyon.panel/videos/download_xml_neon.mp4`}
            type="video/mp4"
          ></source>
        </video>
        <video
          className="botVideoAction"
          autoplay
          muted
          id="esocial_neon"
          onEnded={() => {
            handleVideoEnded("esocial_neon");
          }}
        >
          <source
            src={`https://s3.sa-east-1.amazonaws.com/qyon.panel/videos/esocial_neon.mp4`}
            type="video/mp4"
          ></source>
        </video>
        <video
          className="botVideoAction"
          autoplay
          muted
          id="government_neon"
          onEnded={() => {
            handleVideoEnded("government_neon");
          }}
        >
          <source
            src={`https://s3.sa-east-1.amazonaws.com/qyon.panel/videos/government_neon.mp4`}
            type="video/mp4"
          ></source>
        </video>
        <video
          className="botVideoAction"
          autoplay
          muted
          id="internet_neon"
          onEnded={() => {
            handleVideoEnded("internet_neon");
          }}
        >
          <source
            src={`https://s3.sa-east-1.amazonaws.com/qyon.panel/videos/internet_neon.mp4`}
            type="video/mp4"
          ></source>
        </video>
        <video
          className="botVideoAction"
          autoplay
          muted
          id="listening_neon"
          onEnded={() => {
            handleVideoEnded("listening_neon");
          }}
        >
          <source
            src={`https://s3.sa-east-1.amazonaws.com/qyon.panel/videos/listening_neon.mp4`}
            type="video/mp4"
          ></source>
        </video>
        <video
          className="botVideoAction"
          autoplay
          muted
          id="logo_upload_neon"
          onEnded={() => {
            handleVideoEnded("logo_upload_neon");
          }}
        >
          <source
            src={`https://s3.sa-east-1.amazonaws.com/qyon.panel/videos/logo_upload_neon.mp4`}
            type="video/mp4"
          ></source>
        </video>
        <video
          className="botVideoAction"
          autoplay
          muted
          id="processing_neon"
          onEnded={() => {
            handleVideoEnded("processing_neon");
          }}
        >
          <source
            src={`https://s3.sa-east-1.amazonaws.com/qyon.panel/videos/processing_neon.mp4`}
            type="video/mp4"
          ></source>
        </video>
        <video
          className="botVideoAction"
          autoplay
          onClick={() => {
            window.open("https://qyon.com/aerosports/", "_blank");
          }}
          id="aerosports"
          onEnded={() => {
            handleVideoEnded("aerosports");
          }}
        >
          <source
            src={`https://s3.sa-east-1.amazonaws.com/qyon.panel/videos/aerosports.mp4`}
            type="video/mp4"
          ></source>
        </video>
        <video
          className="botVideoAction"
          autoplay
          onClick={() => {
            window.open("http://qyon.com/universidade-qyon/", "_blank");
          }}
          id="ead"
          onEnded={() => {
            handleVideoEnded("ead");
          }}
        >
          <source
            src={`https://s3.sa-east-1.amazonaws.com/qyon.panel/videos/ead.mp4`}
            type="video/mp4"
          ></source>
        </video>
        <video
          className="botVideoAction"
          autoplay
          onClick={() => {
            window.open("http://videos.qyon.com/", "_blank");
          }}
          id="metaverse"
          onEnded={() => {
            handleVideoEnded("metaverse");
          }}
        >
          <source
            src={`https://s3.sa-east-1.amazonaws.com/qyon.panel/videos/metaverse.mp4`}
            type="video/mp4"
          ></source>
        </video>
        <video
          className="botVideoAction"
          autoplay
          id="bill_will_expire"
          onEnded={() => {
            handleVideoEnded("bill_will_expire");
          }}
        >
          <source
            src={`https://s3.sa-east-1.amazonaws.com/qyon.panel/videos/bill_will_expire.mp4`}
            type="video/mp4"
          ></source>
        </video>
        <video
          className="botVideoAction"
          autoplay
          id="bill_expired"
          onEnded={() => {
            handleVideoEnded("bill_expired");
          }}
        >
          <source
            src={`https://s3.sa-east-1.amazonaws.com/qyon.panel/videos/bill_expired.mp4`}
            type="video/mp4"
          ></source>
        </video>
        <video
          className="botVideoAction"
          autoplay
          id="bot_presentation"
          onEnded={() => {
            handleVideoEnded("bot_presentation");
          }}
        >
          <source
            src={`https://s3.sa-east-1.amazonaws.com/qyon.panel/videos/bot_presentation.mp4`}
            type="video/mp4"
          ></source>
        </video>
      </div>

      <BotActions
        hasBatteryMessage={botContext.messageBattery !== ""}
        hasBoletoAlert={props.notificationOpen}
      >
        <Grid container direction="row" justifyContent="center" spacing={1}>
          <Grid item>
            <Tooltip title={"Apresentação do Qyon Bot"}>
              <IconButton
                size="small"
                onClick={() => {
                  if (botContext.isBotPresentationRunning === null) {
                    botContext.setBotPresentationRunning(true);
                    ref.current.bot_presentation();
                  } else {
                    botContext.setBotPresentationRunning(null);

                    ref.current.stop_bot_presentation();
                  }
                }}
              >
                {/* 
                //todo add stop icon
              */}
                {botContext.isBotPresentationRunning === null && (
                  <BotActionIcons icon="play" color="#0839a9" />
                )}
                {botContext.isBotPresentationRunning !== null && (
                  <BotActionIcons icon="stop" color="#0839a9" />
                )}
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title={"Treinar QYON Bot"}>
              <IconButton
                size="small"
                onClick={() => {
                  botContext.setOpenBotTrainingConfiguration(true);
                }}
              >
                <BotActionIcons icon="chip" color="#0839a9"></BotActionIcons>
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title={"Upload de arquivos"}>
              <IconButton
                size="small"
                onClick={() => {
                  botContext.setShowDialogUploadDocument(true);
                }}
              >
                <BotActionIcons icon="upload" color="#0839a9"></BotActionIcons>
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </BotActions>
    </BotContainer>
  );
});

export default Bot;
