import axios from "axios";
import { notify } from "../utils/notify";

const newPath = `${process.env.REACT_APP_NEW_PAINEL_API_URL}`;
const BOT_API = process.env.REACT_APP_BOT_API_URL;
export const getDocumentData = async (base64) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "c799c146-6264-4bc6-94d7-56b4cac8deb0",
  };

  let response = await axios.post(
    BOT_API + "/v1/bot/scan/document",
    {
      base64,
    },
    {
      headers: headers,
    }
  );
  return response.status === 200 ? response.data : null;
};

export async function getDocumentsById({
  scheduled_id,
  companyId,
  service,
  confirmed,
  systemId,
}) {
  return axios
    .get(`${newPath}/v1/document_client/get-documents-by-id/${companyId}`, {
      params: {
        comm_type: service,
        confirmed,
        scheduled_id,
      },
      headers: {
        Authorization: "Bearer " + localStorage.getItem("tokenPanel"),
        "Content-Type": "application/json",
        "Content-Language": "pt-BR",
      },
    })
    .then((response) => {
      return response.data.data;
    })
    .catch((err) => {
      notify("Ocorreu um erro ao buscar o email do cliente!");
      return null;
    });
}

export async function postDocuments(body) {
  return axios
    .post(`${newPath}/v1/document_client/post-documents-list`, body, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("tokenPanel"),
        "Content-Type": "application/json",
        "Content-Language": "pt-BR",
      },
    })
    .then((response) => {
      return response.data.data;
    })
    .catch((err) => {
      notify("Ocorreu um erro ao cadastrar os documentos!");
      return null;
    });
}

export async function postDocument(body) {
  return await axios
    .post(`${newPath}/v1/document_client/post-document`, body, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("tokenPanel"),
        "Content-Type": "application/json",
        "Content-Language": "pt-BR",
      },
    })
    .then((response) => {
      if (body["comm_type"] === "Email") {
        notify("Email Adicionado com Sucesso!", true, "success");
        return response;
      } else {
        notify("Sms Adicionado com Sucesso!", true, "success");
        return response;
      }
    })
    .catch((err) => {
      return err;
    });
}

export async function deleteDocument(document_id) {
  return axios
    .delete(`${newPath}/v1/document_client/delete-document/${document_id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("tokenPanel"),
        "Content-Type": "application/json",
        "Content-Language": "pt-BR",
      },
    })
    .then((response) => {
      notify("Documento deletado com sucesso!", true, "success");
    })
    .catch((err) => {
      notify("Ocorreu um erro ao deletar o documento!");
      return null;
    });
}

export async function sendVerifyEmail(email, body) {
  return await axios
    .post(`${newPath}/v1/document_client/send-verify-email/${email}`, body, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("tokenPanel"),
        "Content-Type": "application/json",
        "Content-Language": "pt-BR",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return null;
    });
}
