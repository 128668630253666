import { Button, Dialog, Tab, Tabs, Grid, Chip } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";

import styled, { css } from "styled-components";

const blue = (props) => props.theme.palette.blue;
const background = (props) => "white";

export const Container = styled.div`
  border-radius: 16px;
  border: 1px solid #e0e0e0;
  width: calc(100% - 20px);
  position: relative;
  top: 25px;
  display: flex;
  flex-direction: column;
  left: -25px;
  margin-left: 20px;
  margin-right: 20px;
`;

export const DataGridGuideReport = styled(DataGrid)`
  border: none !important;
  ${(props) =>
    props.maxHeight &&
    css`
      max-height: ${props.maxHeight}!important;
    `}
`;

export const TitleTable = styled.label`
  font-size: 20px;
  color: ${blue};
  font-weight: 600;
  ${(props) => (props.alignSelf ? `align-self: ${props.alignSelf};` : ``)}
  ${(props) => (props.margin ? `margin: ${props.margin};` : ``)}
`;

export const DialogSendEmail = styled(Dialog)`
  .MuiPaper-root {
    min-width: 600px !important;
  }
`;

export const ContainerTab = styled.div`
  width: 100%;

  .MuiPaper-elevation4 {
    box-shadow: none !important;
  }

  .MuiAppBar-root {
    background-color: ${background};
  }

  .MuiTabs-indicator {
    height: 5px;
    border-radius: 5px;
    background-color: ${blue} !important;
  }
`;

export const ButtonsDocument = styled(Button)`
  background-color: #f0f1f3;
  color: #424242;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 20px;
  margin-bottom: 10px;
  border-radius: 50px;
  text-transform: none;
  width: 100px;
  &:hover {
    background-color: #e7eefe;
    color: #0036b3;
  }
`;

export const TabsNew = styled(Tabs)`
  background-color: ${blue};
  height: 100%;
  margin-top: 10px;
  & .MuiTabs-indicator {
    display: none;
  }
`;

export const TabsStyled = styled(Tab)`
  background-color: #f0f1f3;
  color: #424242;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 20px;
  border-radius: 50px;
  text-transform: none;
  &:hover {
    background-color: #e7eefe;
    color: #0e42b8;
  }
  &.Mui-selected {
    background-color: #0036b3;
    color: #fff;
  }
  &.MuiTab-root {
    min-height: inherit;
  }
`;

export const TemplateTypeChips = styled.p((props) => ({
  display: "flex",
  padding: "8px 24px",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "14px",
  // gap: "8px",
  borderRadius: "24px",
  background: "#F0F1F3",
  cursor: "pointer",

  ...(props?.active && {
    display: "flex",
    padding: "8px 24px",
    justifyContent: "center",
    alignItems: "center",
    // gap: "8px",

    borderRadius: "24px",
    background: "#0036b3",
    color: "#fbfbfd",
    cursor: "pointer",
    fontFamily: "Roboto",
  }),

  "&:hover": {
    display: "flex",
    padding: "8px 24px",
    justifyContent: "center",
    alignItems: "center",
    // gap: "8px",
    borderRadius: "24px",
    background: "#0036b3",
    color: "#fbfbfd ",
    cursor: "pointer",
    fontFamily: "Roboto",
  },
}));

export const SearchFilter = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
`;

export const OutsideText = styled.div({
  color: "#364461",
  fontFamily: "Roboto",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "22px",
  display: "flex",
  gap: "10px",
});

export const CndStatusChip = styled(Chip)`
  ${(props) => {
    switch (props?.type) {
      case "N":
        return css`
          background: #87cb92;
          color: #fff;
        `;
      case "P":
        return css`
          background: #f8531e;
          color: #fff;
        `;
      case "I":
        return css`
          background: #9143f5;
          color: #fff;
        `;
      case "PN":
        return css`
          background: #ceba52;
          color: #fff;
        `;
      case "E":
        return css`
          background: #000000;
          color: #fff;
        `;
      case "NSA":
        return css`
          background: #03226a;
          color: #fff;
        `;
      default:
        return css``;
    }
  }}
`;
