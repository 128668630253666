import {
  Checkbox,
  CircularProgress,
  DialogActions,
  DialogContent,
  Grid,
  makeStyles,
  Modal,
  TextField,
} from "@material-ui/core";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  ButtonCancel,
  ButtonSave,
  CloseIconStyle,
  DialogHeader,
  DialogStyle,
} from "../../../components/modal/Modal";
import SelectErp from "../../../components/basics/SelectErp";
import { notify } from "../../../utils/notify";
import SubdirectoryArrowLeftIcon from "@material-ui/icons/SubdirectoryArrowLeft";
import { validEmail } from "../../../utils/validations";
import { RecursivePermissionList } from "../../userAccess/components/RecursivePermissionList";
import { ManagementContext } from "../../accessProfile/contexts/ManagementContext";
import { getUserPermission } from "../../../repositories/panel/v1/EntityRepository";
import { CustumerManagementContext } from "../contexts/CustomerManagementContext";
import { GlobalContext } from "../../../contexts/GlobalContext";
import { DialogAddUserUserInfo } from "./DialogAddUserUserInfo";
import {
  createEntityUser,
  updateEntityUser,
} from "../../../repositories/portal_api/EntityUser";

export const DialogAddUser = ({
  open,
  firstUser = false,
  user,
  users = [],
  setUsers,
  handleClose,
  handleSave,
  permissionGroupsList = [],
  permissionsTree = [],
  setLogout,
  location,
  ...props
}) => {
  const [selectedPermissionGroup, setSelectedPermissionGroup] = useState(null);

  const [fullNameError, setFullNameError] = useState(false);
  const [permissionDescription, setPermissionDescription] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [permissionGroupError, setPermissionGroupError] = useState(false);
  const formatedCellphone = useRef();
  const [loading, setLoading] = useState(false);
  const [mainUser, setMainUser] = useState(false);
  const [formatedPermissionList, setFormatedPermisionList] = useState([]);
  const update = useRef(true);

  const { permissionsList, setPermissionsList, customized, setCustomized } =
    useContext(ManagementContext);

  const {
    documentCustomer,
    userFullName,
    setUserFullName,
    userEmail,
    setUserEmail,
    userCellphone,
    setUserCellphone,
  } = useContext(CustumerManagementContext);
  const { companyUser, userLogged } = useContext(GlobalContext);

  const setEntityDocument = () => {
    if (location?.pathname === "/customermanagement/edit") {
      return documentCustomer;
    } else {
      return companyUser?.document;
    }
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      "& .MuiTextField-root": {
        background: "red",
        alignself: "stretch",
        color: "#364461",
        fontFamily: "Roboto",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "14px",
      },
    },
  }));

  const classes = useStyles();

  const validateFields = () => {
    if (!userFullName) {
      notify("Nome é obrigatório");
      setFullNameError(true);
      return false;
    }

    if (!userEmail) {
      notify("Email é obrigatório");
      setEmailError(true);
      return false;
    }

    //validate email
    if (!validEmail(userEmail)) {
      notify("Email inválido");
      setEmailError(true);
      return false;
    }

    if (!firstUser && !selectedPermissionGroup) {
      setPermissionGroupError(true);
      notify("Grupo de Permissões é obrigatório");
      return false;
    }

    return true;
  };

  useEffect(() => {
    let description = {};

    if (selectedPermissionGroup?.name === "Customizado") {
      description = selectedPermissionGroup;
    } else {
      description = permissionGroupsList?.find(
        (permission) => permission?.id === selectedPermissionGroup?.id
      );
    }

    if (selectedPermissionGroup?.name === "Administrador Principal") {
      setMainUser(true);
    }

    setPermissionDescription(description?.description || "");
    if (
      (description?.name !== "Padrão" && description?.name !== "Default") ||
      description?.name !== "Customizado"
    ) {
      setPermissionsList(description?.permissions);
    } else {
      setPermissionsList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPermissionGroup]);

  const findDefaultPermission = () => {
    const defaultPermission = permissionGroupsList.find(
      (item) => item.name === "Padrão" || item.name === "Default"
    );
    return defaultPermission;
  };

  const findMasterPermission = () => {
    const masterPermission = permissionGroupsList.find(
      (item) => item.name === "Master"
    );
    return masterPermission;
  };

  useEffect(() => {
    if (customized) {
      setSelectedPermissionGroup({
        id: 0,
        name: "Customizado",
        permissions: permissionsList,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customized]);

  const formatPermissionList = () => {
    let permissionGroupsListCopy = [...permissionGroupsList];

    if (userLogged.userId === companyUser.masterUserId) {
      const masterPermission = findMasterPermission();

      const main_master = {
        created_at: null,
        default: false,
        description: null,
        id: 0,
        master: true,
        name: "Administrador Principal",
        owner_entity_id: 0,
        permissions: masterPermission?.permissions,
        updated_at: null,
      };

      permissionGroupsListCopy.push(main_master);
      setSelectedPermissionGroup(main_master);
    }
    setFormatedPermisionList(permissionGroupsListCopy);
  };

  useEffect(() => {
    initialSetup();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id, open]);

  useEffect(() => {
    formatedCellphone.current = userCellphone?.replace(/[-()/ ]/g, "");
  }, [userCellphone]);

  const initialSetup = async () => {
    if (open) {
      formatPermissionList();
      setLoading(true);
      setCustomized(false);
      setMainUser(false);

      if (user?.id) {
        if (
          user.customized &&
          (user.permission_group_name === "Padrão" ||
            user.permission_group_name === "Default" ||
            user.permission_group_name === "Customizado")
        ) {
          setCustomized(true);
        }

        setUserFullName(user?.first_name?.concat(" " + user?.last_name));
        setUserEmail(user?.email);
        setUserCellphone(user?.cellphone);
        setSelectedPermissionGroup({
          id: user?.permission_group_id,
          name: user?.permission_group_name,
        });
        setMainUser(user?.is_master);

        const responseUserPermissions = await getUserPermission({
          document: setEntityDocument(),
          userId: user?.id,
          withDetails: false,
        });

        setPermissionsList(responseUserPermissions.data);
      } else {
        setUserFullName("");
        setUserEmail("");
        setUserCellphone("");
        setMainUser(false);
        setSelectedPermissionGroup(null);
      }

      setLoading(false);
    }
  };

  const changeMasterUser = () => {
    let usersCopy = [...users];
    if (mainUser) {
      usersCopy.forEach((user) => (user.is_master = false));
      setUsers(usersCopy);
      update.current = true;
    } else {
      const currentUser = usersCopy.find((userCopy) => userCopy.id === user.id);
      if (currentUser?.is_master === true) {
        update.current = false;
      }
    }
  };

  const getUserObject = () => {
    const [first, ...last] = userFullName.split(" ");

    const masterPermission = findMasterPermission();

    const object = {
      id: user?.id,
      first_name: first,
      last_name: last.join(" "),
      cellphone:
        userCellphone?.replace(/\D/g, "")?.length === 13
          ? "+" + userCellphone.replace(/\D/g, "")
          : null,
      permission_group_id:
        selectedPermissionGroup?.name === "Administrador Principal"
          ? masterPermission?.id
          : selectedPermissionGroup?.id,
      permission_group_name: selectedPermissionGroup?.name,
      is_master: mainUser,
      customized: customized,
      email: userEmail,
    };

    if (customized) {
      const defaultPermission = findDefaultPermission();
      object.permission_group_id = defaultPermission.id;
      object.permissions = permissionsList.filter((item) => item !== "NULL");
    }

    return object;
  };

  const handleSaveUsers = async () => {
    if (validateFields()) {
      changeMasterUser();
      if (update.current) {
        const userObject = getUserObject();

        if (location?.pathname === "/customermanagement/edit" && firstUser) {
          handleSave(userObject);
          setMainUser(false);
          handleClose();
        } else {
          if (userObject?.id === undefined) {
            setLoading(true);
            const createResponse = await createEntityUser(
              location?.state?.customerId,
              userObject
            );

            if (createResponse.success) {
              setLoading(false);
              handleClose();
              props.initialSetup();
            }
          } else {
            setLoading(true);
            const createResponse = await updateEntityUser(
              location?.state?.customerId,
              userObject
            );

            if (createResponse.success) {
              setLoading(false);
              handleClose();
              props.initialSetup();
            }
          }

          if (
            mainUser &&
            location.pathname === "/useraccess/management" &&
            selectedPermissionGroup?.name === "Administrador Principal"
          ) {
            setLogout(true);
          }
        }
      } else {
        notify("É necessário ter um Usuário Principal!", true, "warning");
      }
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogStyle style={{ width: "950px" }}>
          <DialogHeader id="alert-dialog-title">
            <div className="row">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: 20,
                }}
              >
                {user?.id ? "Editar" : "Cadastro"} Usuário
              </div>
              <CloseIconStyle
                style={{ marginRight: "6px" }}
                onClick={() => {
                  handleClose();
                }}
              />
            </div>
          </DialogHeader>
          <DialogContent style={{ maxHeight: "70vh" }}>
            {loading ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </div>
            ) : (
              <Grid container spacing={1}>
                {firstUser && (
                  <Grid item xs={12}>
                    Para confirmar o cadastro da empresa como cliente, insira os
                    dados do <b>usuário master</b> para acesso ao{" "}
                    <b>Portal do Cliente</b>
                  </Grid>
                )}

                <DialogAddUserUserInfo
                  user={user}
                  emailError={emailError}
                  setEmailError={setEmailError}
                  fullNameError={fullNameError}
                  setFullNameError={setFullNameError}
                  location={location}
                  mainUser={mainUser}
                />

                {!firstUser && (
                  <Grid container item xs={12}>
                    <Grid item xs={12}>
                      <div>Grupo de Permissão*</div>
                    </Grid>
                    <Grid item xs={10}>
                      <SelectErp
                        variant="outlined"
                        placeholder="Grupo de permissões"
                        onFocus={() => {
                          setPermissionGroupError(false);
                        }}
                        value={selectedPermissionGroup}
                        error={permissionGroupError}
                        setValue={setSelectedPermissionGroup}
                        optionsList={formatedPermissionList}
                        descriptionField="name"
                        disabled={
                          customized ||
                          mainUser ||
                          userLogged.userId === user?.id?.toString()
                        }
                        getOptionLabel={(userPermission) =>
                          userPermission.name === "Master"
                            ? "Administrador"
                            : userPermission.name === "Padrão" ||
                              userPermission.name === "Default"
                            ? "Acesso Básico"
                            : userPermission.name
                        }
                      />
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      direction="row"
                      style={{ display: "flex", alignContent: "center" }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Checkbox
                          onChange={(event) =>
                            setCustomized(event.target.checked)
                          }
                          color="primary"
                          checked={customized}
                          disabled={
                            mainUser ||
                            userLogged.userId === user?.id?.toString()
                          }
                        ></Checkbox>
                        <p>Customizado</p>
                      </div>
                    </Grid>
                  </Grid>
                )}

                {!firstUser && (
                  <>
                    <Grid item xs={12}>
                      <TextField
                        className={classes.root}
                        variant="outlined"
                        size="small"
                        fullWidth
                        value={permissionDescription}
                        multiline
                        minRows={5}
                        style={{
                          display: "flex",
                        }}
                        disabled
                        InputProps={{
                          startAdornment: (
                            <p
                              style={{
                                display: "flex",
                                position: "absolute",
                                left: 14,
                                top: 14,
                                color: "#364461",
                                fontFamily: "Roboto",
                                fontSize: "12px",
                                fontStyle: "normal",
                                fontWeight: "300",
                                lineHeight: "10px",
                              }}
                            >
                              Descrição da Permissão
                            </p>
                          ),
                          style: {
                            paddingTop: "32px",
                            alignself: "stretch",
                            fontFamily: "Roboto",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "14px",
                          },
                        }}
                      />
                    </Grid>

                    <RecursivePermissionList
                      permissionsTree={permissionsTree}
                      expecifyUser={user}
                      groupPermissionsList={permissionGroupsList}
                    />
                  </>
                )}

                {mainUser &&
                  !user.is_master &&
                  location?.pathname !== "/customermanagement/edit" && (
                    <>
                      <p style={{ color: "red" }}>
                        Você será desconectado após a troca do usuário master
                        principal, e passara os privelégios para este usuário!
                      </p>
                    </>
                  )}
              </Grid>
            )}
          </DialogContent>
          <DialogActions style={{ paddingTop: 24, paddingBottom: 24 }}>
            <ButtonCancel
              style={{
                marginRight: 24,
                float: "right",
              }}
              onClick={handleClose}
              startIcon={<SubdirectoryArrowLeftIcon />}
            >
              {"Cancelar"}
            </ButtonCancel>
            <ButtonSave
              style={{
                float: "right",
                right: 14,
              }}
              onClick={() => {
                if (validateFields()) {
                  handleSaveUsers();
                }
              }}
            >
              {"Enviar"}
            </ButtonSave>
          </DialogActions>
        </DialogStyle>
      </Modal>
    </>
  );
};
