import React from "react";
import { Helmet } from "react-helmet";
import { ItemGridDragDropTitleStyle } from "../../components/grid-drag-drop/ItemGridDragDropTitleStyle";
import { Grid, IconButton } from "@material-ui/core";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import { LightTooltip, Margin } from "../../styles/GlobalStyle";
import CachedOutlinedIcon from "@material-ui/icons/CachedOutlined";
import CloseIcon from "@material-ui/icons/Close";
import { DividerStyled } from "../../components/chat/styles/GeneralStyle";
import useDashboardViewController from "../../pages/dashboard/controllers/DashboardViewController";

export const WidgetVersion92 = () => {
  const { handleDeleteWidgets } = useDashboardViewController();

  const item = {
    i: "92",
    importScripts: ["https://app2.weatherwidget.org/js/?id=ww_ef72f3d7b4d4d"],
    stringScripts: [""],
    onRefresh:
      "try {var url = 'https://api-microservice.qyon.info/v1/fiscal-intelligence/dashboard/nfeEntradas?cnpj=' + localStorage.getItem('documentPanel');var requestOptions = {method: 'GET',headers: { 'Authorization': '0893E99C-4AE4-4A83-89E5-1EED31171094' },redirect: 'follow'};fetch(url, requestOptions).then(response => response.text()).then(result => printResult(result)).catch(error => console.log('error', error));function printResult(result) {let obj = JSON.parse(result);obj.list.forEach(item => {let element = document.getElementById(item.id);if (element) element.innerHTML = item.value;});}} catch (e) {console.log(e)}",
    refreshOnLoad: true,
  };

  return (
    <>
      {item.importScripts.map((script, index) => {
        return (
          <div key={`import_${index}`}>
            <Helmet>
              <script async src={script}></script>
            </Helmet>
          </div>
        );
      })}

      <ItemGridDragDropTitleStyle customStyle={item.headerStyle ?? {}}>
        <Grid item container justifyContent="space-between" alignItems="center">
          <Grid item flex={1}>
            <IconButton size="small">
              <DragIndicatorIcon style={{ fontSize: 18, marginRight: 5 }} />
            </IconButton>
            NF-e Entradas
          </Grid>
          <Grid item flex={1}>
            <Grid item container alignItems="center">
              <Margin top="7" />
              {item.onRefresh &&
                (() => {
                  if (item?.refreshOnLoad) {
                    setTimeout(() => {
                      try {
                        // eslint-disable-next-line no-eval
                        eval("try{" + item.onRefresh + "}catch(err){}");
                      } catch (error) {
                        console.error(error);
                      }
                    }, 1500);
                  }

                  return (
                    <Grid item flex={1}>
                      <LightTooltip title="Atualizar">
                        <CachedOutlinedIcon
                          style={{
                            fontSize: 18,
                            marginRight: 10,
                            cursor: "pointer",
                            color: "#636363",
                          }}
                          onClick={() => {
                            // Muito cuidado, o eval é perigoso - https://developer.mozilla.org/pt-BR/docs/Web/JavaScript/Reference/Global_Objects/eval
                            try {
                              // eslint-disable-next-line no-eval
                              eval("try{" + item.onRefresh + "}catch(err){}");
                            } catch (error) {
                              console.error(error);
                            }
                          }}
                        />
                      </LightTooltip>
                    </Grid>
                  );
                })()}
              <Grid item flex={1}>
                <LightTooltip title="Remover">
                  <CloseIcon
                    style={{
                      fontSize: 18,
                      cursor: "pointer",
                      color: "#636363",
                      marginRight: 10,
                    }}
                    onClick={() => handleDeleteWidgets(item)}
                  />
                </LightTooltip>
              </Grid>
            </Grid>
          </Grid>
          <DividerStyled style={{ width: "100%" }} />
        </Grid>
      </ItemGridDragDropTitleStyle>

      <div
        style={{
          height: `${"calc(100% - 65px)"}`,
          width: "100%",
        }}
      >
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin="true"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto&display=swap"
          rel="stylesheet"
        />
        <body
          style={{
            fontFamily: "'Roboto', sans-serif",
            fontSize: "14px",
            backgroundColor: "white",
          }}
        >
          <p
            style={{
              fontSize: "15px",
              fontWeight: "bold",
              paddingBottom: "0",
              marginTop: "0",
            }}
          >
            Entradas
          </p>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                width: "4px",
                height: "73px",
                background: "#0A73DB 0% 0% no-repeat padding-box",
                borderRadius: "2px",
              }}
            ></div>
            <div style={{ width: "100%", paddingLeft: "10px" }}>
              <div style={{ display: "flex", paddingBottom: "2px" }}>
                <span style={{ width: "100%", color: "#7B7B7B" }}>Hoje</span>
                <span id="NFeEntradasHoje" style={{ fontWeight: "bold" }}>
                  0
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  padding: "2px 0",
                  borderBottom: "#E5E5E5 solid 1px",
                  borderTop: "#E5E5E5 solid 1px",
                }}
              >
                <span style={{ width: "100%", color: "#7B7B7B" }}>
                  Mês Atual
                </span>
                <span id="NFeEntradasMesAtual" style={{ fontWeight: "bold" }}>
                  0
                </span>
              </div>
              <div style={{ display: "flex", paddingTop: "2px" }}>
                <span style={{ width: "100%", color: "#7B7B7B" }}>
                  Mês Anterior
                </span>
                <span
                  id="NFeEntradasMesAnterior"
                  style={{ fontWeight: "bold" }}
                >
                  0
                </span>
              </div>
            </div>
          </div>
          <p
            style={{ fontSize: "15px", fontWeight: "bold", paddingBottom: "0" }}
          >
            Canceladas
          </p>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                width: "4px",
                height: "73px",
                background: "#FA3A3A 0% 0% no-repeat padding-box",
                borderRadius: "2px",
              }}
            ></div>
            <div style={{ width: "100%", paddingLeft: "10px" }}>
              <div style={{ display: "flex", paddingBottom: "2px" }}>
                <span style={{ width: "100%", color: "#7B7B7B" }}>Hoje</span>
                <span id="NFeCanceladassHoje" style={{ fontWeight: "bold" }}>
                  0
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  padding: "2px 0",
                  borderBottom: "#E5E5E5 solid 1px",
                  borderTop: "#E5E5E5 solid 1px",
                }}
              >
                <span style={{ width: "100%", color: "#7B7B7B" }}>
                  Mês Atual
                </span>
                <span id="NFeCanceladasMesAtual" style={{ fontWeight: "bold" }}>
                  0
                </span>
              </div>
              <div style={{ display: "flex", paddingTop: "2px" }}>
                <span style={{ width: "100%", color: "#7B7B7B" }}>
                  Mês Anterior
                </span>
                <span
                  id="NFeCanceladasMesAnterior"
                  style={{ fontWeight: "bold" }}
                >
                  0
                </span>
              </div>
            </div>
          </div>
          <p
            style={{ fontSize: "15px", fontWeight: "bold", paddingBottom: "0" }}
          >
            Eventos
          </p>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                width: "4px",
                height: "73px",
                background: "#FFB100 0% 0% no-repeat padding-box",
                borderRadius: "2px",
              }}
            ></div>
            <div style={{ width: "100%", paddingLeft: "10px" }}>
              <div style={{ display: "flex", paddingBottom: "2px" }}>
                <span style={{ width: "100%", color: "#7B7B7B" }}>Hoje</span>
                <span id="NFeEventosHoje" style={{ fontWeight: "bold" }}>
                  0
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  padding: "2px 0",
                  borderBottom: "#E5E5E5 solid 1px",
                  borderTop: "#E5E5E5 solid 1px",
                }}
              >
                <span style={{ width: "100%", color: "#7B7B7B" }}>
                  Mês Atual
                </span>
                <span id="NFeEventosMesAtual" style={{ fontWeight: "bold" }}>
                  0
                </span>
              </div>
              <div style={{ display: "flex", paddingTop: "2px" }}>
                <span style={{ width: "100%", color: "#7B7B7B" }}>
                  Mês Anterior
                </span>
                <span id="NFeEventosMesAnterior" style={{ fontWeight: "bold" }}>
                  0
                </span>
              </div>
            </div>
          </div>
        </body>
      </div>

      {item.stringScripts &&
        item.stringScripts.map((script, index) => {
          return (
            <div key={`script_${index}`}>
              <Helmet>
                <script async>{script}</script>
              </Helmet>
            </div>
          );
        })}
    </>
  );
};
