import { Grid } from "@material-ui/core";
import React, { useState } from "react";
import { DescriptionVideo, TitleVideo } from "../styles/KnowledgeBase";
import moment from "moment";

import ContentModal from "./ContentModal";
import VideoKnowledgeBaseIcon from "../../../components/icons/VideoKnowledgeBaseIcon";
import PdfKnowledgeBaseIcon from "../../../components/icons/PdfKnowledgeBaseIcon";

const ContentComponent = ({
  thumbnail,
  title,
  description,
  url,
  dateEntered,
  duration,
  typeFile,
  listRelatedContent,
  listContent,
  size,
  handleOpenRelatedContent = () => {},
  handleCloseRelatedContent = () => {},
  ...props
}) => {
  const [openModal, setOpenModal] = useState(false);

  let formattedDescription = "";

  if (description.length > 80) {
    formattedDescription = description.substring(0, 80) + "...";
  } else {
    formattedDescription = description;
  }

  let formattedTitle = "";

  if (title.length > 60) {
    formattedTitle = title.substring(0, 60) + "...";
  } else {
    formattedTitle = title;
  }

  const formatDate = (date) => {
    const originalDate = date;
    const dates = moment(originalDate);
    const formatedDate = dates.format("DD/MM/YYYY");
    return formatedDate;
  };

  let formattedDate = "";
  if (dateEntered) {
    formattedDate = formatDate(dateEntered);
  } else {
    formattedDate = "N/A";
  }

  const DefaultFileIconComponent = ({ typeFile, ...props }) => {
    if (typeFile === "mp4") {
      return <VideoKnowledgeBaseIcon width="75" height="75" />;
    } else if (typeFile === "pdf") {
      return <PdfKnowledgeBaseIcon width="75" height="75" />;
    } else {
      return <></>;
    }
  };

  return (
    <Grid item xs={size ? size : 6}>
      <div
        style={{
          display: "flex",
          gap: 8,
          background: "#fff",
          borderRadius: 12,
          padding: 8,
          boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.08)",
          cursor: "pointer",
        }}
        onClick={() => {
          handleOpenRelatedContent();
          setOpenModal(true);
        }}
      >
        {thumbnail ? (
          <img
            src={thumbnail}
            alt="thumbnail"
            width={170}
            height={96}
            style={{ borderRadius: 8 }}
          />
        ) : (
          <>
            <div
              style={{
                height: 96,
                width: 170,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <DefaultFileIconComponent typeFile={typeFile} />
            </div>
          </>
        )}

        {duration && (
          <div
            id="duration"
            style={{
              right: 8,
              top: 96,
              width: 0,
              height: 0,
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              position: "relative",
            }}
          >
            <div
              style={{
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                color: "#fff",
                padding: 3,
                borderBottomRightRadius: 8,
              }}
            >
              {duration}
            </div>
          </div>
        )}

        <div
          style={{
            display: "flex",
            flexGrow: 1,
            flexDirection: "column",
            justifyContent: "space-between",
            maxHeight: 96,
          }}
        >
          <div
            style={{
              maxHeight: "80%",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <TitleVideo>{formattedTitle}</TitleVideo>
            <DescriptionVideo
              style={{ overflow: "hidden", textOverflow: "ellipsis" }}
            >
              {formattedDescription}
            </DescriptionVideo>
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <DescriptionVideo>{formattedDate}</DescriptionVideo>
          </div>
        </div>
      </div>

      <ContentModal
        open={openModal}
        description={description}
        title={title}
        url={url}
        formattedDate={formattedDate}
        typeFile={typeFile}
        handleClose={() => {
          handleCloseRelatedContent();
          setOpenModal(false);
        }}
        listRelatedContent={listRelatedContent}
        listContent={listContent}
      />
    </Grid>
  );
};

export default ContentComponent;
