import {
  CircularProgress,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@material-ui/core";
import React, { useContext, useEffect, useRef, useState } from "react";
import { GlobalContext } from "../../../contexts/GlobalContext";
import {
  CndStatusChip,
  DataGridGuideReport,
  OutsideText,
  SearchFilter,
} from "../styles/GuideReport";
import { SearchIcon } from "../../../components/icons/SearchIcon";
import { NewBotContext } from "../../../components/newBot/context/NewBotContext";
import StarIcon from "@material-ui/icons/Star";
import { GridStyled, LabelStyled } from "../../../styles/GlobalStyle";
import {
  downloadDocument,
  favoriteDocument,
  getDocumentHistory,
  archiveDocument,
} from "../../../repositories/GuideReportList";
import SendIcon from "../../../components/icons/SendIcon";
import { FoldersIds } from "../utils/FolderTypes";

import {
  formatDateTime,
  formatStatusMessage,
  getDocumentList,
} from "../controller/GuideReportController";
import { DocumentGuideContext } from "../contexts/DocumentGuideContext";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import MaskService from "../../../utils/mask/maskService";
import { Autocomplete } from "@material-ui/lab";
import ArchiveIcon from "../../../components/icons/ArchiveIcon";
import { Add } from "@material-ui/icons";

import { DocumentInboxTypes } from "../utils/DocumentInboxTypes";
import { notify } from "../../../utils/notify";
import UnarchiveIcon from "../../../components/icons/UnarchiveIcon";
import ModalArchive from "./ModalArchive";
import { ptBR } from "@material-ui/data-grid";
import ModalSendDocs from "./ModalSendDocs";

const DocumentGuideReportBody = ({ client, ...props }) => {
  const { companyUser, setSidebarAction } = useContext(GlobalContext);
  const botContext = useContext(NewBotContext);

  const lockLoad = useRef(false);

  const [isLoadingFavoriteDocument, setLoadingFavoriteDocument] = useState({});
  const [isLoadingViewDocument, setIsLoadingViewDocument] = useState({});
  const [isLoadingDownloadDocument, setIsLoadingDownloadDocument] = useState(
    {}
  );
  const [isLoadingArchiveDocument, setLoadingArchiveDocument] = useState({});

  const [selectDocumentInboxStatus, setSelectDocumentInboxStatus] = useState(
    []
  );

  const isInClientView = sessionStorage.getItem("isInClientView") === "true";
  const clientId = JSON.parse(sessionStorage.getItem("client"))?.company;

  const [openModalSendDocs, setOpenModalSendDocs] = useState(false);

  useEffect(() => {
    let newDocumentInboxTypes = [...DocumentInboxTypes];

    if (!companyUser.isAccountant) {
      newDocumentInboxTypes = newDocumentInboxTypes.filter(
        (item) => item.id !== 4
      );
    }

    setSelectDocumentInboxStatus(newDocumentInboxTypes);
  }, [companyUser.isAccountant]);

  const {
    chipValue,
    tabValue,
    searchField,
    setSearchField,
    selectedEndDate,
    selectedStartDate,
    totalDocs,
    documentList,
    page,
    pageSize,
    setClickedRow,
    clickedRow,
    setPdf,

    setPage,
    setPageSize,
    setSelectedEndDate,
    setSelectedStartDate,
    setOpenModalPreviewDoc,
    clientList,
    setSearchClientList,
    searchClientList,
    setDocumentList,
    isLoadingDocuments,
    setIsLoadingDocuments,
    setOpenModalDocumentHistory,
    setLoadingDocumentHistory,
    setDocumentHistoryList,
    currentDocumentInboxStatusId,
    setCurrentDocumentInboxStatusId,
    openModalArchive,
    setOpenModalArchive,
    setTotalDocs,
  } = useContext(DocumentGuideContext);

  const columns = [
    {
      field: "formatedName",
      headerName: "Nome",
      flex: 2,
      renderCell: ({ row }) => {
        return (
          <Tooltip title={row.formatedName} arrow>
            <div
              style={{
                marginLeft: 5,
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              {row.formatedName}
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: "formatedEnteredDate",
      headerName: "Lançamento",
      flex: 2,
      renderCell: ({ row }) => {
        if (row.formatedEnteredDate) {
          return (
            <Tooltip title={row.formatedEnteredDate} arrow>
              <div style={{ marginLeft: 5 }}>{row.formatedEnteredDate}</div>
            </Tooltip>
          );
        }
        return "";
      },
    },
    {
      field: "formatedExpireDate",
      headerName: "Vencimento",
      flex: 2,
      renderCell: ({ row }) => {
        if (row.formatedExpireDate) {
          return (
            <Tooltip title={row.formatedExpireDate} arrow>
              <div style={{ marginLeft: 5 }}>{row.formatedExpireDate}</div>
            </Tooltip>
          );
        }
        return "";
      },
    },

    {
      field: "formatedStatusDocument",
      headerName: "Status",
      hide: tabValue !== FoldersIds.CND,
      flex: 2,
      renderCell: ({ row }) => {
        return (
          <Tooltip title={row.formatedStatusDocument} arrow>
            <CndStatusChip
              size="small"
              label={row.formatedStatusDocument}
              type={row.status_document}
            />
          </Tooltip>
        );
      },
    },

    {
      field: "history_status_message",
      headerName: "Histórico",
      flex: 3,
      renderCell: ({ row }) => {
        if (row.history_status_message) {
          return (
            <div style={{ cursor: "pointer" }}>
              <Tooltip
                arrow
                title={
                  row.formatedStatusMessage +
                  (row.history_status_user
                    ? " por " +
                      row.history_status_user +
                      " em " +
                      row.formatedStatusDate +
                      ". Clique para mais detalhes."
                    : "")
                }
              >
                <GridStyled
                  style={{ cursor: "pointer" }}
                  container
                  justifyContent="center"
                  alignItems="flex-start"
                  height={5}
                  line-height={1.5}
                  onClick={() => {
                    if (row.history_status_user) {
                      handleOpenDocumentHistoryModal(row.document_id);
                    }
                  }}
                >
                  <GridStyled container height={25}>
                    <LabelStyled
                      fontSize={14}
                      style={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        fontWeight: "bold",
                        cursor: "pointer",
                      }}
                    >
                      {row.formatedStatusMessage}, {row.history_status_user}
                    </LabelStyled>
                  </GridStyled>

                  <GridStyled container height={25}>
                    <LabelStyled fontSize={12} style={{ cursor: "pointer" }}>
                      {row.formatedStatusDate}
                    </LabelStyled>
                  </GridStyled>
                </GridStyled>
              </Tooltip>
            </div>
          );
        } else {
          return <div>Não Visualizado</div>;
        }
      },
    },
    {
      field: "actions",
      headerName: "Ações",
      sortable: false,
      editable: false,
      minWidth: 210,
      renderHeader: () => {
        return (
          <Grid container justifyContent="center">
            <div className="MuiDataGrid-columnHeaderTitle">Ações</div>
          </Grid>
        );
      },
      renderCell: ({ row }) => {
        const isLoadingView = isLoadingViewDocument[row.id];
        const isLoadingDownload = isLoadingDownloadDocument[row.id];
        const isLoadingFavorite = isLoadingFavoriteDocument[row.id];

        return (
          <Grid container spacing={1} justifyContent="flex-end">
            {(!companyUser.isAccountant ||
              (companyUser.isAccountant && !client && !searchClientList)) && (
              <Grid item>
                {isLoadingFavorite ? (
                  <CircularProgress size={25} />
                ) : row.favorite ? (
                  <Tooltip title="Favoritar">
                    <IconButton
                      size="small"
                      onClick={() => favoriteDocumentById(row)}
                    >
                      <StarIcon
                        style={{ cursor: "pointer", color: "#0036B3" }}
                      />
                    </IconButton>
                  </Tooltip>
                ) : !row.favorite ? (
                  <Tooltip title="Favoritar">
                    <IconButton
                      size="small"
                      onClick={() => favoriteDocumentById(row)}
                    >
                      <StarIcon
                        style={{
                          cursor: "pointer",
                          color: "white",
                          stroke: "black",
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                ) : null}
              </Grid>
            )}

            {row.suffix === ".zip" ? (
              <Grid item>
                <Tooltip title="Não há pré-visualização para esse tipo de documento.">
                  <IconButton size="small">
                    {isLoadingView ? (
                      <CircularProgress size={25} />
                    ) : (
                      <VisibilityOutlinedIcon
                        style={{ color: "  #ccced1", cursor: "default" }}
                      />
                    )}
                  </IconButton>
                </Tooltip>
              </Grid>
            ) : (
              <Grid item>
                <Tooltip title="Ver Documento">
                  <IconButton
                    size="small"
                    onClick={() => {
                      setClickedRow(row);
                      handlePreviewDocument(row);
                      setOpenModalPreviewDoc(true);
                    }}
                  >
                    {isLoadingView ? (
                      <CircularProgress size={25} />
                    ) : (
                      <VisibilityOutlinedIcon style={{ color: "#616161" }} />
                    )}
                  </IconButton>
                </Tooltip>
              </Grid>
            )}

            <Grid item>
              <Tooltip title="Baixar Documento">
                <IconButton
                  size="small"
                  onClick={() => {
                    handleDownloadDocument(row);
                    setClickedRow(row);
                  }}
                >
                  {isLoadingDownload ? (
                    <CircularProgress size={25} />
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M12.9908 3.005C12.9908 2.45271 12.5431 2.005 11.9908 2.005C11.4386 2.005 10.9908 2.45271 10.9908 3.005V13.5866L8.2021 10.7979C7.81158 10.4074 7.17841 10.4074 6.78789 10.7979C6.39736 11.1884 6.39736 11.8216 6.78789 12.2121L11.2315 16.6557C11.4149 16.8695 11.6871 17.005 11.9908 17.005L11.9929 17.005C12.2495 17.0055 12.5063 16.9079 12.7021 16.7121L17.2021 12.2121C17.5926 11.8216 17.5926 11.1884 17.2021 10.7979C16.8116 10.4074 16.1784 10.4074 15.7879 10.7979L12.9908 13.5949V3.005Z"
                        fill="#616161"
                      />
                      <path
                        d="M20.995 11.005C21.5473 11.005 21.995 11.4527 21.995 12.005V21.005C21.995 21.5573 21.5473 22.005 20.995 22.005H2.995C2.44271 22.005 1.995 21.5573 1.995 21.005V12.0091C1.995 11.4569 2.44271 11.0091 2.995 11.0091C3.54728 11.0091 3.995 11.4569 3.995 12.0091V20.005H19.995V12.005C19.995 11.4527 20.4427 11.005 20.995 11.005Z"
                        fill="#616161"
                      />
                    </svg>
                  )}
                </IconButton>
              </Tooltip>
            </Grid>

            {companyUser.isAccountant && (
              <Grid item>
                <Tooltip title="Arquivar">
                  <IconButton
                    size="small"
                    onClick={() => {
                      setClickedRow(row);
                      setOpenModalArchive(true);
                    }}
                  >
                    {row?.archived ? (
                      <UnarchiveIcon fill="#616161" />
                    ) : (
                      <ArchiveIcon fill="#616161" />
                    )}
                  </IconButton>
                </Tooltip>
              </Grid>
            )}

            <Grid item>
              <Tooltip title="Enviar Documento por Email">
                <IconButton
                  size="small"
                  onClick={() => {
                    setClickedRow(row);
                    setOpenModalSendDocs(true);
                  }}
                >
                  <SendIcon fill="#616161" />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        );
      },
    },
  ];

  const favoriteDocumentById = async (row) => {
    const updatedLoadingStates = {
      ...isLoadingFavoriteDocument,
      [row.id]: true,
    };
    const favorite = { favorite: !row.favorite };
    setLoadingFavoriteDocument(updatedLoadingStates);
    await favoriteDocument(row.document_id, companyUser.companyId, favorite);
    updatedLoadingStates[row.id] = false;
    setLoadingFavoriteDocument(updatedLoadingStates);
    row.favorite = !row.favorite;
  };

  const archiveDocumentById = async (row) => {
    const updatedLoadingStates = {
      ...isLoadingArchiveDocument,
      [row.id]: true,
    };

    setLoadingArchiveDocument(updatedLoadingStates);

    await archiveDocument(row.document_id, !row?.archived);

    updatedLoadingStates[row.id] = false;
    setLoadingArchiveDocument(updatedLoadingStates);

    let newDocumentList = [...documentList];
    newDocumentList = newDocumentList.filter((item) => item.id !== row.id);
    setDocumentList(newDocumentList);

    notify(
      !row?.archived
        ? "Documento arquivado com sucesso!"
        : "Documento desarquivado com sucesso!",
      true,
      "success"
    );
  };

  const handlePreviewDocument = async (row) => {
    const updatedLoadingStates = { ...isLoadingViewDocument, [row.id]: true };
    setIsLoadingViewDocument(updatedLoadingStates);
    await previewDocument(row.document_id, row.formatedName);
    updatedLoadingStates[row.id] = false;
    setIsLoadingViewDocument(updatedLoadingStates);
  };

  const handleDownloadDocument = async (row) => {
    const updatedLoadingStates = {
      ...isLoadingDownloadDocument,
      [row.id]: true,
    };
    setIsLoadingDownloadDocument(updatedLoadingStates);
    await downloadDocumentById(row.document_id, row.formatedName);
    updatedLoadingStates[row.id] = false;
    setIsLoadingDownloadDocument(updatedLoadingStates);
  };

  const downloadDocumentById = async (document_id, document_name) => {
    const response = await downloadDocument(document_id, companyUser.companyId);
    const link = document.createElement("a");
    link.href = response;
    link.download = document_name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    return response;
  };

  const previewDocument = async (document_id) => {
    const response = await downloadDocument(document_id, companyUser.companyId);
    setPdf(response);
  };

  const handleChange = async (event) => {
    setCurrentDocumentInboxStatusId(event.target.value);

    const folderId = getFolderIdOther();

    setIsLoadingDocuments(true);

    let data = await getDocumentList({
      startDate: selectedStartDate,
      endDate: selectedEndDate,
      page: 0,
      entityId: companyUser.companyId,
      search: searchField,
      searchId: searchClientList?.id || isInClientView ? clientId : null,
      pageSize: pageSize,
      folderId: folderId,
      inboxStatusId: event.target.value,
    });

    setIsLoadingDocuments(false);

    setDocumentList(data.documentList);
    setTotalDocs(data.totalDocs);
  };

  const handleOpenDocumentHistoryModal = async (documentId) => {
    setLoadingDocumentHistory(true);

    let historyResponse = await getDocumentHistory(documentId);

    if (historyResponse?.success) {
      let newHistory = historyResponse?.data.map((item) => {
        console.log(item);
        const formattedStatus = formatStatusMessage(item.status);
        const formattedDate = formatDateTime(item.date_status);
        return {
          ...item,
          formatedDate: formattedDate,
          formatedStatusMessage: formattedStatus,
        };
      });

      setDocumentHistoryList(newHistory);
    } else {
      setDocumentHistoryList([]);
    }

    setLoadingDocumentHistory(false);
    setOpenModalDocumentHistory(true);
  };

  const getFolderIdOther = () => {
    const folderId =
      FoldersIds.CND === tabValue
        ? tabValue
        : FoldersIds.Outros === tabValue
        ? tabValue
        : chipValue;

    return folderId;
  };

  const handleStartDateChange = async (event) => {
    setSelectedStartDate(event.target.value);
    const folderId = getFolderIdOther();

    setIsLoadingDocuments(true);

    let data = await getDocumentList({
      startDate: event.target.value,
      endDate: selectedEndDate,
      page: 0,
      folderId: folderId,
      entityId: companyUser.companyId,
      search: searchField,
      searchId: searchClientList?.id || isInClientView ? clientId : null,
      pageSize: pageSize,
      inboxStatusId: currentDocumentInboxStatusId,
    });
    setIsLoadingDocuments(false);
    setDocumentList(data.documentList);
    setTotalDocs(data.totalDocs);
  };

  const handleEndDateChange = async (event) => {
    setSelectedEndDate(event.target.value);
    const folderId = getFolderIdOther();

    setIsLoadingDocuments(true);
    let data = await getDocumentList({
      startDate: selectedStartDate,
      endDate: event.target.value,
      page: 0,
      folderId: folderId,
      entityId: companyUser.companyId,
      searchId: searchClientList?.id || isInClientView ? clientId : null,
      search: searchField,
      pageSize: pageSize,
      inboxStatusId: currentDocumentInboxStatusId,
    });
    setIsLoadingDocuments(false);
    setDocumentList(data.documentList);
    setTotalDocs(data.totalDocs);
  };

  useEffect(() => {
    //FIXME
    const delayDebounceFn = setTimeout(async () => {
      const folderId = getFolderIdOther();

      let data = await getDocumentList({
        startDate: selectedStartDate,
        endDate: selectedEndDate,
        page: page,
        folderId: folderId,
        entityId: companyUser.companyId,
        searchId: searchClientList?.id || isInClientView ? clientId : null,
        search: searchField,
        pageSize: pageSize,
        inboxStatusId: currentDocumentInboxStatusId,
      });

      setDocumentList(data.documentList);
      setTotalDocs(data.totalDocs);
    }, 900);

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchField]);

  const customLocaleText = {
    ...ptBR.props.MuiDataGrid.localeText,
    noRowsLabel: "Sem Registros",
  };

  return (
    <>
      <ModalSendDocs
        handleClose={() => setOpenModalSendDocs(false)}
        open={openModalSendDocs}
        documentId={clickedRow?.document_id}
      />

      <Grid
        item
        xs
        style={{
          paddingTop: 0,
          paddingRight: client ? 0 : 30,
          paddingLeft: client ? 0 : 30,
          paddingBotton: 30,
        }}
      >
        {companyUser.isAccountant && !client && (
          <Grid container xs={12} style={{ marginBottom: "12px" }}>
            <SearchFilter item container>
              <OutsideText item>
                Empresa
                <Tooltip title="Selecione uma empresa para ver os documentos. Caso esteja vazio, ou você selecione sua própria empresa, os seus próprios documentos serão mostrados.">
                  <InfoOutlinedIcon />
                </Tooltip>
              </OutsideText>
              <Autocomplete
                id="company"
                size="small"
                fullWidth
                options={clientList}
                getOptionLabel={(option) => {
                  let maskedDocument = "";
                  if (option.document.length === 14) {
                    maskedDocument = MaskService.toMask(
                      "cnpj",
                      option.document
                    );
                  } else {
                    maskedDocument = MaskService.toMask("cpf", option.document);
                  }

                  return `${maskedDocument} - ${option.company_name}`;
                }}
                onChange={async (event, newValue) => {
                  setSearchClientList(newValue);
                  setIsLoadingDocuments(true);
                  const folderId =
                    FoldersIds.CND === tabValue
                      ? tabValue
                      : FoldersIds.Outros === tabValue
                      ? tabValue
                      : chipValue;
                  let data = await getDocumentList({
                    folderId: folderId,
                    search: searchField || null,
                    startDate: selectedStartDate,
                    endDate: selectedEndDate,
                    page: page,
                    pageSize: pageSize,
                    entityId: companyUser.companyId,
                    searchId: newValue?.id || null,
                    inboxStatusId: currentDocumentInboxStatusId,
                  });
                  setIsLoadingDocuments(false);
                  setDocumentList(data.documentList);
                  setTotalDocs(data.totalDocs);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Caso esteja vazio, seus documentos aparecerão"
                  />
                )}
              />
            </SearchFilter>
          </Grid>
        )}

        <Grid container xs={12} style={{ marginBottom: "16px", gap: "12px" }}>
          <SearchFilter item container>
            <OutsideText item>Pesquisar</OutsideText>
            <TextField
              onChange={(e) => setSearchField(e.target.value)}
              variant="outlined"
              size="small"
              value={searchField}
              fullWidth
              placeholder="Pesquisar por nome da Guia"
              InputProps={{
                startAdornment: <SearchIcon style={{ marginRight: "10px" }} />,
              }}
            />
          </SearchFilter>

          {/* TODO: VER COMO SERÁ IMPLEMENTADO O FAVORITO */}

          <SearchFilter item container>
            <OutsideText item>Status da Mensagem</OutsideText>
            <Select
              variant="outlined"
              fullWidth
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={currentDocumentInboxStatusId}
              onChange={handleChange}
              style={{ height: "40px" }}
            >
              {selectDocumentInboxStatus.map((item) => {
                return <MenuItem value={item.id}>{item.name}</MenuItem>;
              })}
            </Select>
          </SearchFilter>

          <Grid style={{ display: "flex" }}>
            <SearchFilter item container>
              <OutsideText item>De</OutsideText>
              <TextField
                id="startDate"
                type="date"
                variant="outlined"
                size="small"
                value={selectedStartDate}
                onChange={handleStartDateChange}
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ marginRight: "10px" }}
              />
            </SearchFilter>

            <SearchFilter item container>
              <OutsideText item>Até</OutsideText>
              <TextField
                id="endDate"
                type="date"
                variant="outlined"
                size="small"
                value={selectedEndDate}
                onChange={handleEndDateChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </SearchFilter>
          </Grid>
          <Grid
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <Tooltip title="Enviar documento">
              <IconButton
                size="small"
                style={{
                  backgroundColor: "#0036b3",
                  color: "white",
                  textTransform: "none",
                  width: "35px",
                  height: "35px",
                }}
                onClick={() => {
                  setSidebarAction((current) => ({ ...current, open: true }));
                  botContext.setShowDialogUploadDocument(true);
                }}
              >
                <Add />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>

        {!isLoadingDocuments ? (
          <div style={{ height: 380, width: "100%", marginTop: "10px" }}>
            <DataGridGuideReport
              disableColumnMenu
              disableSelectionOnClick
              disableColumnSelector
              rows={documentList}
              columns={columns}
              pageSize={pageSize}
              page={page}
              localeText={customLocaleText}
              onPageSizeChange={async (newPageSize) => {
                setPageSize(newPageSize);
                setIsLoadingDocuments(true);
                let data = await getDocumentList({
                  folderId:
                    FoldersIds.Outros === tabValue ? tabValue : chipValue,
                  search: searchField,
                  startDate: selectedStartDate,
                  endDate: selectedEndDate,
                  page: page,
                  pageSize: newPageSize,
                  entityId: companyUser.companyId,
                  searchId:
                    searchClientList?.id || isInClientView ? clientId : null,
                  inboxStatusId: currentDocumentInboxStatusId,
                });

                setDocumentList(data.documentList);
                setTotalDocs(data.totalDocs);
                setIsLoadingDocuments(false);
                lockLoad.current = true;
              }}
              onPageChange={async (newPage) => {
                if (!lockLoad.current) {
                  setPage(newPage);
                  setIsLoadingDocuments(true);
                  let data = await getDocumentList({
                    search: searchField,
                    folderId:
                      FoldersIds.Outros === tabValue ? tabValue : chipValue,
                    page: newPage,
                    pageSize: pageSize,
                    startDate: selectedStartDate,
                    endDate: selectedEndDate,
                    entityId: companyUser.companyId,
                    searchId: searchClientList?.id || null,
                    inboxStatusId: currentDocumentInboxStatusId,
                  });
                  setDocumentList(data.documentList);
                  setTotalDocs(data.totalDocs);
                  setIsLoadingDocuments(false);
                  lockLoad.current = false;
                }
              }}
              rowCount={totalDocs}
              paginationMode="server"
            />
          </div>
        ) : (
          <CircularProgress />
        )}

        <ModalArchive
          open={openModalArchive}
          handleClose={() => {
            setOpenModalArchive(false);
          }}
          handleSave={async () => {
            await archiveDocumentById(clickedRow);
            setOpenModalArchive(false);
          }}
          document={clickedRow}
        />
      </Grid>
    </>
  );
};

export default DocumentGuideReportBody;
