import axios from "axios";
import { notify } from "../utils/notify";

const newPath = `${process.env.REACT_APP_NEW_PAINEL_API_URL}`;

export async function getNotificationTree() {
  return axios
    .get(`${newPath}/v1/comms-config/get-notification-tree`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("tokenPanel"),
        "Content-Type": "application/json",
        "Content-Language": "pt-BR",
      },
    })
    .then((response) => {
      return response.data.data;
    })
    .catch((err) => {
      notify("Ocorreu um erro ao buscar os sistemas!");
      return null;
    });
}

export async function getNotificationServices() {
  return axios
    .get(`${newPath}/v1/comms-config/get-notification-services`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("tokenPanel"),
        "Content-Type": "application/json",
        "Content-Language": "pt-BR",
      },
    })
    .then((response) => {
      return response.data.data;
    })
    .catch((err) => {
      notify("Ocorreu um erro ao buscar os sistemas de notificação!");
      return null;
    });
}

export async function getSystemsServices() {
  return axios
    .get(`${newPath}/v1/comms-config/get-notification-system-services`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("tokenPanel"),
        "Content-Type": "application/json",
        "Content-Language": "pt-BR",
      },
    })
    .then((response) => {
      return response.data.data;
    })
    .catch((err) => {
      notify(
        "Ocorreu um erro ao buscar as configurações de notificações dos produtos!"
      );
      return null;
    });
}

export async function getSystemsServicesBySystemId(systemId, entityId) {
  return axios
    .get(
      `${newPath}/v1/comms-config/get-notification-system-services/${systemId}/${entityId}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("tokenPanel"),
          "Content-Type": "application/json",
          "Content-Language": "pt-BR",
        },
      }
    )
    .then((response) => {
      return response.data.data;
    })
    .catch((err) => {
      notify(
        "Ocorreu um erro ao buscar as configurações de notificações dos produtos!"
      );
      return null;
    });
}

export async function getSystemsServicesBySystemIdAccountant(systemId) {
  return axios
    .get(
      `${newPath}/v1/comms-config/get-notification-system-services/${systemId}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("tokenPanel"),
          "Content-Type": "application/json",
          "Content-Language": "pt-BR",
          "Entity-Id": localStorage.getItem("companyIdPanel"),
        },
      }
    )
    .then((response) => {
      return response.data.data;
    })
    .catch((err) => {
      notify(
        "Ocorreu um erro ao buscar as configurações de notificações dos produtos!"
      );
      return null;
    });
}

export async function postConfiguration(body) {
  return axios
    .post(`${newPath}/v1/comms-config/post-configuration`, body, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("tokenPanel"),
        "Content-Type": "application/json",
        "Content-Language": "pt-BR",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      notify("Ocorreu um erro cadastrar as configurações!");
      return null;
    });
}
