import {
  CircularProgress,
  Grid,
  IconButton,
  TextField,
  ListItemIcon,
  makeStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import AddIcon from "@material-ui/icons/Add";

import { DataGrid, GridPagination } from "@material-ui/data-grid";
import { DocumentTypes } from "../../../pages/GuideReports/utils/DocumentTypes";
import {
  BlueButton,
  CommDocsGrid,
  DoccCom,
  Separator,
} from "../styles/ModalPublicationChannelFrame";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import SmsOutlinedIcon from "@material-ui/icons/SmsOutlined";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import InputIcon from "@material-ui/icons/Input";
import GroupAddOutlinedIcon from "@material-ui/icons/GroupAddOutlined";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import { validEmail } from "../../../utils/validations";
import { v4 as uuidv4 } from "uuid";
import {
  getDocumentsById,
  postDocument,
  sendVerifyEmail,
  deleteDocument,
} from "../../../repositories/Document";
import { notify } from "../../../utils/notify";
import { isInteger } from "formik";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import ReactInputMask from "react-input-mask";

export const ModalPublicationChannel = ({
  startOption,
  entityIdParam,
  open,
  setTab,
  handleClose,
}) => {
  const [commType, setCommType] = useState(startOption);
  const [isLoadingModal, setIsLoadingModal] = React.useState(false);
  const [document, setDocument] = useState("");
  const [emailList, setDocumentsList] = useState([]);
  const cacheDocuments = React.useRef({});
  const [size, setSize] = React.useState("800px");
  const [pageSize, setPageSize] = React.useState(5);

  const columns = [
    {
      field: "document",
      headerName: commType,
      flex: 1,
      editable: false,
      sortable: false,
    },
    {
      field: "verificado",
      headerName: "Verificado",
      sortable: false,
      width: 105,
      editable: false,
      renderCell: ({ row }) => {
        return (
          <>
            {row.confirmed ? (
              <>
                <CheckIcon
                  style={{
                    width: "20px",
                    height: "20px",
                    color: "#006641",
                    marginLeft: "12px",
                  }}
                />
                <Separator
                  style={{
                    marginLeft: "34px",
                  }}
                >
                  |
                </Separator>
              </>
            ) : (
              <>
                {" "}
                <CloseIcon
                  style={{
                    width: "20px",
                    height: "20px",
                    color: "red",
                    marginLeft: "12px",
                  }}
                />
                <Separator
                  style={{
                    marginLeft: "34px",
                  }}
                >
                  |
                </Separator>
              </>
            )}
          </>
        );
      },
    },
    {
      field: "delete",
      headerName: "Excluir",
      sortable: false,
      width: 80,
      editable: false,
      renderCell: ({ id }) => {
        return (
          <ListItemIcon
            onClick={() => deleteEmailList(id)}
            style={{ minWidth: "30px", cursor: "pointer", marginLeft: "13px" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M9.99512 9.00488C10.5474 9.00488 10.9951 9.4526 10.9951 10.0049V16.5049C10.9951 17.0572 10.5474 17.5049 9.99512 17.5049C9.44283 17.5049 8.99512 17.0572 8.99512 16.5049V10.0049C8.99512 9.4526 9.44283 9.00488 9.99512 9.00488Z"
                fill="#616161"
              />
              <path
                d="M14.9951 10.0049C14.9951 9.4526 14.5474 9.00488 13.9951 9.00488C13.4428 9.00488 12.9951 9.4526 12.9951 10.0049V16.5049C12.9951 17.0572 13.4428 17.5049 13.9951 17.5049C14.5474 17.5049 14.9951 17.0572 14.9951 16.5049V10.0049Z"
                fill="#616161"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.63962 1.00488C9.27442 1.00488 8.93827 1.20396 8.76272 1.5242L7.40289 4.00488H1.99512C1.44283 4.00488 0.995117 4.4526 0.995117 5.00488C0.995117 5.55717 1.44283 6.00488 1.99512 6.00488H3.49512V22.0049C3.49512 22.5572 3.94283 23.0049 4.49512 23.0049H19.4951C20.0474 23.0049 20.4951 22.5572 20.4951 22.0049V6.00488H21.9951C22.5474 6.00488 22.9951 5.55717 22.9951 5.00488C22.9951 4.4526 22.5474 4.00488 21.9951 4.00488H16.5931L15.2646 1.53168C15.0904 1.20727 14.7519 1.00488 14.3837 1.00488H9.63962ZM14.3228 4.00488L13.7857 3.00488H10.2318L9.68367 4.00488H14.3228ZM5.49512 6.00488V21.0049H18.4951V6.00488H5.49512Z"
                fill="#616161"
              />
            </svg>
          </ListItemIcon>
        );
      },
    },
  ];

  const useStyles = makeStyles((theme) => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "24px",
      flexDirection: "column",
      gap: "24px",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(2, 4, 3),
      width: size,
      borderRadius: "16px",
    },
    MuiAccordionroot: {
      "&.MuiAccordion-root:before": {},
    },
    root: {
      "& .MuiDataGrid-root": {
        // Cor de fundo do DataGrid
        border: "none",
      },
      "& .MuiDataGrid-columnsContainer": {
        // Cor de fundo do cabeçalho
      },
      "& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell": {
        // Borda inferior das células e cabeçalhos
      },
      "& .MuiDataGrid-columnSeparator": {
        display: "none",
      },
    },
  }));

  const classes = useStyles();

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (commType === DocumentTypes.email) {
        addEmail();
      } else {
        addCellphone();
      }
    }
  };

  const addEmail = () => {
    addEmailCopy();
    setDocument("");
  };

  const addEmailCopy = async () => {
    setIsLoadingModal(true);
    if (validEmail(document.trim())) {
      const newEmailList = [...emailList];
      const newEmailObject = {
        id: uuidv4(),
        comm_type: commType,
        document: document.trim(),
        confirmed: false,
        enabled: false,
        entity_id: entityIds,
      };
      let response = await postDocument(newEmailObject);
      if (response !== null && response.status === 201) {
        newEmailList.push(newEmailObject);
        setDocumentsList(newEmailList);
        cacheDocuments.current[commType] = newEmailList;
        sendVerifyEmail(newEmailObject.document, newEmailObject);
      }
    } else {
      notify("Documento Inválido");
    }
    refreshDocuments();
  };

  const addCellphoneCopy = () => {
    const formatedDocument = document.replace(/[-()/ ]/g, "");
    if (formatedDocument.length === 11 && isInteger(formatedDocument)) {
      const newEmailList = [...emailList];

      const newEmailObject = {
        id: uuidv4(),
        comm_type: commType,
        document: formatedDocument.trim(),
        confirmed: true,
        enabled: false,
        entity_id: entityIds,
      };
      postDocument(newEmailObject);
      newEmailList.push(newEmailObject);
      setDocumentsList(newEmailList);
      cacheDocuments.current[commType] = newEmailList;
    } else {
      notify("Telefone inválido, tente novamente.", true, "warning");
    }
  };

  const addCellphone = () => {
    addCellphoneCopy();
    setDocument("");
  };

  const entityIds =
    entityIdParam === undefined && localStorage.getItem("isAccountantPanel")
      ? localStorage.getItem("companyIdPanel")
      : localStorage.getItem("isAccountantPanel")
      ? entityIdParam
      : localStorage.getItem("companyIdPanel");

  const refreshDocuments = async () => {
    delete cacheDocuments.current[commType];
    loadDocuments();
  };

  const loadDocuments = async () => {
    const documentsByType = cacheDocuments.current[commType];
    if (documentsByType) {
      setDocumentsList(documentsByType);
      return;
    }
    setIsLoadingModal(true);

    const params = {
      scheduled_id: null,
      companyId: entityIds,
      service: commType,
      confirmed: null,
      systemId: null,
    };

    const response = await getDocumentsById(params);
    setDocumentsList(response === null ? [] : response);
    cacheDocuments.current[commType] = response;
    setIsLoadingModal(false);
  };

  const backModalSize = (tabNumber) => {
    setTab(tabNumber);
    setSize("800px");
  };

  const deleteEmailList = async (id) => {
    setIsLoadingModal(true);
    await deleteDocument(id);
    refreshDocuments();
  };

  const customPagination = ({ pagination }) => {
    return (
      <GridPagination
        nextIconButtonText="Próxima página"
        backIconButtonText="Página anterior"
        rowsPerPageOptions={[5, 10, 15, 20]}
        labelRowsPerPage="Linhas por página"
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} de ${count} `
        }
      />
    );
  };

  const customLocaleText = {
    noRowsLabel: "Sem Registros",
  };

  useEffect(() => {
    if (open === true) {
      loadDocuments();
      // setTab(2);
      backModalSize(0);
    } else {
      cacheDocuments.current = {};
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, commType]);

  useEffect(() => {
    setDocument("");
  }, [commType]);

  return (
    <>
      {isLoadingModal ? (
        <div
          style={{ display: "flex", justifyContent: "center", padding: "30px" }}
        >
          {" "}
          <CircularProgress />
        </div>
      ) : (
        <>
          <Grid container item xs={12}>
            <CommDocsGrid item container justifyContent="space-between" xs={12}>
              <DoccCom
                onClick={() => setCommType(DocumentTypes.email)}
                active={commType === DocumentTypes.email}
              >
                <MailOutlineIcon style={{ height: "24px", width: "24px" }} />
                Email
              </DoccCom>
              <DoccCom
                disabled
                onClick={() => setCommType(DocumentTypes.sms)}
                active={commType === DocumentTypes.sms}
              >
                <SmsOutlinedIcon />
                SMS
              </DoccCom>
              <DoccCom
                disabled
                onClick={() => setCommType(DocumentTypes.whatsapp)}
                active={commType === DocumentTypes.whatsapp}
              >
                <WhatsAppIcon />
                Whatsapp
              </DoccCom>
              <DoccCom
                disabled
                onClick={() => setCommType(DocumentTypes.push)}
                active={commType === DocumentTypes.push}
              >
                <InputIcon />
                Push{" "}
              </DoccCom>
            </CommDocsGrid>

            {isLoadingModal ? (
              <CircularProgress />
            ) : (
              <>
                <Grid
                  item
                  xs={12}
                  style={{
                    marginBottom: "12px",
                    marginTop: "10px",
                    gap: "24px",
                  }}
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  {commType === DocumentTypes.email ? (
                    <TextField
                      onKeyDown={handleKeyDown}
                      variant="outlined"
                      size="small"
                      value={document}
                      onChange={(e) => setDocument(e.target.value)}
                      style={{
                        display: "flex",
                        height: "48px",
                        flex: "1 0 0",
                        borderRadius: "8px",
                      }}
                      placeholder="usuario@email.com"
                      InputProps={{
                        endAdornment: (
                          <>
                            <IconButton
                              style={{ height: "4px", width: "4px" }}
                              aria-label="more"
                              aria-controls="long-menu"
                              aria-haspopup="true"
                            >
                              <AddIcon onClick={addEmail} />
                            </IconButton>
                          </>
                        ),
                      }}
                    />
                  ) : (
                    <ReactInputMask
                      mask="(99) 99999-9999"
                      value={document}
                      onChange={(e) => setDocument(e.target.value)}
                    >
                      {(inputProps) => (
                        <TextField
                          onKeyDown={handleKeyDown}
                          variant="outlined"
                          size="small"
                          style={{
                            display: "flex",
                            height: "48px",
                            flex: "1 0 0",
                            borderRadius: "8px",
                          }}
                          placeholder="(99) 99999-9999"
                          InputProps={{
                            endAdornment: (
                              <>
                                <IconButton
                                  style={{ height: "4px", width: "4px" }}
                                  aria-label="more"
                                  aria-controls="long-menu"
                                  aria-haspopup="true"
                                >
                                  <AddIcon onClick={addCellphone} />
                                </IconButton>
                              </>
                            ),
                          }}
                        />
                      )}
                    </ReactInputMask>
                  )}

                  <BlueButton
                    item
                    onClick={() => backModalSize(1)}
                    style={{ background: "#0036B3" }}
                  >
                    Inserir múltiplos
                    <GroupAddOutlinedIcon style={{ marginLeft: "8px" }} />
                  </BlueButton>

                  <AutorenewIcon
                    style={{ cursor: "pointer" }}
                    onClick={refreshDocuments}
                  />
                </Grid>

                <div
                  style={{ minHeight: 300, width: "100%", overflowX: "hidden" }}
                  className={classes.root}
                >
                  <DataGrid
                    disableColumnMenu
                    disableSelectionOnClick
                    rows={emailList}
                    columns={columns}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[5, 10, 15, 20]}
                    components={{
                      Pagination: customPagination,
                    }}
                    localeText={customLocaleText}
                  />
                </div>
              </>
            )}
          </Grid>
        </>
      )}
    </>
  );
};
