import {
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  FormControlLabel,
  Grid,
  TextField,
  makeStyles,
  Collapse,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  MainAccordionText,
  OutsideText,
  SearchFilter,
  VersionButton,
} from "./styles/CommsConfig";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { CustomAccordion } from "./styles/CommsConfig";
import {
  getNotificationTree,
  getNotificationServices,
} from "../../repositories/CommsConfig";
import { getAccountantClients } from "../../repositories/portal_api/AccountantClient";
import { ModalPublicationChannelFrame } from "../../components/modal/ModalPublicationChannelFrame";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ConfigIcon from "../../components/icons/ConfigIcon";
import { notify } from "../../utils/notify";
import ModalCommsConfig from "../../components/modal/ModalCommsConfig/ModalCommsConfig";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "24px",
    flexDirection: "column",
    gap: "24px",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    width: "785px",
    borderRadius: "16px",
  },
  MuiAccordionroot: {
    "&.MuiAccordion-root:before": {},
  },
}));

export const CommsConfig = () => {
  const classes = useStyles();
  const [tree, setTree] = React.useState([]);
  const [notificationServices, setNotificationServices] = React.useState([]);
  const [isLoadingAccordion, setIsLoadingAccordion] = React.useState(false);

  const [modalConfig, setModalConfig] = useState({
    open: false,
    systemId: null,
    systemName: "",
  });

  const [modalDocuments, setModalDocuments] = useState({
    open: false,
  });

  const [clientList, setClientList] = React.useState([]);
  const [searchClient, setSearchClient] = React.useState("");
  const [expandedAccordions, setExpandedAccordions] = useState({});

  useEffect(() => {
    loadSystemsServices();
  }, []);

  const loadSystemsServices = async () => {
    setIsLoadingAccordion(true);
    const [responseSystem, responseService, responseClients] =
      await Promise.all([
        getNotificationTree(),
        getNotificationServices(),
        getAccountantClients(),
      ]);
    setIsLoadingAccordion(false);
    setTree(responseSystem);
    setNotificationServices(responseService);
    setClientList(responseClients.data);
  };

  const handleOpenModalExpecifyConfig = (parent) => {
    setModalConfig({
      open: true,
      systemId: parent.id,
      systemName: parent.name,
    });
  };

  const handleCloseModalExpecifyConfig = () => {
    setModalConfig({
      open: false,
      systemId: null,
      systemName: "",
    });
  };

  const handleOpenModalDocuments = () => {
    if (!searchClient)
      return notify(
        "Selecione uma empresa para configurar os canais de publicação."
      );

    setModalDocuments({
      open: true,
    });
  };

  const handleCloseModalDocuments = () => {
    setModalDocuments({
      open: false,
      systemId: null,
    });
  };

  const handleAccordionChange = (accordionId) => {
    setExpandedAccordions((prevState) => ({
      ...prevState,
      [accordionId]: !prevState[accordionId],
    }));
  };

  const RecursiveComponentAccordion = ({ tree, time = 0 }) => {
    return (
      <div style={{ width: "100%" }}>
        {isLoadingAccordion ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "30px",
            }}
          >
            {" "}
            <CircularProgress />
          </div>
        ) : (
          tree?.map((parent) => {
            const isAccordionExpanded = expandedAccordions[parent.id];
            const hasChildren = parent.children && parent.children.length > 0;

            return (
              <div key={parent.id} style={{ width: "100%" }}>
                <CustomAccordion
                  container
                  classes={{
                    root: classes.MuiAccordionroot,
                  }}
                  style={{
                    width: "100%",
                    boxShadow: "none",
                  }}
                  expanded={isAccordionExpanded && hasChildren}
                  onChange={() => handleAccordionChange(parent.id)}
                  TransitionComponent={Collapse}
                >
                  <AccordionSummary
                    expandIcon={
                      parent.children && parent.children.length > 0 ? (
                        <ExpandMoreIcon
                          style={{ width: "30px", height: "30px" }}
                        />
                      ) : (
                        <div style={{ width: "30px", height: "30px" }} />
                      )
                    }
                    id={parent.id}
                    style={{
                      flexDirection: "row-reverse",
                      paddingLeft: time * 40,
                      borderBottom: "1px solid #e0e0e0",
                      width: "auto",
                    }}
                    aria-controls={parent.name}
                  >
                    <FormControlLabel
                      aria-label="Acknowledge"
                      labelPlacement="start"
                      label={
                        <MainAccordionText
                          className={"2"}
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {parent.name}
                        </MainAccordionText>
                      }
                      control={
                        <IconButton
                          size="small"
                          style={{ marginLeft: 16 }}
                          onClick={(event) => {
                            event.stopPropagation();
                            handleOpenModalExpecifyConfig(parent);
                          }}
                        >
                          <ConfigIcon fill="#616161" />
                        </IconButton>
                      }
                    />
                  </AccordionSummary>
                  <AccordionDetails
                    style={{ width: "100%", transition: "0.3s" }}
                  >
                    {parent.isFolder && <button>{parent.name}</button>}
                    <div style={{ width: "100%" }}>
                      {parent.children && (
                        <RecursiveComponentAccordion
                          tree={parent.children}
                          time={time + 1}
                        />
                      )}
                    </div>
                  </AccordionDetails>
                </CustomAccordion>
              </div>
            );
          })
        )}
      </div>
    );
  };

  return (
    <Grid
      style={{
        padding: "16px 16px 24px 16px",
      }}
      container
    >
      <Grid container xs={12} style={{ marginBottom: "24px", gap: "24px" }}>
        {localStorage.getItem("isAccountantPanel") ? (
          <SearchFilter item container>
            <OutsideText item>
              Empresa{" "}
              <Tooltip title="Selecione uma empresa para configurar. Caso esteja vazio, ou você selecione sua própria empresa, a configuração valerá para todos seus clientes.">
                <InfoOutlinedIcon />
              </Tooltip>{" "}
            </OutsideText>
            <Autocomplete
              style={{ marginTop: "-2px" }}
              id="company"
              size="small"
              fullWidth
              options={clientList}
              getOptionLabel={(option) => option.trading_name}
              onChange={(event, newValue) => {
                setSearchClient(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Caso esteja vazio, será aplicado para todos os clientes"
                />
              )}
            />
          </SearchFilter>
        ) : (
          ""
        )}

        <SearchFilter
          item
          style={{
            paddingRight: "16px",
            alignSelf: "end",
            marginBottom: "-10px",
          }}
          container
        >
          <VersionButton
            onClick={() => handleOpenModalDocuments()}
            style={
              !searchClient
                ? {
                    backgroundColor: "#e0e0e0",
                    color: "#9e9e9e",
                  }
                : null
            }
          >
            Canais de Publicação
          </VersionButton>
        </SearchFilter>
      </Grid>

      <Grid container xs={12} style={{ width: "100%" }}>
        <RecursiveComponentAccordion fullWidth tree={tree} />
      </Grid>

      <ModalCommsConfig
        open={modalConfig.open}
        systemId={modalConfig.systemId}
        onClose={handleCloseModalExpecifyConfig}
        listServices={notificationServices}
        selectedEntityId={searchClient?.id}
        selectedServiceId={modalConfig.systemId}
      />

      <ModalPublicationChannelFrame
        open={modalDocuments.open}
        handleClose={handleCloseModalDocuments}
        entityIdParam={searchClient?.id}
        nameIdentifier={searchClient?.trading_name}
        startOption={"Email"}
      />
    </Grid>
  );
};
