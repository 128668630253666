import React, { useContext, useEffect, useState } from "react";

import { GlobalVariables } from "../../GlobalVariables";
import { usePermissions } from "../../utils/permissionsHook";
import { GlobalContext } from "../../contexts/GlobalContext";
import useSettingTranslate from "../../translations/settings/Setting";
import { GlobalSubheader } from "../../components/subHeader/GlobalSubheader";
import { DefaultPageContainer } from "../../components/layoutContainer/styles/LayoutContainer";
import { ButtonTogleStyle, DivStyle } from "../../components/modal/Modal";
import ReleaseVersion from "../../components/settings/ReleaseVersion";
import ChatSettings from "../chatSettings/ChatSettings";
import { CustomerEditData } from "../customerManagements/children/CustomerEditData";


export default function Settings({ location }) {
  const translate = useSettingTranslate();
  const { companyUser } = useContext(GlobalContext);

  const { checkIfUserHasPermission } = usePermissions();
  const [currentTab, setCurrentTab] = useState(1);

  const tabList = [
    {
      id: 1,
      name: translate.registrationData,
      permission: checkIfUserHasPermission(
        GlobalVariables.PERMISSIONS.SETTINGS_COMPANY
      ),
      forAccountant: false,
    },
    {
      id: 2,
      name: "Chat",
      permission: checkIfUserHasPermission(GlobalVariables.PERMISSIONS.CRM),
      forAccountant: true,
    },
    {
      id: 3,
      name: "Notas de Versão",
      permission: true,
      forAccountant: false,
    },
  ];

  const RecursiveTabs = ({ list }) => (
    <DivStyle style={{ marginBottom: "16px" }}>
      {list.map((item) => (
        <>
          {(item.forAccountant === false ||
            (companyUser?.isAccountant && item.forAccountant === true)) && (
            <>
              <ButtonTogleStyle
                key={item.id}
                active={currentTab === item.id}
                onClick={() => {
                  setCurrentTab(item.id);
                }}
                disabled={!item.permission}
              >
                <p style={{ textTransform: "none" }}>{item.name}</p>
              </ButtonTogleStyle>
              &nbsp;
            </>
          )}
        </>
      ))}
    </DivStyle>
  );

  useEffect(() => {
    if (
      !checkIfUserHasPermission(GlobalVariables.PERMISSIONS.SETTINGS_COMPANY)
    ) {
      setCurrentTab(4);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <GlobalSubheader
        title={"Configurações"}
        titleLocation={"/setting"}
      ></GlobalSubheader>
      <DefaultPageContainer>
        <div
          style={{
            background: "#fff",
            border: "1px solid #E0E0E0",
            borderRadius: 15,
            padding: 15,
          }}
        >
          <RecursiveTabs list={tabList} />

          {currentTab === 1 ? (
            <CustomerEditData
              pathname={location.pathname}
              customerId={companyUser?.companyId}
              from={"settings"}
              location={location}
            />
          ) : currentTab === 2 ? (
            <ChatSettings />
          ) : (
            currentTab === 3 && <ReleaseVersion />
          )}
        </div>
      </DefaultPageContainer>
    </>
  );
}
