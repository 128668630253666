import React, { useContext, useEffect, useMemo, useRef, useState } from "react";

import { Grid, CircularProgress } from "@material-ui/core";

import {
  VersionButton,
  VersionButtonsGrid,
  VersionGrid,
  LabelVersion,
  VersionHeader,
  VersionContainer,
  LabelVersionDate,
  IntroVersion,
  IntroTextVersion,
  DescriptionVersionGrid,
  ShowMoreButton,
  TextShowMoreLessButton,
  ArrowShowMoreButton,
  VersionRow,
  LabelcurrentVersion,
  Labeltitle,
} from "./styles/ReleaseVersion";
import {
  ListSystemVersion,
  downloadPdfById,
  downloadPdfFromHtmlById,
} from "../../repositories/panel/v1/integrations/VersionPortal";
import { notify } from "../../utils/notify";
import { GlobalContext } from "../../contexts/GlobalContext";

export default function ReleaseVersion() {
  const versions = useRef([]);
  const [system, setSystem] = useState("Portal");
  const [filteredVersions, setFilteredVersions] = useState([]);
  const [loading, setLoading] = useState(false);
  const { setBreadcrumbs } = useContext(GlobalContext);

  useEffect(() => {
    start();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const start = async () => {
    setBreadcrumbs([
      {
        name: "Notas de Versão",
        href: "",
      },
    ]);
    setLoading(true);
    let response = await ListSystemVersion();

    if (response?.status === 200) {
      versions.current = response.data;
      loadProduct("Portal");
    }
    setLoading(false);
  };

  const loadProduct = (product) => {
    setSystem(product);

    const newDataVerion = versions.current.filter(
      (item) => product === item.product
    );

    setFilteredVersions(newDataVerion);
  };
  async function downloadPdf(item) {
    setLoadingItem(item.id, true);

    const responsePdf = await downloadPdfById(item.id);
    const responsePdfFromHtml = await downloadPdfFromHtmlById(item.id);

    if (!responsePdf && !responsePdfFromHtml) {
      setLoadingItem(item.id, false);
      return notify("Nota de versão não disponível para download");
    }

    const data = window.URL.createObjectURL(responsePdf || responsePdfFromHtml);

    const link = document.createElement("a");
    link.href = data;
    link.download = (item.name || "default") + ".pdf";
    link.dispatchEvent(
      new MouseEvent("click", { bubbles: true, cancelable: true, view: window })
    );

    setTimeout(() => {
      window.URL.revokeObjectURL(data);
      link.remove();
      setLoadingItem(item.id, false);
    }, 100);
  }

  function setLoadingItem(id, loading) {
    const updatedVersions = filteredVersions.map((version) =>
      version.id === id ? { ...version, loading: loading } : version
    );
    setFilteredVersions(updatedVersions);
  }

  const currentVersion = useMemo(() => {
    return filteredVersions?.[0] || null;
  }, [filteredVersions]);

  const olderVersions = useMemo(() => {
    return filteredVersions?.slice(1) || [];
  }, [filteredVersions]);

  return (
    <>
      <Grid style={{ width: "100%" }}>
        <VersionButtonsGrid container>
          <VersionButton
            onClick={(event, newValue) => {
              loadProduct("Portal");
            }}
            active={system === "Portal"}
          >
            Qyon
          </VersionButton>

          <VersionButton
            onClick={(event, newValue) => {
              loadProduct("Gestão Fácil");
            }}
            active={system === "Gestão Fácil"}
          >
            Gestão Fácil
          </VersionButton>

          <VersionButton
            onClick={(event, newValue) => {
              loadProduct("XML");
            }}
            active={system === "XML"}
          >
            XML
          </VersionButton>

          <VersionButton
            onClick={(event, newValue) => {
              loadProduct("Folha");
            }}
            active={system === "Folha"}
          >
            Folha de Pagamento
          </VersionButton>

          <VersionButton
            onClick={(event, newValue) => {
              loadProduct("Fiscal");
            }}
            active={system === "Fiscal"}
          >
            {" "}
            Escrita Fiscal{" "}
          </VersionButton>

          <VersionButton
            onClick={(event, newValue) => {
              loadProduct("Contábil");
            }}
            active={system === "Contábil"}
          >
            {" "}
            Contábil{" "}
          </VersionButton>

          <VersionButton
            onClick={(event, newValue) => {
              loadProduct("Qyon Bank");
            }}
            active={system === "Qyon Bank"}
          >
            {" "}
            Qyon Bank{" "}
          </VersionButton>

          <VersionButton
            onClick={(event, newValue) => {
              loadProduct("CRM");
            }}
            active={system === "CRM"}
          >
            {" "}
            CRM{" "}
          </VersionButton>

          <VersionButton
            onClick={(event, newValue) => {
              loadProduct("Radar");
            }}
            active={system === "Radar"}
          >
            {" "}
            Radar{" "}
          </VersionButton>
        </VersionButtonsGrid>

        <Grid>
          <Grid
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            {loading && <CircularProgress size={24} color="secondary" />}
          </Grid>
          <IntroVersion style={{ margin: "16px" }}>
            {filteredVersions.length === 0 && !loading && (
              <p>Nenhuma nota de atualização encontrada</p>
            )}
          </IntroVersion>
          {currentVersion && (
            <VersionGrid>
              <VersionHeader>
                <VersionRow>
                  <LabelVersion>{currentVersion.version}</LabelVersion>
                  <Labeltitle>{currentVersion.name}</Labeltitle>
                </VersionRow>
                <VersionContainer>
                  <LabelcurrentVersion>Mais Recente</LabelcurrentVersion>
                  <LabelVersionDate>
                    {currentVersion.release_date}
                  </LabelVersionDate>
                </VersionContainer>
              </VersionHeader>
              <DescriptionVersionGrid>
                <IntroVersion>
                  {" "}
                  Nova versão recém-lançada do sistema, acompanhada por novos
                  recursos e melhorias significativas:{" "}
                </IntroVersion>
                <IntroTextVersion>{currentVersion.resume}</IntroTextVersion>
                <ShowMoreButton
                  onClick={() => {
                    downloadPdf(currentVersion);
                  }}
                >
                  {currentVersion.loading === true ? (
                    <CircularProgress size={24} color="secondary" />
                  ) : (
                    <>
                      <ArrowShowMoreButton>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M19 9h-4V3H9v6H5l7 7 7-7zM4 19h16v2H4v-2z"
                            fill="#fff"
                          />
                        </svg>
                      </ArrowShowMoreButton>
                      <TextShowMoreLessButton>
                        Baixar nota de versão
                      </TextShowMoreLessButton>
                    </>
                  )}
                </ShowMoreButton>
              </DescriptionVersionGrid>
            </VersionGrid>
          )}

          {olderVersions.length > 0 &&
            olderVersions.map((item) => (
              <VersionGrid key={item.id}>
                <VersionHeader>
                  <VersionRow>
                    <LabelVersion>{item.version}</LabelVersion>
                    <Labeltitle>{item.name}</Labeltitle>
                  </VersionRow>
                  <LabelVersionDate>{item.release_date}</LabelVersionDate>
                </VersionHeader>
                <DescriptionVersionGrid>
                  <IntroVersion>
                    {" "}
                    Nova versão recém-lançada do sistema, acompanhada por novos
                    recursos e melhorias significativas:{" "}
                  </IntroVersion>
                  <IntroTextVersion>{item.resume}</IntroTextVersion>
                  <ShowMoreButton
                    onClick={() => {
                      downloadPdf(item);
                    }}
                  >
                    {item.loading === true ? (
                      <CircularProgress size={24} color="secondary" />
                    ) : (
                      <>
                        <ArrowShowMoreButton>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M19 9h-4V3H9v6H5l7 7 7-7zM4 19h16v2H4v-2z"
                              fill="#fff"
                            />
                          </svg>
                        </ArrowShowMoreButton>
                        <TextShowMoreLessButton>
                          Baixar nota de versão
                        </TextShowMoreLessButton>
                      </>
                    )}
                  </ShowMoreButton>
                </DescriptionVersionGrid>
              </VersionGrid>
            ))}
        </Grid>
      </Grid>
    </>
  );
}
