import axios from "axios";
import { notify } from "../utils/notify";

import { search } from "./BaseRepositoryComponents";

const newPath = `${process.env.REACT_APP_NEW_PAINEL_API_URL}`;

export async function getFolders() {
  return axios
    .get(`${newPath}/v1/folder`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("tokenPanel"),
        "Content-Type": "application/json",
        "Content-Language": "pt-BR",
      },
    })
    .then((response) => {
      return response.data.data;
    })
    .catch((err) => {
      notify("Ocorreu um erro ao buscar as pastas!");
      return null;
    });
}

export async function getDocuments({
  page_size,
  start_date,
  end_date,
  page,
  folder_id,
  search,
  entity_id,
  favorite,
  do_count,
  search_id,
  archived,
}) {
  return axios
    .get(`${newPath}/v1/document`, {
      params: {
        page_size: page_size,
        start_date: start_date,
        end_date: end_date,
        page: page,
        folder_id: folder_id,
        search: search,
        do_count: do_count,
        search_id: search_id,
        archived: archived,
      },
      headers: {
        Authorization: "Bearer " + localStorage.getItem("tokenPanel"),
        "Content-Type": "application/json",
        "Content-Language": "pt-BR",
        entity_id: entity_id,
        favorite: favorite,
      },
    })
    .then((response) => {
      if (response?.data?.success) return response?.data?.data;

      return null;
    })
    .catch((err) => {
      notify("Ocorreu um erro ao buscar os documentos!");
      return err;
    });
}

export async function favoriteDocument(document_id, entity_id, body) {
  return await axios
    .put(`${newPath}/v1/document/${document_id}/favorite`, body, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("tokenPanel"),
        "Content-Type": "application/json",
        "Content-Language": "pt-BR",
        entity_id: entity_id,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      notify("Ocorreu um Erro ao favoritar o documento!");
      return err;
    });
}

export async function downloadDocument(document_id, entity_id) {
  return await axios
    .get(`${newPath}/v1/document/${document_id}/download`, {
      responseType: "blob",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("tokenPanel"),
        "Content-Type": "application/json",
        "Content-Language": "pt-BR",
        entity_id: entity_id,
      },
    })
    .then((response) => {
      return URL.createObjectURL(response.data);
    })
    .catch((err) => {
      notify("Ocorreu um erro ao baixar o documento!");
      return err;
    });
}

export async function sendDocumentsByEmail(
  entity_id,
  document_id,
  document_name,
  email
) {
  return await axios
    .get(
      `${newPath}/v1/document/${document_id}/${document_name}/${email}/send-email`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("tokenPanel"),
          "Content-Type": "application/json",
          "Content-Language": "pt-BR",
          entity_id: entity_id,
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      notify("Ocorreu um Erro ao Enviar o Documento!", true, "warning");
      return err;
    });
}

export async function sendEmailDocumentById(document_id, emailList) {
  const body = {
    emailList: emailList,
    document_id: document_id,
  };

  return search({
    method: "POST",
    path: `${newPath}/v1/document/send-email`,
    myBody: body,
  });
}

export async function sendTemplateFiles(body) {
  return await axios
    .post(`${newPath}/v1/email-template/send-template-files`, body, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("tokenPanel"),
        "Content-Type": "application/json",
        "Content-Language": "pt-BR",
      },
    })
    .then((response) => {
      if (body["editorType"] === "Edit") {
        notify("Template Editado com Sucesso!", true, "success");
        return response.data.data;
      } else {
        notify("Template Criado com Sucesso!", true, "success");
        return response.data.data;
      }
    })
    .catch((err) => {
      if (body["editorType"] === "Edit") {
        notify("Ocorreu um Erro ao Editar o Template!");
      } else {
        notify("Ocorreu um Erro ao Salvar o Template!");
      }

      return err;
    });
}

export async function sendPreviewEmail(email, document, type, template_id) {
  return await axios
    .get(
      `${newPath}/v1/email-template/send-preview-email/${email}/${document}/${type}/${template_id}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("tokenPanel"),
          "Content-Type": "application/json",
          "Content-Language": "pt-BR",
        },
      }
    )
    .then((response) => {
      notify("Prévia Enviada com Sucesso!", true, "success");
      return response;
    })
    .catch((err) => {
      return null;
    });
}

// /v1/documents/change-additional-message
export async function changeAdditionalMessage(body) {
  return await axios
    .post(`${newPath}/v1/email-template/change-additional-message`, body, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("tokenPanel"),
        "Content-Type": "application/json",
        "Content-Language": "pt-BR",
      },
    })
    .then((response) => {
      notify("Mensagem Adicional Alterada com sucesso!", true, "success");
      return response;
    })
    .catch((err) => {
      notify("Ocorreu um Erro ao Alterar a Mensagem Adicional!");
      return err;
    });
}

export async function deleteTemplate(template_id, document, type) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("tokenPanel"),
  };
  return await axios
    .delete(
      `${newPath}/v1/email-template/delete-template/${template_id}/${document}/${type}`,
      {
        headers: headers,
      }
    )
    .then((response) => {
      if (response.data.data === "Template is being used") {
        notify(
          "O template está sendo utilizado em outra configuração!",
          true,
          "warning"
        );
        return response;
      } else {
        notify("Template deletado com sucesso!", true, "success");
        return response;
      }
    })
    .catch((err) => {
      notify("Ocorreu um erro ao deletar o template!");
      return err;
    });
}

export async function getTemplateImage(document, type, template_id) {
  return axios
    .get(
      `${newPath}/v1/email-template/get-template-image/${document}/${type}/${template_id}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("tokenPanel"),
          "Content-Type": "application/json",
          "Content-Language": "pt-BR",
        },
      }
    )
    .then((response) => {
      return response.data.data;
    })
    .catch((err) => {
      notify("Ocorreu um erro ao carregar as imagens dos templates!");
      return null;
    });
}

export async function getTemplateJson(document, type, template_id) {
  return axios
    .get(
      `${newPath}/v1/email-template/get-template-json/${document}/${type}/${template_id}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("tokenPanel"),
          "Content-Type": "application/json",
          "Content-Language": "pt-BR",
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      notify("Ocorreu um erro as carregar o template!");
      return null;
    });
}

export const getDocumentHistory = async (document_id) => {
  return search({
    method: "GET",
    path: `${newPath}/v1/document/${document_id}/history`,
  });
};

export const archiveDocument = async (document_id, archived = true) => {
  return search({
    method: "PUT",
    path: `${newPath}/v1/document/${document_id}/archive`,
    myBody: { archived: archived },
  });
};
