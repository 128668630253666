import { BrowserRouter, Route, Switch } from "react-router-dom";

import AccessProfile from "./modules/AccessProfile";
import Attendances from "./modules/Attendance";
import ChatSetting from "./modules/ChatSetting";
import CustomerManagement from "./modules/CustomerManagement";
import Dashboard from "./modules/Dashboard";
import Guide from "./modules/Guide";
import Setting from "./modules/Setting";
import AccessRelease from "./modules/Versions";
import UserConfirmation from "./UserConfirmation";
import Backup from "./modules/Backup";
import { LayoutContainer } from "../components/layoutContainer/LayoutContainer";
import MiddlewareRoute from "./Middleware";
import RenderAnimation from "../components/basics/RenderAnimation";
import RecoverPassword from "../pages/authentications/RecoverPassword";
import { ChangePassword } from "../pages/authentications/ChangePassword";

import CompanySelection from "../pages/authentications/CompanySelection";
import Login from "../pages/authentications/Login";
import NotFoundPage from "../assets/lotties/NotFoundPage2.json";
import { ComercialQyon } from "../pages/comercialQyon/home";
import { TableXML } from "../pages/comercialQyon/children/tableXML";
import { TableCND } from "../pages/comercialQyon/children/tableCND";

import Logo from "../pages/default/Logo";
import Video from "../pages/default/Video";
import Home from "../pages/default/Home";

import { GlobalVariables } from "../GlobalVariables";
import KnowledgeBaseModule from "./modules/KnowledgeBase";
import AppStoreModule from "./modules/AppStore";

const Routes = () => {
  return (
    <>
      <BrowserRouter
        getUserConfirmation={(message, callback) => {
          return UserConfirmation(message, callback);
        }}
      >
        <Switch>
          <Route path="/login" component={Login} />

          <MiddlewareRoute
            partialLogin
            path="/companyselection"
            component={CompanySelection}
          />

          <Route path="/changepassword" component={ChangePassword} />

          <Route path="/recoverpassword" component={RecoverPassword} />

          <Route
            path="/notfound"
            component={() => (
              <RenderAnimation
                height={420}
                width={500}
                speed={1}
                label="PÁGINA NÃO ENCONTRADA"
                labelColor="gray"
                open={true}
                animation={NotFoundPage}
                timeout={5000}
              />
            )}
          />

          <MiddlewareRoute exact path="/versoes" component={ComercialQyon} />
          <MiddlewareRoute exact path="/versoes/xml" component={TableXML} />
          <MiddlewareRoute exact path="/versoes/cnd" component={TableCND} />

          <Route path="/logo" component={Logo} />
          <Route path="/video" component={Video} />
          <Route path="/home" component={Home} />
          <MiddlewareRoute
            routePermissionId={GlobalVariables.PERMISSIONS.SETTINGS_COMPANY}
            path="/edithome"
            component={Home}
          />

          <Route exact path="/" component={Home} />

          <Route path="/">
            <LayoutContainer noPadding>
              <Dashboard />
              <CustomerManagement />
              <Setting />
              <Attendances />
              <Guide />
              <ChatSetting />
              <AccessProfile />
              <AccessRelease />
              <KnowledgeBaseModule />
              <Backup />
              <AppStoreModule />
            </LayoutContainer>
          </Route>
        </Switch>
      </BrowserRouter>
    </>
  );
};

export default Routes;
