import React, { useContext, useEffect, useState } from "react";

import { useHistory } from "react-router-dom";

import { Grid, IconButton, InputAdornment } from "@material-ui/core";

import TextFieldErp from "../../components/basics/TextFieldErp";

import { GridStyled, Margin } from "../../styles/GlobalStyle";

import { LoginContext } from "./contexts/LoginContext";
import useLoginController from "./controllers/LoginController";
import { PasswordIcon, QyonLogo } from "./LoginIcon";
import {
  FormRecoveryPassword,
  LogoQyonContainer,
  RecoveryCancelButton,
  RecoveryLabel,
  RecoveryPasswordHeader,
  RecoveryPasswordHeaderSecondary,
  RecoverySendButton,
} from "./styles/RecoveryPassword";
import PasswordRequirements from "./PasswordRequirements";
import LoginLayout from "./LoginLayout";

const View = () => {
  const { handleChangePassword } = useLoginController();
  const {
    password,
    setPassword,
    confirmNewPassword,
    setConfirmNewPassword,
    errors,
  } = useContext(LoginContext);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

  const { push } = useHistory();

  useEffect(() => {
    setPassword("");
    setConfirmNewPassword("");
  }, [setPassword, setConfirmNewPassword]);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowPasswordConfirm = () =>
    setShowPasswordConfirm((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <LoginLayout>
      <FormRecoveryPassword style={{ maxHeight: "100vh" }}>
        <GridStyled>
          <Margin top="20" />
          <LogoQyonContainer>
            <QyonLogo />
          </LogoQyonContainer>

          <Margin top="16" />

          <RecoveryPasswordHeader style={{ textAlign: "center" }}>
            Troca de Senha
          </RecoveryPasswordHeader>
          <Margin top="8" />
          <RecoveryPasswordHeaderSecondary style={{ textAlign: "center" }}>
            No seu Primeiro acesso é necessario a alteração da senha
          </RecoveryPasswordHeaderSecondary>

          <Margin top="8" />
          <form onSubmit={handleChangePassword} autoComplete="off">
            <Grid container justifyContent="center">
              <GridStyled justifyContent="center" container direction="column">
                <Grid justifyContent="flex" container>
                  <RecoveryLabel>Nova Senha</RecoveryLabel>
                  <TextFieldErp
                    error={errors.password ? true : false}
                    helperText={errors.password || ""}
                    variant="outlined"
                    placeholder="Digite a nova senha"
                    type={showPasswordConfirm ? "text" : "password"}
                    inputProps={{
                      autoComplete: "off",
                    }}
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPasswordConfirm}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          <PasswordIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </Grid>

                <Grid item>
                  <RecoveryLabel>Confirme a Senha</RecoveryLabel>
                  <TextFieldErp
                    error={errors.confirmNewPassword ? true : false}
                    helperText={errors.confirmNewPassword || ""}
                    variant="outlined"
                    placeholder="Repita a nova senha"
                    type={showPassword ? "text" : "password"}
                    value={confirmNewPassword}
                    setValue={setConfirmNewPassword}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          <PasswordIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </Grid>
              </GridStyled>

              <Margin top="8" />
              <PasswordRequirements password={password} />

              <Margin top="16" />
              <Grid
                container
                spacing={3}
                rowSpacing={10}
                columnSpacing={{ xs: 1, sm: 3, md: 3 }}
              >
                <Grid item xs={6}>
                  <RecoveryCancelButton
                    type="submit"
                    full
                    variant="contained"
                    color="#364461"
                    label="Cancelar"
                    onClick={() => push("/login")}
                  >
                    Cancelar
                  </RecoveryCancelButton>
                </Grid>
                <Grid item xs={6}>
                  <RecoverySendButton
                    type="submit"
                    full
                    variant="contained"
                    color="primary"
                    label="Enviar"
                  >
                    Salvar
                  </RecoverySendButton>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </GridStyled>
      </FormRecoveryPassword>
    </LoginLayout>
  );
};

const ChangePassword = ({ ...props }) => {
  return <View {...props} />;
};

export { ChangePassword };
