import React, { useContext, useEffect, useState } from "react";

import { GlobalContext } from "../../../contexts/GlobalContext";
import {
  ButtonSystem,
  CompanyLogo,
  CompanyName,
  DivCompanyAbbreviation,
  DivNotResult,
  FormControlDashboard,
  FormControlLabelStyled,
  FormControlStyled,
  GridStyled,
  StyledSubheader,
} from "../../dashboard/components/styles/Subheader";
import { SearchOutlined } from "@material-ui/icons";
import {
  Badge,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  Popover,
  Switch,
} from "@material-ui/core";
import { usePortalAsClient } from "../../../utils/viewPortalAsClientHook";
import { notify } from "../../../utils/notify";
import Loading from "../../../components/basics/Loading";
import { Filter } from "./SubHeaderIcons";

import { getEntitySystems } from "../../../repositories/portal_api/Systems";

import { getWidgets } from "../../../repositories/portal_api/Widgets";
import {
  deletePanelWidget,
  getHomePageWidgets,
  putWidgets,
} from "../../../repositories/portal_api/PanelWidgets";
import {
  getPanelsUser,
  postPanel,
} from "../../../repositories/portal_api/Panel";

export const Subheader = () => {
  const { userLogged, companyUser, reloadWidgets, setReloadWidgets } =
    useContext(GlobalContext);
  const [companyAbbreviation, setCompanyAbbreviation] = useState("");
  const [categoryDashboard, setCategoryDashboard] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [ERP, setERP] = useState([]);
  const [CRM, setCRM] = useState([]);
  const [XML, setXML] = useState([]);
  const [OUTROS, setOUTROS] = useState([]);
  const [buttonSystemActive, setButtonSystemActive] = useState("");
  const [doingAction, setDoingAction] = useState(false);

  const [panelsList, setPanelsList] = useState([]);
  const [panelSelected, setPanelSelected] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [idsWidgetsHomePage, setIdsWidgetsHomePage] = useState([]);
  const [customKey, setCustomKey] = useState(0);

  const { isInClientView, clientViewed } = usePortalAsClient();
  const [filteredRows, setFilteredRows] = useState(categoryDashboard);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const [optionsSystems, setOptionsSystems] = useState([]);

  const [hasFiscalAutomation, setHasFiscalAutomation] = useState(false);

  useEffect(() => {
    let abbreviation = companyUser.companyName.split(" ");
    let output = "";
    if (abbreviation.length > 1) {
      output = abbreviation[0].charAt(0) + abbreviation[1].charAt(0);
    } else {
      output = companyUser.companyName.charAt(0);
    }

    setCompanyAbbreviation(output);
  }, [companyUser.companyName]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getWidgetsBySystem = async (system) => {
    if (system === buttonSystemActive) return;
    setButtonSystemActive(system);
    if (system === "Gestão Fácil") {
      setCategoryDashboard(ERP);
      setFilteredRows(ERP);
    }
    if (system === "Gestão de Relacionamento") {
      setCategoryDashboard(CRM);
      setFilteredRows(CRM);
    }
    if (system === "XML") {
      setCategoryDashboard(XML);
      setFilteredRows(XML);
    }
    if (system === "Outros") {
      setCategoryDashboard(OUTROS);
      setFilteredRows(OUTROS);
    }
  };

  const handleClick = async (event) => {
    setAnchorEl(event.currentTarget);

    setLoading(true);
    try {
      await loadItems();
    } catch (err) {}
    setLoading(false);
  };

  const loadItems = async () => {
    try {
      if (!panelSelected?.id) {
        return;
      }

      const ids = [];
      const respWidgets = await getHomePageWidgets(panelSelected.id);
      if (!respWidgets?.success || !Array.isArray(respWidgets?.data)) {
        return;
      }
      respWidgets.data.forEach((value) => {
        ids.push(value.widget_version.id);
      });
      setIdsWidgetsHomePage(ids);

      let ERP = [];
      let CRM = [];
      let XML = [];
      let OUTROS = [];

      let response = await getWidgets(userLogged.userId, companyUser.companyId);

      if (response.code === 200) {
        response.data.forEach((element) => {
          const active = element.widget_versions.some((version) =>
            ids.includes(version.id)
          );
          element.active = active;
          if (element?.system?.name === "ERP") {
            ERP.push(element);
          }
          if (element?.system?.name === "CRM") {
            CRM.push(element);
          }
          if (element?.system?.name === "XML") {
            XML.push(element);
          }
          if (
            element?.system?.name !== "ERP" &&
            element?.system?.name !== "CRM" &&
            element?.system?.name !== "XML"
          ) {
            OUTROS.push(element);
          }
        });
      }
      setERP(ERP);
      setCRM(CRM);
      setXML(XML);
      setOUTROS(OUTROS);

      let options = [];
      if (ERP.length > 0) {
        options.push({
          name: "Gestão Fácil",
          value: "Gestão Fácil",
        });
      }

      if (CRM.length > 0) {
        options.push({
          name: "Gestão de Relacionamento",
          value: "Gestão de Relacionamento",
        });
      }

      if (XML.length > 0) {
        options.push({
          name: "XML",
          value: "XML",
        });
      }

      if (OUTROS.length > 0) {
        options.push({
          name: "Outros",
          value: "Outros",
        });
      }

      setOptionsSystems(options);

      setCategoryDashboard(ERP);
      setFilteredRows(ERP);
      setButtonSystemActive("Gestão Fácil");
    } catch (error) {
      console.error(error);
      notify("Não foi possível obter os dados.");
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const entitySystems = await getEntitySystems();
        if (entitySystems?.success) {
          entitySystems.data.forEach((system) => {
            if (system.system_id === 6 && system.system_version_id === 15) {
              setHasFiscalAutomation(true);
            }
          });
        }

        const panelsResp = await getPanelsUser({
          id: isInClientView ? clientViewed?.id : userLogged?.userId,
          ownerentity: isInClientView && clientViewed?.company,
        });

        if (panelsResp?.success && Array.isArray(panelsResp?.data)) {
          setPanelsList(panelsResp?.data);
        }

        // This user dont have any panel
        if (!panelsResp?.success && panelsResp?.code === 404) {
          await postPanel({
            body: {
              shared_to_entity: 0,
              name: "Painel padrão",
            },
          });

          const panelsResp = await getPanelsUser({
            id: isInClientView ? clientViewed?.id : userLogged?.userId,
            ownerentity: isInClientView && clientViewed?.company,
          });

          if (panelsResp?.success && Array.isArray(panelsResp?.data)) {
            if (panelsResp?.data.length !== 0) {
              setPanelsList(panelsResp?.data);
            }
          }
        }
      } catch (error) {
        console.error(error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyUser?.companyId]);

  useEffect(() => {
    setPanelSelected(
      panelsList.find((p) => p.favorited) || panelsList[0] || null
    );
  }, [panelsList]);

  const handlePutWidgets = async (widget) => {
    setDoingAction(true);

    try {
      setFilteredRows((prevItems) =>
        prevItems.map((item) => {
          if (item.widget_versions[0].id === widget[0].id) {
            item.loading = true;
            item.active = true;
            return item;
          }
          return item;
        })
      );

      let body = {
        panel_id: panelSelected.id,
        widget_version_id: widget[0]?.id || null,
        auto_update: true,
        position_y: 0,
        postion_x: 0,
        width: widget[0]?.width || 7,
        height: widget[0]?.height || 6,
      };

      const mainResponse = await putWidgets({
        body,
        idpanel: panelSelected.id,
      });

      if (!mainResponse?.success || !mainResponse?.data) {
        setFilteredRows((prevItems) =>
          prevItems.map((item) => {
            if (item.widget_versions[0].id === widget[0].id) {
              item.loading = false;
              item.active = false;
              return item;
            }
            return item;
          })
        );
        return notify("Não foi possível adicionar o widget.");
      }

      if (mainResponse?.success) {
        setFilteredRows((prevItems) =>
          prevItems.map((item) => {
            if (item.widget_versions[0].id === widget[0].id) {
              item.loading = false;
              return item;
            }
            return item;
          })
        );
        setReloadWidgets(!reloadWidgets);
      }

      if (widget?.id) {
        setIdsWidgetsHomePage((current) => [...current, widget.id]);
      }
    } catch (error) {
      console.error(error);
    }
    setDoingAction(false);
  };

  const handleDeleteWidgets = async (widget) => {
    setDoingAction(true);
    try {
      setFilteredRows((prevItems) =>
        prevItems.map((item) => {
          if (item.widget_versions[0].id === widget[0].id) {
            item.loading = true;
            item.active = false;
            return item;
          }
          return item;
        })
      );

      let widgetId = widget[0].id;

      const mainResponse = await deletePanelWidget(panelSelected.id, widgetId);

      if (!mainResponse?.success || !mainResponse?.data) {
        setFilteredRows((prevItems) =>
          prevItems.map((item) => {
            if (item.widget_versions[0].id === widget[0].id) {
              item.loading = false;
              item.active = true;
              return item;
            }
            return item;
          })
        );
        return notify("Algo deu errado, tente novamente.");
      }

      if (mainResponse?.success) {
        setIdsWidgetsHomePage((current) => {
          var index = current.indexOf(widgetId);
          if (index !== -1) {
            current.splice(index, 1);
          }
          return current;
        });

        setFilteredRows((prevItems) =>
          prevItems.map((item) => {
            if (item.widget_versions[0].id === widget[0].id) {
              item.loading = false;
              return item;
            }
            return item;
          })
        );
        setReloadWidgets(!reloadWidgets);
      }
    } catch (error) {
      console.error(error);
    } finally {
    }
    setDoingAction(false);
  };

  function handleFilterChange(event) {
    const value = event.target.value.toLowerCase();
    const filteredData = categoryDashboard.filter((row) =>
      row.title.toLowerCase().includes(value)
    );
    setFilteredRows(filteredData);
  }

  const getAbbrev = (name) => {
    try {
      return name.substring(0, 2);
    } catch (err) {
      return null;
    }
  };

  useEffect(() => {
    setCustomKey(Math.random());
  }, [companyUser.logoUrl]);

  return (
    <div style={{ position: "relative", width: "calc(100% + 30px)" }}>
      <Loading open={doingAction} />
      <StyledSubheader item container direction="row" alignItems="center">
        {isInClientView && (
          <Grid
            item
            container
            direction="row"
            xs={12}
            sm={7}
            md={10}
            alignItems="center"
            spacing={1}
          >
            <Grid item>
              {getAbbrev(clientViewed.clientCompanyName) !== null && (
                <DivCompanyAbbreviation>
                  {getAbbrev(clientViewed.clientCompanyName)}
                </DivCompanyAbbreviation>
              )}
            </Grid>
            <Grid item>
              <CompanyName>{clientViewed.clientCompanyName}</CompanyName>
            </Grid>
          </Grid>
        )}

        {!isInClientView && (
          <Grid
            item
            container
            direction="row"
            xs={12}
            sm={7}
            md={10}
            alignItems="center"
            spacing={1}
          >
            <Grid item>
              {companyUser.logoUrl !== undefined &&
                companyUser.logoUrl !== "" &&
                companyUser.logoUrl !== null && (
                  <CompanyLogo
                    src={companyUser.logoUrl}
                    alt="Logo Empresa"
                    key={customKey}
                  />
                )}

              {(companyUser.logoUrl === undefined ||
                companyUser.logoUrl === "" ||
                companyUser.logoUrl === null) && (
                <DivCompanyAbbreviation>
                  {companyAbbreviation}
                </DivCompanyAbbreviation>
              )}
            </Grid>
            <Grid item>
              <CompanyName>{companyUser.companyName}</CompanyName>
            </Grid>
          </Grid>
        )}

        {!hasFiscalAutomation && (
          <Grid item xs={12} sm={5} md={2} container justifyContent="flex-end">
            <IconButton onClick={handleClick}>
              <Filter />
            </IconButton>
          </Grid>
        )}

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Grid
            container
            style={{
              maxWidth: "510px",
            }}
          >
            <Grid item xs={12}>
              <FormControlStyled variant="standard">
                <Input
                  style={{ color: "grey", marginTop: 10 }}
                  placeholder="Buscar"
                  fullWidth
                  startAdornment={
                    <InputAdornment position="start">
                      <Badge>
                        <SearchOutlined
                          style={{ color: "#063bb5", marginLeft: 15 }}
                        />
                      </Badge>
                    </InputAdornment>
                  }
                  onChange={handleFilterChange}
                />
              </FormControlStyled>

              {optionsSystems.map((option) => (
                <ButtonSystem
                  variant="contained"
                  onClick={() => getWidgetsBySystem(option.name)}
                  actived={buttonSystemActive === option.name}
                >
                  {option.name}{" "}
                </ButtonSystem>
              ))}

              <Divider style={{ backgroundColor: "#9e9e9e", height: "1px" }} />

              {loading && (
                <GridStyled container justifyContent="center">
                  <CircularProgress />
                </GridStyled>
              )}

              {!loading && (
                <FormControlDashboard component="fieldset">
                  {filteredRows
                    .slice()
                    .sort((a, b) => {
                      /*const activeA = a.widget_versions.some((version) =>
                        idsWidgetsHomePage.includes(version.id)
                      );
                      const activeB = b.widget_versions.some((version) =>
                        idsWidgetsHomePage.includes(version.id)
                      );

                      // Coloque os itens ativos primeiro
                      if (activeA && !activeB) return -1;
                      if (!activeA && activeB) return 1;
                      */
                      return 0; // Mantenha a ordem original para os itens inativos
                    })
                    .map((widget) => {
                      return (
                        <Grid item xs={12} key={widget.id}>
                          <FormControlLabelStyled
                            key={widget.id}
                            value={widget.id}
                            control={
                              <Switch
                                edge="start"
                                color="primary"
                                checked={widget.active}
                                onClick={() =>
                                  widget.active
                                    ? handleDeleteWidgets(
                                        widget.widget_versions
                                      )
                                    : handlePutWidgets(widget.widget_versions)
                                }
                              />
                            }
                            label={
                              <div>
                                <span
                                  style={{ fontSize: "14px", lineHeight: 3 }}
                                >
                                  {widget.title}
                                </span>
                                {widget.loading && (
                                  <CircularProgress
                                    size="0.7rem"
                                    style={{ marginTop: 20, marginLeft: 20 }}
                                  />
                                )}
                              </div>
                            }
                          />
                        </Grid>
                      );
                    })}
                  {filteredRows.length === 0 && (
                    <DivNotResult>Nenhum widget encontrado</DivNotResult>
                  )}
                </FormControlDashboard>
              )}
            </Grid>
            {/*
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-end"
            >
              <Grid item xs={12}>
                <Divider
                  style={{ backgroundColor: "#9e9e9e", height: "1px" }}
                />
                <ButtonRestoreDefault>Restaurar Padrão</ButtonRestoreDefault>
              </Grid>
            </Grid>
            */}
          </Grid>
        </Popover>
      </StyledSubheader>
    </div>
  );
};
