exports.GlobalVariables = {
  ID_FAMILIA_ERP_PLUG: 1,
  ID_PRODUTO_ERP_PLUG: 1,

  ID_PRODUCT_ERP_PANEL: 1,

  ID_PRODUCT_VERSIONS_ERP_PANEL: [1, 2, 3, 4, 6, 7, 23, 27],
  ID_ERP_VERSION_GESTAO_FACIL: 23,
  ID_ERP_VERSION_GESTAO_FACIL_PRO: 27,

  SYSTEMS: {
    ERP: 1,
    Folha: 2,
    XML: 3,
    CRM: 4,
    Contabil: 5,
    Fiscal: 6,
    BANK: 7,
    RADAR: 8,
  },

  INTERNAL_CODES: {
    UserExistsInCognitoButNotInDatabase: 1001,
    UserNotFoundOnCognito: 1002,
    UserNotConfirmedException: 1003,
    ClientsBpoNotFound: 1004,
    ClientsNotFound: 1005,
    ThisUserDoesntHaveAnyPanel: 1006,
    EntityNotFound: 1007,
    CnpjDoesNotHaveErp: 1008,
    SessionExpired: 1009,
    EmailIsInvalid: 10010,
    EntityHasntAnySystem: 10011,
    EntityHasntAnyPanel: 10012,
    UserTemporaryPasswordException: 10013,
    AgendaRelatedToGroup: 10014,
    Unauthorized: 4001,
    NotAuthorized: 401,

    RPA: {
      InvalidToken: 5401,
    },
  },

  PERMISSIONS: {
    MASTER: "f4038afa-9321-4543-86ae-becdd2459993",

    // Menus
    MENUS: "a9810b69-1b15-427e-b4e1-d2665ffc22d2",

    SETTINGS: "358fb3df-47b7-4496-b5aa-68f4b97e5dd1",
    SETTINGS_COMPANY: "b13a50e4-90fd-4426-82b2-b70dbf2047a0",
    SETTINGS_USER: "63a0443b-1aab-4502-b140-b0aeee6d35f2",

    CLIENTES: "1a0c1bc4-1b86-4244-9196-110990c159ae",
    CLIENTES_LIBERATION: "97888038-3969-457f-80a0-bb7b00bdeefd",
    CLIENTES_VISUALIZATION: "1bba6ee0-75fd-4958-b2d0-ce301b3dc7b6",
    CLIENTES_BPO: "87a7d59a-5f58-47a2-9727-43049236b052",
    CLIENTES_MANAGEMENT: "a211f873-3276-45ac-89d0-769984472c29",

    GESTAO_FACIL: "d0fe9bad-bbf0-4ff8-88bb-8a9bf48cb08c",

    CRM: "fcf6d388-be69-4f9b-bdc6-2e85036ea07c",

    CONTABIL: "f994fa4b-e2ab-4f4a-9779-2fa91dc1bc84",

    FISCAL: "a242dfe0-1df2-4556-b7eb-be0a97587efd",

    FOLHA: "364dfa28-482c-4117-8c28-932c49598de8",

    XML: "3a890d8c-1d16-4ece-85e9-82f29ab562d9",

    CND: "1e111d55-76a7-48c0-a9f8-9222ad51cee0",
    CND_VISUALIZATION: "64423680-9428-4b0f-9455-81a05b39cdd2",
    CND_SOLICITATION: "9b5797d4-e71b-4f12-9805-0b8a783f0fcf",

    //Cards
    CARDS: "2ff3cd2b-82f7-4111-9f9b-018079d9aae3",

    DOCUMENTS_AND_GUIDES: "b4277384-b361-431f-ab48-9c93f8c1fab1",
    ATTENDANCE: "372884e8-e4eb-4909-857e-6235e810834f",
    RADAR: "b1b46f3e-a2b7-4bd6-b24e-10a6db3f8b01",
    NFS_MANAGEMENT: "ad1849fe-e09c-45b1-836a-a4b5c854044a",
  },
  SISTEM_VERSION_DATE: "3.17.2 - 18/01/2025",
};
