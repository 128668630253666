import { CircularProgress } from "@material-ui/core";
import React, { useContext, useEffect, useRef, useState } from "react";
import ModalPreviewPdf from "../../GuideReports/components/ModalPreviewPdf";
import ModalSendDocs from "../../GuideReports/components/ModalSendDocs";
import { EmailTemplateContextProvider } from "../../../components/modal/contexts/EmailTemplateContext";
import DocumentGuideReportBody from "../../GuideReports/components/DocumentGuideReportBody";

import { DocumentGuideContext } from "../../GuideReports/contexts/DocumentGuideContext";
import { GlobalContext } from "../../../contexts/GlobalContext";
import { getDocumentList } from "../../GuideReports/controller/GuideReportController";
import { getFolders } from "../../../repositories/GuideReportList";
import { FoldersIds } from "../../GuideReports/utils/FolderTypes";
import { CommsConfig } from "../../GuideReports/CommsConfig";

import { DocumentFolderTabsCustomerManagement } from "../../GuideReports/components/DocumentFolderTabsCustomerManagement";
import { CustumerManagementContext } from "../contexts/CustomerManagementContext";
import ModalDocumentHistory from "../../GuideReports/components/ModalDocumentHistory";
import { getAccountantClientDataByDocument } from "../../../repositories/portal_api/AccountantClient";

var currentDate;
var oneMonthBeforeDate;

const setCurrentDateAndOneMonthBefore = () => {
  var actualDate = new Date();

  var actualYear = actualDate.getFullYear();
  var actualMonth = ("0" + (actualDate.getMonth() + 1)).slice(-2);
  var actualDay = ("0" + actualDate.getDate()).slice(-2);
  currentDate = actualYear + "-" + actualMonth + "-" + actualDay;

  var oneMonthBefore = new Date(actualDate);
  oneMonthBefore.setMonth(oneMonthBefore.getMonth() - 1);
  var yearBefore = oneMonthBefore.getFullYear();
  var monthBefore = ("0" + (oneMonthBefore.getMonth() + 1)).slice(-2);
  var dayBefore = ("0" + oneMonthBefore.getDate()).slice(-2);
  oneMonthBeforeDate = yearBefore + "-" + monthBefore + "-" + dayBefore;
};

setCurrentDateAndOneMonthBefore();

const DocumentsTab = () => {
  const folders = useRef([""]);
  const [isLoading, setIsLoading] = useState(false);
  const { companyUser } = useContext(GlobalContext);
  const firstLoad = useRef(true);
  const [openModalSendMail, setOpenModalSendMail] = useState(false);

  const { documentCustomer } = useContext(CustumerManagementContext);

  const {
    setDocumentList,
    setTotalDocs,
    searchField,
    page,
    pageSize,
    clickedRow,
    selectedStartDate,
    selectedEndDate,
    setSelectedStartDate,
    setSelectedEndDate,
    setPdf,
    searchClientList,
    setIsLoadingDocuments,
    chipValue,
    tabValue,
    setChipValue,
    setTabValue,
    openModalPreviewDoc,
    setOpenModalPreviewDoc,
    setSearchClientList,
    openModalDocumentHistory,
    setOpenModalDocumentHistory,
    setDocumentHistoryList,
    currentDocumentInboxStatusId,
  } = useContext(DocumentGuideContext);

  useEffect(() => {
    initialSetup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialSetup = async () => {
    setSelectedStartDate(oneMonthBeforeDate);
    setSelectedEndDate(currentDate);
    setIsLoading(true);

    await getFoldersList();

    if (!tabValue) {
      setTabValue(FoldersIds.Guias);
    }

    const response = await getAccountantClientDataByDocument(documentCustomer);

    if (response.success) {
      setSearchClientList(response.data);
    }

    setInitialChip(response.data);
    firstLoad.current = false;
    setIsLoading(false);
  };

  const handleTabChange = async (folderId) => {
    setTabValue(folderId);

    if (isFolderIdOtherOrCnd(folderId)) {
      setIsLoadingDocuments(true);
      let data = await getDocumentList({
        startDate: oneMonthBeforeDate,
        endDate: currentDate,
        folderId: folderId,
        entityId: companyUser.companyId,
        search: searchField,
        searchId: searchClientList?.id,
        page: page,
        pageSize: pageSize,
        inboxStatusId: currentDocumentInboxStatusId,
      });
      setIsLoadingDocuments(false);

      setDocumentList(data.documentList);
      setTotalDocs(data.totalDocs);
    }
  };

  const isFolderIdOtherOrCnd = (folderId) => {
    return folderId === FoldersIds.CND || folderId === FoldersIds.Outros;
  };

  const handleChipChange = async (folderId) => {
    setChipValue(folderId);
    setIsLoadingDocuments(true);
    let data = await getDocumentList({
      startDate: selectedStartDate,
      endDate: selectedEndDate,
      folderId: folderId,
      entityId: companyUser.companyId,
      search: searchField,
      searchId: searchClientList?.id,
      page: page,
      pageSize: pageSize,
      inboxStatusId: currentDocumentInboxStatusId,
    });
    setIsLoadingDocuments(false);

    setDocumentList(data.documentList);

    setTotalDocs(data.totalDocs);
  };

  useEffect(() => {
    if (searchClientList) {
      setInitialChip();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabValue, searchClientList]);

  useEffect(() => {
    if (!firstLoad.current) {
      executeSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchField]);

  const getFoldersList = async () => {
    const response = await getFolders();
    if (response) {
      if (companyUser.isAccountant) {
        folders.current = [
          ...response,

          {
            date_entered: null,
            folder_id: "COMMS_CONFIG",
            key: "comms_config",
            name: "Configurações de Comunicação",
            parent_id: null,
          },
        ];
      } else {
        folders.current = [...response];
      }
    }
  };

  const setInitialChip = (searchClient) => {
    let searchClientDocumentId = searchClientList?.id;

    if (searchClient) searchClientDocumentId = searchClient.id;

    let count = 0;
    folders.current.forEach(async (item) => {
      if (item.parent_id === tabValue && count < 1) {
        setChipValue(item.folder_id);
        count++;
        if (firstLoad.current) {
          setIsLoadingDocuments(true);
          let data = await getDocumentList({
            startDate: selectedStartDate || oneMonthBeforeDate,
            endDate: selectedEndDate || currentDate,
            folderId: "00000000-0000-0000-0001-000000000000",
            entityId: companyUser.companyId,
            search: searchField,
            searchId: searchClientDocumentId,
            page: page,
            pageSize: pageSize,
            inboxStatusId: currentDocumentInboxStatusId,
          });

          setIsLoadingDocuments(false);
          setDocumentList(data.documentList);

          setTotalDocs(data.totalDocs);
        } else {
          setIsLoadingDocuments(true);
          let data = await getDocumentList({
            startDate: selectedStartDate,
            endDate: selectedEndDate,
            folderId: item.folder_id,
            entityId: companyUser.companyId,
            search: searchField,
            searchId: searchClientDocumentId,
            page: page,
            pageSize: pageSize,
            inboxStatusId: currentDocumentInboxStatusId,
          });
          setIsLoadingDocuments(false);
          setDocumentList(data.documentList);
          setTotalDocs(data.totalDocs);
        }
      }
    });
  };

  const executeSearch = async () => {
    setIsLoadingDocuments(true);
    let data = await getDocumentList({
      pageSize: pageSize,
      startDate: selectedStartDate,
      endDate: selectedEndDate,
      folderId: chipValue,
      page: page,
      search: searchField,
      entityId: companyUser.companyId,
      searchId: searchClientList?.id,
      inboxStatusId: currentDocumentInboxStatusId,
    });
    setIsLoadingDocuments(false);

    setDocumentList(data.documentList);
    setTotalDocs(data.totalDocs);
  };

  return (
    <>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "8px",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div style={{ padding: 0, margin: 0 }}>
          <DocumentFolderTabsCustomerManagement
            folders={folders}
            handleChipChange={handleChipChange}
            handleTabChange={handleTabChange}
          />

          {tabValue !== "COMMS_CONFIG" ? (
            <>
              <DocumentGuideReportBody
                client={documentCustomer}
              ></DocumentGuideReportBody>
            </>
          ) : (
            <EmailTemplateContextProvider>
              <CommsConfig />
            </EmailTemplateContextProvider>
          )}

          <ModalSendDocs
            handleClose={() => setOpenModalSendMail(false)}
            open={openModalSendMail}
            documentId={clickedRow?.document_id}
          />

          <ModalPreviewPdf
            handleClose={() => {
              setOpenModalPreviewDoc(false);
              setPdf("");
            }}
            open={openModalPreviewDoc}
          />

          <ModalDocumentHistory
            handleClose={() => {
              setOpenModalDocumentHistory(false);
              setDocumentHistoryList([]);
            }}
            open={openModalDocumentHistory}
          />
        </div>
      )}
    </>
  );
};

export default DocumentsTab;
