import {
  Checkbox,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect } from "react";
import { DateConfirmed } from "../../styles/ModalServicesConfig";
import moment from "moment";
import { getDocumentsById } from "../../../../repositories/Document";

const ModalCommsConfigChannelBody = ({
  selectedServices,
  setSelectedServices,
  serviceId,
  entityId,
  listServices,
  ...props
}) => {
  const [documentList, setDocumentList] = React.useState([]);

  useEffect(() => {
    let params = {
      scheduled_id: null,
      companyId: entityId,
      service: listServices.find((item) => item.id === serviceId).service,
      confirmed: null,
      systemId: null,
    };

    getDocumentsById(params).then((response) => {
      if (response) {
        response = response.map((item) => {
          return {
            ...item,
            scheduled_id: selectedServices[serviceId]?.scheduled_id,
          };
        });
      }
      setDocumentList(response);
    });
  }, [listServices, entityId, serviceId, selectedServices]);

  function updateDocumentList(values) {
    const arrayUniqueByKey = [
      ...new Map(values.map((item) => [item["id"], item])).values(),
    ];

    let newServices = { ...selectedServices };

    newServices[serviceId].documents = arrayUniqueByKey;

    setSelectedServices(newServices);
  }

  /*
  objeto dentro de documents
  {
				"comm_type": "Email",
				"confirmed": true,
				"created_at": "2024-07-24 19:24:08.594625",
				"document": "gabriel.oliveira@qyon.com",
				"entity_id": "5515",
				"id": "bdbe4d47-d53c-4a49-804b-f7b687da0b4d",
				"scheduled_id": null
			}
         */

  return (
    <>
      <Autocomplete
        style={{ marginTop: "24px", background: "#F0F1F3" }}
        multiple
        value={selectedServices[serviceId]?.documents || []}
        options={documentList}
        disableCloseOnSelect
        getOptionLabel={(option) => option.document}
        renderOption={(option) => {
          return (
            <React.Fragment>
              <Checkbox
                style={{ marginRight: 8 }}
                color="primary"
                checked={selectedServices[serviceId].documents.find(
                  (item) => item.id === option.id
                )}
                onChange={(ev) => {
                  let newServices = { ...selectedServices };

                  if (!ev.target.checked) {
                    newServices[serviceId].documents = newServices[
                      serviceId
                    ].documents.filter((item) => item.id !== option.id);
                  } else {
                    newServices[serviceId].documents.push(option);
                  }
                }}
              />
              <Grid
                xs={12}
                justifyContent="space-between"
                style={{
                  display: "flex",
                  alignItems: "baseline",
                }}
              >
                {option.document}
              </Grid>
            </React.Fragment>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder="Buscar emails confirmados"
          />
        )}
        renderTags={() => null}
        onChange={(event, newValue) => updateDocumentList(newValue)}
      />

      <List style={{ marginTop: "24px" }}>
        {selectedServices[serviceId]?.documents && (
          <>
            {selectedServices[serviceId].documents.map((value) => {
              const labelId = `checkbox-list-label-${value}`;

              return (
                <ListItem
                  key={value}
                  role={undefined}
                  dense
                  button
                  onClick={() => null}
                  style={{ borderBottom: " 1px solid #9DA4B2" }}
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ "aria-labelledby": labelId }}
                      color="primary"
                    />
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={value.document} />
                  <ListItemSecondaryAction>
                    <DateConfirmed edge="end">
                      {moment(value.created_at).format("DD/MM/YYYY hh:mm")}
                    </DateConfirmed>
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
          </>
        )}
      </List>
    </>
  );
};

export default ModalCommsConfigChannelBody;
