import React from "react";
import { Helmet } from "react-helmet";
import { ItemGridDragDropTitleStyle } from "../../components/grid-drag-drop/ItemGridDragDropTitleStyle";
import { Grid, IconButton } from "@material-ui/core";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import { LightTooltip, Margin } from "../../styles/GlobalStyle";
import CachedOutlinedIcon from "@material-ui/icons/CachedOutlined";
import CloseIcon from "@material-ui/icons/Close";
import { DividerStyled } from "../../components/chat/styles/GeneralStyle";
import useDashboardViewController from "../../pages/dashboard/controllers/DashboardViewController";

export const WidgetVersion50 = () => {
  const { handleDeleteWidgets } = useDashboardViewController();

  const item = {
    i: "50",
    importScripts: ["https://cdn.jsdelivr.net/npm/chart.js"],
    stringScripts: [""],
    onRefresh: `
try {
    const GECR_ENVIRONMENT = localStorage.getItem("environment") ?? "test";
    const GECR_ERP_API_URL = GECR_ENVIRONMENT === "prod" ? "https://qerpapi.qyon.com" :
        GECR_ENVIRONMENT === "beta" ? "https://beta-qerpapi.qyon.com" :
        GECR_ENVIRONMENT === "dev" ? "http://localhost:85" :
        "https://test-qerpapi.qyon.com";

    const GECR_ERP_API_TOKEN = GECR_ENVIRONMENT === "prod" ? "eVmDI3J6dCqvxDUyZv16Sr1KBDW0TTNKvZA4LCkY" : "eyJ0ZXN0ZSI6InJhZmFvbGluZG9zejEyMyJ9";

    const documentPanel = localStorage.getItem("documentPanel");

    const GECR_getCRStatus = async () => {
        const GECR_CRStatusData = await fetch(
            \`\${GECR_ERP_API_URL}/api/erp/financeiro/contasreceber/v1/status?\${new URLSearchParams({empresa_cnpj: documentPanel})}\`,
            {
                method: "GET",
                headers: new Headers({ Authorization: GECR_ERP_API_TOKEN })
            }
        )
        .then((res) => {
            if (res.ok) {
                return res.json();
            }
            throw res.statusText;
        })
        .catch((error) => {
            console.error("Widget GE - Contas Receber, fetch error:", error);
            if (document.getElementById("GECR_fetchError")) {
                document.getElementById("GECR_fetchError").style.display = "flex";
                document.getElementById("GECR_loaderAnimation").style.display = "none";
            }
        });
        return GECR_CRStatusData?.success ? GECR_CRStatusData.data : false;
    };

    const GECR_populateCR = async () => {
        try {
            let GECR_CRStatusReturn = await GECR_getCRStatus();
            if (GECR_CRStatusReturn && document.getElementById("GECR_content")) {
                document.getElementById("GECR_totalReceiveLate").innerHTML = GECR_numberUStoBR(GECR_CRStatusReturn.totalReceiveLate);
                document.getElementById("GECR_totalReceiveLastMonth").innerHTML = GECR_numberUStoBR(GECR_CRStatusReturn.totalReceiveLastMonth);
                document.getElementById("GECR_totalReceiveNextMonth").innerHTML = GECR_numberUStoBR(GECR_CRStatusReturn.totalReceiveNextMonth);
                document.getElementById("GECR_totalReceiveFutures").innerHTML = GECR_numberUStoBR(GECR_CRStatusReturn.totalReceiveFutures);

                if (GECR_CRStatusReturn.inMonth) {
                    document.getElementById("GECR_content").style.display = "flex";
                    GECR_renderChartJs(GECR_CRStatusReturn.inMonth);
                }
            }
        } catch (error) {
            console.error("Widget GE - Contas Receber, populate error:", error);
            document.getElementById("GECR_fetchError").style.display = "flex";
            document.getElementById("GECR_content").style.display = "none";
        } finally {
            if (document.getElementById("GECR_loaderAnimation")) {
                document.getElementById("GECR_loaderAnimation").style.display = "none";
            }
        }
    };

    function GECR_renderChartJs(inMonth) {
        document.getElementById("GECR_chartContainer").innerHTML = "";
        document.getElementById("GECR_chartContainer").innerHTML = '<canvas id="GECR_ChartCanvas" style="max-height: 100%"></canvas>';

        const GECR_data = {
            labels: ["Em aberto", "Recebido", "Em atraso"],
            datasets: [{
                label: "My First Dataset",
                data: [inMonth.open, inMonth.received, inMonth.late],
                backgroundColor: ["rgb(255, 205, 86)", "rgb(54, 162, 235)", "rgb(255, 99, 132)"],
                hoverOffset: 4
            }]
        };

        const GECR_config = {
            type: "doughnut",
            data: GECR_data,
            options: {
                plugins: {
                    subtitle: {
                        display: true,
                        text: "No mês"
                    },
                    legend: {
                        position: "bottom",
                        labels: {
                            boxWidth: 8,
                            boxHeight: 8,
                            usePointStyle: true,
                            font: { size: 10 }
                        }
                    },
                    tooltip: {
                        callbacks: {
                            label: function (context) {
                                return \`R$ \${GECR_numberUStoBR(context.parsed)}\`;
                            }
                        }
                    }
                }
            }
        };

        const GECR_myChart = new Chart(
            document.getElementById("GECR_ChartCanvas"),
            GECR_config
        );
    }

    function GECR_numberUStoBR(number) {
        return number.toLocaleString("pt-br", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    }

    document.getElementById("GECR_loaderAnimation").style.display = "block";
    document.getElementById("GECR_fetchError").style.display = "none";
    document.getElementById("GECR_content").style.display = "none";

    try {
        GECR_populateCR();
    } catch (error) {
        console.error("Widget GE - Contas Receber, error:", error);
        document.getElementById("GECR_fetchError").style.display = "flex";
        document.getElementById("GECR_loaderAnimation").style.display = "none";
    }
} catch (err) {}
`,
    refreshOnLoad: true,
  };

  return (
    <>
      {item.importScripts.map((script, index) => {
        return (
          <div key={`import_${index}`}>
            <Helmet>
              <script async src={script}></script>
            </Helmet>
          </div>
        );
      })}

      <ItemGridDragDropTitleStyle customStyle={item.headerStyle ?? {}}>
        <Grid item container justifyContent="space-between" alignItems="center">
          <Grid item flex={1}>
            <IconButton size="small">
              <DragIndicatorIcon style={{ fontSize: 18, marginRight: 5 }} />
            </IconButton>
            Contas a Receber
          </Grid>
          <Grid item flex={1}>
            <Grid item container alignItems="center">
              <Margin top="7" />
              {item.onRefresh &&
                (() => {
                  if (item?.refreshOnLoad) {
                    setTimeout(() => {
                      try {
                        console.log("ALOOOOOOOOOO");
                        // eslint-disable-next-line no-eval
                        eval(
                          "try{" +
                            item.onRefresh +
                            "}catch(err){console.log(err)}"
                        );
                      } catch (error) {
                        console.error(error);
                      }
                    }, 1500);
                  }

                  return (
                    <Grid item flex={1}>
                      <LightTooltip title="Atualizar">
                        <CachedOutlinedIcon
                          style={{
                            fontSize: 18,
                            marginRight: 10,
                            cursor: "pointer",
                            color: "#636363",
                          }}
                          onClick={() => {
                            // Muito cuidado, o eval é perigoso - https://developer.mozilla.org/pt-BR/docs/Web/JavaScript/Reference/Global_Objects/eval
                            try {
                              // eslint-disable-next-line no-eval
                              eval("try{" + item.onRefresh + "}catch(err){}");
                            } catch (error) {
                              console.error(error);
                            }
                          }}
                        />
                      </LightTooltip>
                    </Grid>
                  );
                })()}
              <Grid item flex={1}>
                <LightTooltip title="Remover">
                  <CloseIcon
                    style={{
                      fontSize: 18,
                      cursor: "pointer",
                      color: "#636363",
                      marginRight: 10,
                    }}
                    onClick={() => handleDeleteWidgets(item)}
                  />
                </LightTooltip>
              </Grid>
            </Grid>
          </Grid>
          <DividerStyled style={{ width: "100%" }} />
        </Grid>
      </ItemGridDragDropTitleStyle>

      <div
        style={{
          height: `${"calc(100% - 65px)"}`,
          width: "100%",
        }}
      >
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin="true"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/icon?family=Material+Icons+Round"
          rel="stylesheet"
        />
        <style>
          {`
          @keyframes GECR_spinner-grow {
            0% {
              transform: scale(0);
            }
            50% {
              opacity: 1;
              transform: none;
            }
          }
        `}
        </style>
        <div
          id="GECR_widget"
          style={{
            height: "100%",
            fontFamily: "'Roboto', sans-serif",
            position: "relative",
          }}
        >
          <div
            id="GECR_loaderAnimation"
            style={{
              color: "#0d6efd !important",
              display: "none",
              width: "5rem",
              height: "5rem",
              verticalAlign: "-0.125em",
              backgroundColor: "currentColor",
              borderRadius: "50%",
              opacity: 0,
              WebkitAnimation: "0.75s linear infinite GECR_spinner-grow",
              animation: "0.75s linear infinite GECR_spinner-grow",
              animationDuration: "0.75s",
              position: "absolute",
              top: "calc(50% - 2.5rem)",
              left: "calc(50% - 2.5rem)",
            }}
          ></div>
          <div
            id="GECR_fetchError"
            style={{
              position: "absolute",
              width: "calc(100% - 20px)",
              height: "100%",
              display: "none",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span
              className="material-icons-round"
              style={{ fontSize: "3em", margin: "8px" }}
            >
              error_outline
            </span>
            <p>
              Não foi possível carregar os dados
              <br />
              Tente novamente
            </p>
          </div>
          <div
            id="GECR_content"
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",
                width: "45%",
                borderLeft: "4px solid #0A73DB",
                paddingLeft: "12px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <div style={{ fontSize: "1.2em", fontWeight: "bold" }}>
                  R$ <span id="GECR_totalReceiveLate">-</span>
                </div>
                <hr
                  style={{
                    width: "40%",
                    border: "none",
                    height: "1px",
                    color: "#ddd",
                    backgroundColor: "#ddd",
                    margin: "4px auto 4px 0",
                  }}
                />
                <span style={{ fontSize: "0.8em", color: "#777777" }}>
                  Total Atrasado
                </span>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <div style={{ fontSize: "1.2em", fontWeight: "bold" }}>
                  R$ <span id="GECR_totalReceiveLastMonth">-</span>
                </div>
                <hr
                  style={{
                    width: "40%",
                    border: "none",
                    height: "1px",
                    color: "#ddd",
                    backgroundColor: "#ddd",
                    margin: "4px auto 4px 0",
                  }}
                />
                <span style={{ fontSize: "0.8em", color: "#777777" }}>
                  Atrasados Último Mês
                </span>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <div style={{ fontSize: "1.2em", fontWeight: "bold" }}>
                  R$ <span id="GECR_totalReceiveNextMonth">-</span>
                </div>
                <hr
                  style={{
                    width: "40%",
                    border: "none",
                    height: "1px",
                    color: "#ddd",
                    backgroundColor: "#ddd",
                    margin: "4px auto 4px 0",
                  }}
                />
                <span style={{ fontSize: "0.8em", color: "#777777" }}>
                  Recebimentos Próximo Mês
                </span>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <div style={{ fontSize: "1.2em", fontWeight: "bold" }}>
                  R$ <span id="GECR_totalReceiveFutures">-</span>
                </div>
                <hr
                  style={{
                    width: "40%",
                    border: "none",
                    height: "1px",
                    color: "#ddd",
                    backgroundColor: "#ddd",
                    margin: "4px auto 4px 0",
                  }}
                />
                <span style={{ fontSize: "0.8em", color: "#777777" }}>
                  Total de Recebimentos Futuros
                </span>
              </div>
            </div>
            <div
              id="GECR_chartContainer"
              style={{
                display: "flex",
                width: "55%",
                height: "100%",
              }}
            >
              <canvas
                id="GECR_ChartCanvas"
                style={{ maxHeight: "100%" }}
              ></canvas>
            </div>
          </div>
        </div>
      </div>

      {item.stringScripts &&
        item.stringScripts.map((script, index) => {
          return (
            <div key={`script_${index}`}>
              <Helmet>
                <script async>{script}</script>
              </Helmet>
            </div>
          );
        })}
    </>
  );
};
