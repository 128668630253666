import axios from "axios";
import { notify } from "../../../utils/notify";

const newPath = `${process.env.REACT_APP_NEW_PAINEL_API_URL}`;

export async function sendHubDocuments(body) {
  if (!body?.foldersCheckbox) {
    notify("Selecione ao menos uma pasta destino para o documento!");
    return false;
  }

  return axios
    .post(`${newPath}/v1/document/upload-emmit`, body, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("tokenPanel"),
        "Content-Type": "application/json",
        "Content-Language": "pt-BR",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      notify("Ocorreu um erro cadastrar o documento!");
      return err;
    });
}
